import React from 'react';
import { withStyles, Typography, Box } from '@material-ui/core/';
import numeral from 'numeral';
import { Skeleton } from '@material-ui/lab';

const styles = theme => ({
  dataCard: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

function DataCard(props) {
  const { classes, text, value, loading } = props;
  return (
    <Box className={classes.dataCard}>
      <Typography variant='h6' style={{ color: 'white' }}>
        {text}
      </Typography>

      {loading ? (
        <Skeleton variant='text' width={30} height={40}></Skeleton>
      ) : (
        <Typography variant='h3' style={{ color: 'white' }}>
          {numeral(value).format('0a')}
        </Typography>
      )}
    </Box>
  );
}
export default withStyles(styles)(DataCard);

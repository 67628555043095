import React from 'react';
import {
  withStyles,
  withWidth,
  Grid,
  Typography,
  Box,
  Paper,
  FormControlLabel,
  Switch,
  CircularProgress,
  FormHelperText
} from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import axios from 'axios';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import app from '../../../../appConfig';
import Skeleton from '@material-ui/lab/Skeleton';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    flexWrap: 'wrap'
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 5
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button: {
    width: theme.spacing(12),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1)
  },
  chipContainer: {
    margin: theme.spacing(1.2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    placeItems: 'center'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  tagTextField: { marginLeft: theme.spacing(1) },
  listText: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: 'none'
  },
  errorStyle: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#f00'
  }
});

class AddEditUser extends React.Component {
  state = {
    customTag: '',
    text: '',
    open: false,
    isLoading: false,
    isAddTagOpen: false,
    isSitesEnabled: true,
    isDataInValid: {
      firstName: false,
      lastName: false,
      email: false,
      role: false,
      password: false,
      confirmPassword: false
    },
    errMessages: {
      firstName: 'Name is not valid.',
      lastName: 'Name is not valid.',
      email: 'Email is not valid',
      role: 'Role is required.',
      password:
        'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
      confirmPassword: "Password dosen't match"
    },
    dataLoaded: 0,
    userData: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: '',
      pushNotifications: true,
      sites: []
    },
    columns: [
      {
        title: 'Site Name',
        field: 'name',
        filtering: false,
        editComponent: props => (
          <TextField
            select
            style={{
              width: '120px'
            }}
            value={this.state.siteValue}
            onChange={this.handleAddSite}
            margin='dense'
            variant='outlined'
          >
            {this.state.sites.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )
      }
    ],
    siteValue: '',
    siteData: [],

    roleSelection: [
      {
        label: 'Admin',
        value: 'admin'
      },
      {
        label: 'Coordinator/Planner',
        value: 'planner'
      },
      {
        label: 'Foreman/HET',
        value: 'het'
      }
    ],

    tableOptions: {
      search: false,
      filtering: false,
      actionsColumnIndex: -1,
      paginationType: 'stepped',
      pageSize: 2,
      paging: false,
      showTitle: true,
      addRowPosition: 'first',
      headerStyle: {
        whiteSpace: 'noWrap',
        backgroundColor: '#525252',
        color: '#FFF',
        padding: 10,
        fontSize: 14
      },
      rowStyle: rowData => ({
        whiteSpace: 'noWrap',
        padding: 0,
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? '#EEE'
            : '#FFF'
      })
    }
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleChange = name => event => {
    let isSitesEnabled = true;
    let isDataInValid = { ...this.state.isDataInValid };
    const { userData } = { ...this.state };
    const passwordRegex = new RegExp(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W _]){1,})(?!.*\s).{8,}$/
    );
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    userData[name] = event.target.value;
    isDataInValid[name] = false;
    if (
      (name === 'role' && event.target.value === 'admin') ||
      userData.role === 'admin'
    ) {
      isSitesEnabled = false;
    }
    if (name === 'password') {
      isDataInValid[name] = !passwordRegex.test(event.target.value);
    }
    if (name === 'email') {
      isDataInValid[name] = !emailRegex.test(event.target.value);
    }
    if (name === 'confirmPassword') {
      isDataInValid[name] = !(
        userData['password'] === userData['confirmPassword']
      );
    }
    this.setState({ userData, isSitesEnabled, isDataInValid });
  };

  toggleNotificationSwitch = () => {
    let userData = { ...this.state.userData };
    userData.pushNotifications = !userData.pushNotifications;
    this.setState({ userData });
  };
  toggleActiveSwitch = () => {
    let userData = { ...this.state.userData };
    userData.isActive = !userData.isActive;
    this.setState({ userData });
  };

  handleAddSite = event => {
    this.setState({ siteValue: event.target.value });
  };

  evalData = dataObject => {
    let { isDataInValid } = { ...this.state };
    let { errMessages } = { ...this.state };
    let error = false;
    for (var item in dataObject) {
      if (dataObject[item] === '') {
        isDataInValid[item] = true;
        toast.error(errMessages[item]);
        error = true;
      }
    }
    if (error) {
      this.setState({ isLoading: false, isDataInValid });
      return false;
    }
    if (dataObject['password'] !== dataObject['confirmPassword']) {
      isDataInValid['confirmPassword'] = true;
      this.setState({ isLoading: false, isDataInValid });
      return false;
    }
    return true;
  };
  postItem = itemUrl => {
    const bodyData = { ...this.state.userData };
    const errMessages = { ...this.state.errMessages };
    let isDataInValid = { ...this.state.isDataInValid };
    this.setState({ isLoading: true });
    if (!this.evalData(bodyData)) {
      return;
    }
    if (bodyData.role === 'admin') {
      bodyData.pushNotifications = false;
    }
    axios
      .post(apiEndpoint + itemUrl, bodyData, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => {
        toast.success(`User created successfully`);
        const userData = {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          role: '',
          pushNotifications: true,
          sites: []
        };
        this.setState({ userData, siteData: [] });

        this.props.history.push(`/admin-portal/setup/users`);
      })
      .catch(err => {
        if (err.response) {
          const errorObject = err.response.data.errors;

          if (Object.keys(errorObject).length > 0) {
            for (var key in errorObject) {
              isDataInValid[key] = true;
              if (key === 'role') {
                toast.error('Role is required.');
              } else {
                if (key === 'email' && err.response.data.code === 409) {
                  errMessages['email'] = 'E-mail already exists.';
                }
                if (errorObject[key].message)
                  toast.error(errorObject[key].message);
              }
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      })
      .finally(() => {
        this.setState({ isLoading: false, isDataInValid, errMessages });
      });
  };

  updateData = itemUrl => {
    itemUrl += '/' + this.props.match.params.id;
    this.setState({ isLoading: true });
    const {
      firstName,
      lastName,
      email,
      role,
      pushNotifications,
      sites,
      password,
      isActive
    } = this.state.userData;
    const newItemData = {
      firstName,
      lastName,
      email,
      role,
      pushNotifications,
      sites,
      password,
      isActive
    };
    if (isActive) {
      newItemData.permissions = [role];
    }
    if (newItemData.password === '') {
      delete newItemData['password'];
    }
    axios
      .patch(apiEndpoint + itemUrl, newItemData, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => {
        toast.success(`User updated successfully`);
        this.props.history.push(`/admin-portal/setup/users`);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  componentDidMount = () => {
    [{ url: 'sites', data: 'sites' }].map(item => {
      const service = app.service(item.url);
      service
        .find({
          query: {
            $limit: null
          }
        })
        .then(response => {
          // handle success
          const resData = response.data;
          let newData = [];
          let siteLabelMaper = {};
          resData.map(resItem => {
            newData.push({
              label: resItem.name,
              value: resItem._id
            });
            siteLabelMaper[`${resItem._id}`] = resItem.name;
            siteLabelMaper[`${resItem.name}`] = resItem._id;
            return true;
          });
          //
          this.setState({
            [`${item.data}`]: newData,
            dataLoaded: this.state.dataLoaded + 1,
            siteLabelMaper
          });
          if (localStorage.getItem('text') === 'Edit') {
            const userId = this.props.match.params.id;
            axios
              .get(apiEndpoint + 'users/' + userId, {
                headers: {
                  Authorization: localStorage.getItem('token')
                }
              })
              .then(response => {
                // handle success
                const resData = response.data;
                const userData = {
                  id: resData._id,
                  firstName: resData.firstName,
                  lastName: resData.lastName,
                  email: resData.email,
                  role: resData.role,
                  pushNotifications: resData.pushNotifications,
                  isActive: resData.isActive
                };
                if (resData.sites) {
                  let siteData = [];
                  resData.sites.map(site => {
                    siteData.push({
                      name: this.state.siteLabelMaper[site]
                    });
                    return true;
                  });
                  //
                  userData['sites'] = resData.sites;
                  this.setState({ siteData });
                }
                this.setState({
                  userData,
                  dataLoaded: this.state.dataLoaded + 1,
                  isSitesEnabled: userData.role === 'admin' ? false : true
                });
              })
              .catch(error => {
                // handle error
              })
              .finally(() => {
                // always executed
              });
          } else {
            this.setState({ dataLoaded: this.state.dataLoaded + 5 });
          }
        })
        .catch(error => {
          // handle error
        });
      return true;
    });
  };

  submitHandler = event => {
    event.preventDefault();
    const submitType = localStorage.getItem('text');
    if (submitType === 'Add') {
      this.postItem('users');
    } else {
      this.updateData('users');
    }
  };

  render() {
    const { classes } = this.props;
    const {
      userData,
      dataLoaded,
      roleSelection,
      siteData,
      columns,
      tableOptions,
      isSitesEnabled,
      isDataInValid,
      errMessages
    } = this.state;
    const pageType = localStorage.getItem('text');
    return (
      <Box>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Typography variant='h4' gutterBottom>
              {localStorage.getItem('text')} User
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant='h6' gutterBottom>
                User Information
              </Typography>
              {dataLoaded >= 2 && (
                <form
                  className={classes.container}
                  autoComplete='off'
                  onSubmit={this.submitHandler}
                >
                  <Grid item container spacing={2} xs={12}>
                    {[
                      {
                        name: 'First Name',
                        value: 'firstName'
                      },
                      {
                        name: 'Last Name',
                        value: 'lastName'
                      }
                    ].map(item => (
                      <Grid item xs={3} key={item.name}>
                        <TextField
                          error={isDataInValid[item.value]}
                          required
                          autoFocus={item.name === 'First Name'}
                          autoComplete="new-password"
                          label={item.name}
                          className={classes.textField}
                          onChange={this.handleChange(item.value)}
                          value={userData[item.value]}
                          margin='dense'
                          variant='outlined'
                        />
                        <FormHelperText
                          className={classes.errorStyle}
                          hidden={!isDataInValid[item.value]}
                        >
                          {errMessages[item.value]}
                        </FormHelperText>
                      </Grid>
                    ))}
                    <Grid item xs={4}>
                      <TextField
                        error={isDataInValid['email']}
                        required
                        label='Email'
                        autoComplete="new-password"
                        className={classes.textField}
                        onChange={this.handleChange('email')}
                        value={userData.email}
                        margin='dense'
                        variant='outlined'
                      />
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid['email']}
                      >
                        {errMessages['email']}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={isDataInValid['role']}
                        required
                        select
                        label='Role'
                        autoComplete="new-password"
                        className={classes.textField}
                        value={userData.role}
                        onChange={this.handleChange('role')}
                        margin='dense'
                        variant='outlined'
                      >
                        {roleSelection.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid['role']}
                      >
                        {errMessages['role']}
                      </FormHelperText>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={3}>
                      <TextField
                        required={pageType !== 'Edit'}
                        error={isDataInValid['password']}
                        label='Password'
                        type='password'
                        autoComplete="new-password"
                        value={userData.password}
                        className={classes.textField}
                        margin='dense'
                        variant='outlined'
                        onChange={this.handleChange('password')}
                      />
                      <FormHelperText
                        // style={{ position: 'absolute', }}
                        className={classes.errorStyle}
                        hidden={!isDataInValid['password']}
                      >
                        {errMessages['password']}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        required={pageType !== 'Edit'}
                        error={isDataInValid['confirmPassword']}
                        label='Confirm Password'
                        type='password'
                        autoComplete="new-password"
                        value={userData.confirmPassword}
                        className={classes.textField}
                        margin='dense'
                        variant='outlined'
                        onChange={this.handleChange('confirmPassword')}
                      />
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid['confirmPassword']}
                      >
                        {errMessages['confirmPassword']}
                      </FormHelperText>
                    </Grid>
                    {isSitesEnabled && (
                      <Grid item xs={3} style={{ marginTop: 15 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              value={userData.pushNotifications}
                              checked={userData.pushNotifications}
                              onChange={this.toggleNotificationSwitch}
                            />
                          }
                          label='Push Notifications'
                          labelPlacement='start'
                        />
                      </Grid>
                    )}
                    {pageType === 'Edit' && (
                      <Grid item xs={3} style={{ marginTop: 15 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              value={userData.isActive}
                              checked={userData.isActive}
                              onChange={this.toggleActiveSwitch}
                            />
                          }
                          label='Active'
                          labelPlacement='start'
                        />
                      </Grid>
                    )}
                  </Grid>
                  {isSitesEnabled && (
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <MaterialTable
                          columns={columns}
                          data={siteData}
                          title='Sites'
                          options={tableOptions}
                          onRowClick={(evt, selectedRow) =>
                            this.setState({ selectedRow })
                          }
                          editable={{
                            onRowAdd:
                              (userData.role === 'het' &&
                                !userData.sites.length > 0) ||
                              userData.role === 'planner'
                                ? newData =>
                                    new Promise(resolve => {
                                      setTimeout(() => {
                                        resolve();
                                        const siteData = [
                                          ...this.state.siteData
                                        ];
                                        const userData = {
                                          ...this.state.userData
                                        };
                                        let siteLabelMaper = {
                                          ...this.state.siteLabelMaper
                                        };
                                        let { sites } = { ...this.state };

                                        const siteLabel =
                                          siteLabelMaper[
                                            `${this.state.siteValue}`
                                          ];
                                        userData.sites.push(
                                          this.state.siteValue
                                        );
                                        siteData.push({ name: siteLabel });
                                        sites = sites.filter(
                                          item => item.label !== siteLabel
                                        );

                                        this.setState({
                                          siteData,
                                          userData,
                                          siteValue: '',
                                          sites
                                        });
                                      }, 150);
                                    })
                                : null,
                            onRowUpdate: (newData, oldData) =>
                              new Promise(resolve => {
                                setTimeout(() => {
                                  resolve();

                                  const siteData = [...this.state.siteData];
                                  const userData = { ...this.state.userData };
                                  const oldSiteLabel = this.state
                                    .siteLabelMaper[oldData.name];
                                  const newSiteLabel = this.state
                                    .siteLabelMaper[`${this.state.siteValue}`];
                                  siteData[
                                    siteData.indexOf(oldData)
                                  ].name = newSiteLabel;

                                  userData.sites[
                                    userData.sites.indexOf(oldSiteLabel)
                                  ] = this.state.siteValue;
                                  this.setState({
                                    siteData,
                                    userData
                                  });
                                }, 150);
                              }),
                            onRowDelete: oldData =>
                              new Promise(resolve => {
                                setTimeout(() => {
                                  resolve();
                                  const siteData = [...this.state.siteData];
                                  const userData = { ...this.state.userData };
                                  const oldSiteLabel = this.state
                                    .siteLabelMaper[oldData.name];
                                  siteData.splice(siteData.indexOf(oldData), 1);
                                  userData.sites.splice(
                                    userData.sites.indexOf(oldSiteLabel),
                                    1
                                  );
                                  this.setState({
                                    userData,
                                    siteData
                                  });
                                }, 150);
                              })
                          }}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Link
                        color='inherit'
                        to={`/admin-portal/setup/users`}
                        className={classes.listText}
                      >
                        <Button
                          variant='outlined'
                          color='secondary'
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </Link>
                      {!this.state.isLoading && (
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.button}
                        >
                          Save
                        </Button>
                      )}
                      {this.state.isLoading && (
                        <Box
                          mx={4.1}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <CircularProgress
                            className={classes.button}
                            color='primary'
                            size={32}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </form>
              )}
              {dataLoaded < 2 && (
                <Grid item container xs={12} spacing={2}>
                  {[1, 2, 3, 4, 5, 6, 7].map(item => {
                    return (
                      <React.Fragment key={item}>
                        {item !== 3 && item !== 4 && (
                          <Grid item xs={3}>
                            <Skeleton height={35} />
                          </Grid>
                        )}
                        {item === 3 && (
                          <Grid item xs={4}>
                            <Skeleton height={35} />
                          </Grid>
                        )}
                        {item === 4 && (
                          <Grid item xs={2}>
                            <Skeleton height={35} />
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <Grid item xs={12}>
                    <Skeleton height={40} />
                    <Skeleton height={80} />
                    <Box
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Skeleton height={40} className={classes.button} />
                      <Skeleton height={40} className={classes.button} />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(AddEditUser));

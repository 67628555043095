import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
  picker: {
    width: "100%",
  },
  typography: { margin: "0 4px" },
}));

export default function MaterialUIPickers(props) {
  const classes = useStyles();
  const { shift, handleTimeChange } = props;
  const startDate = props.startDate
    ? moment(props.startDate).tz(props.timezone)
    : moment();
  const endDate = props.endDate
    ? moment(props.endDate).tz(props.timezone)
    : moment();

  // console.log(startDate, endDate, props.timezone);

  // const startDate = (props.startDate && props.timezone) ? moment.tz(moment(props.startDate).format('YYYY-MM-DD hh:mm:ss'), props.timezone).toDate() : moment().toDate();
  // const endDate = (props.endDate && props.timezone)? moment(props.endDate).tz(props.timezone).toDate(): moment().toDate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const convertDateToDayTime = date => {
  //   const zeroPad = str => String(str).padStart(2, '0');
  //   return `${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`;
  // };

  const convertDateToDayTime = (date) => {
    return date.format("HH:mm");
  };
  return (
    <Fragment>
      <TextField
        style={{ width: "100%" }}
        disabled
        inputProps={{ style: { cursor: "pointer", color: "black" } }}
        variant="outlined"
        onClick={() => {
          setIsDialogOpen(true);
        }}
        value={`${convertDateToDayTime(startDate)} to ${convertDateToDayTime(
          endDate
        )} ${
          startDate.toDate().getDate() !== endDate.toDate().getDate()
            ? "+1 Day"
            : ""
        }`}
        label={`${shift.charAt(0).toUpperCase() + shift.slice(1)} Shift`}
        margin="dense"
      />

      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogTitle>Choose time range</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid
              container
              spacing={1}
              style={{ display: "flex", placeItems: "center" }}
            >
              <Grid item xs={3}>
                <Typography className={classes.typography}>From</Typography>
              </Grid>
              <Grid item xs={9}>
                <TimePicker
                  disabled={props.disabled}
                  ampm={false}
                  className={classes.picker}
                  value={startDate}
                  onChange={(date) => {
                    handleTimeChange(date, shift, "start");
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.typography}>To</Typography>
              </Grid>
              <Grid item xs={9}>
                <TimePicker
                  disabled={props.disabled}
                  ampm={false}
                  className={classes.picker}
                  value={endDate}
                  onChange={(date) => {
                    handleTimeChange(date, shift, "end");
                  }}
                />
              </Grid>
              <Grid item xs={9} />
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => {
                    setIsDialogOpen(false);
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

import React from "react";
import {
  withStyles,
  withWidth,
  Grid,
  Typography,
  Box,
  Paper,
  CircularProgress,
  FormHelperText,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core/";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { toast } from "react-toastify";
import app from "../../../appConfig";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    flexWrap: "wrap",
    paddingTop: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    borderRadius: 5,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    width: theme.spacing(12),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  listText: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: "none",
  },
  errorStyle: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#f00",
  },
  endAdornmentStyle: {
    top: "unset",
  },
});

class AddEditStatus extends React.Component {
  state = {
    text: "",
    open: false,
    dataLoaded: 0,
    isDataInValid: {
      timestamp: false,
      site: false,
      location: false,
      equipment: false,
      currentSMU: false,
      estimatedDateOfRepair: false,
      equipmentStatus: false,
    },
    errMessages: {
      timestamp: "Date is not valid.",
      site: "Site is required.",
      location: "Location is required.",
      equipment: "Equipment is required.",
      currentSMU: "New SMU must be smaller that the last SMU",
      equipmentStatus: "Equipment Status is required.",
      estimatedDateOfRepair: "Estimated date of repair must be a future date",
    },
    tempStatusEntryData: {},
    statusEntryData: {
      timestamp: new Date(),
      site: -1,
      location: null,
      equipment: null,
      equipmentStatus: "",
      currentSMU: "",
      estimatedDateOfRepair: new Date(),
      additionalDetails: "",
      notes: "",
    },
    siteSelector: [],
    equipmentSelector: [],
    statusSelector: [
      {
        label: "Available",
        value: "AV",
      },
      {
        label: "Down",
        value: "DN",
      },
    ],
    columns: [
      {
        title: "Timestamp",
        field: "timestamp",
        filtering: false,
        defaultSort: "desc",
      },
      { title: "User", field: "userName", searchable: false },
    ],
    editLogData: [],
    isSiteSelected: false,
    tableOptions: {
      search: false,
      filtering: false,
      pageSize: 2,
      pageSizeOptions: [2],
      showTitle: false,
      isLoading: false,
      headerStyle: {
        whiteSpace: "noWrap",
        backgroundColor: "#525252",
        color: "#FFF",
        padding: 10,
        fontSize: 14,
      },
      rowStyle: (rowData) => ({
        whiteSpace: "noWrap",
        padding: 0,
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? "#EEE"
            : "#FFF",
      }),
    },
    sites: [],
    sitesMapper: {},
    equipments: [],
    equipmentsMapper: {},
  };

  handleClose = () => {
    this.props.handleClose();
  };
  handleTimestampDateChange = (date) => {
    let statusEntryData = { ...this.state.statusEntryData };
    statusEntryData.timestamp = new Date(date);
    this.setState({ statusEntryData });
  };
  handleDateChange = (date) => {
    let statusEntryData = { ...this.state.statusEntryData };
    statusEntryData.estimatedDateOfRepair = new Date(date);
    this.setState({ statusEntryData });
  };
  getSiteEquipments = async (siteIdx, equipmentId) => {
    const service = app.service(`equipments/${equipmentId}`);

    await service
      .find({})
      .then((response) => {
        this.setState({
          isSiteSelected: true,
          equipments: [response._id],
          equipmentsMapper: {
            [response._id]: response.number,
          },
        });
        // console.log('equipments', this.state.equipments);
        // console.log('equipmentsMapper', this.state.equipmentsMapper);
      })
      .catch((err) => {
        toast.error("Faild to get Equipment data, Try again.");
      })
      .finally(() => {});
  };
  handleChangeNDG = (event) => {
    const { statusEntryData } = this.state;
    if (!event.target.checked) {
      statusEntryData["estimatedDateOfRepair"] = Date();
      this.setState({ statusEntryData });
    }
    this.setState({ NDG: event.target.checked });
  };
  handleSiteChange = async (event, newValue) => {
    const { statusEntryData, sitesMapper } = this.state;
    const siteId = newValue;
    const service = app.service("equipments");

    statusEntryData["equipment"] = null;
    statusEntryData["location"] = null;
    statusEntryData["site"] = siteId;
    this.setState({ statusEntryData, equipments: [], equipmentsMapper: {} });

    if (newValue)
      await service
        .find({
          query: {
            site: siteId,
            $limit: null,
          },
        })
        .then((response) => {
          // console.log('response', response);
          let equipments = [];
          let equipmentSelector = [];
          let equipmentsMapper = {};
          let resData = response.data;

          if (resData.length > 0) {
            resData = resData.sort((a, b) => (a.number > b.number ? 1 : -1));
            // console.log('resData', resData);
            resData.forEach((equipment) => {
              equipments.push(equipment._id);
              equipmentSelector.push({
                _id: equipment._id,
                value: equipment._id,
                number: equipment.number,
              });
              equipmentsMapper[equipment._id] = equipment.number;
            });
          }
          sitesMapper[siteId].equipments = equipmentSelector;
          this.setState({
            isSiteSelected: true,
            sitesMapper,
            equipmentsMapper,
            equipments,
          });
        })
        .catch((err) => {
          toast.error("Faild to get site data, Try again.");
        })
        .finally(() => {});
  };
  handleLocationChange = (event, newValue) => {
    const statusEntryData = { ...this.state.statusEntryData };
    statusEntryData["location"] = newValue;
    this.setState({ statusEntryData });
  };

  handleEquipmentChange = (event, newValue) => {
    const statusEntryData = { ...this.state.statusEntryData };
    const equipmentId = newValue;
    // console.log('equipmentId', equipmentId);
    if (!equipmentId) {
      statusEntryData["additionalDetails"] = "";
      statusEntryData["notes"] = "";
    } else {
      this.getEquipmentData(equipmentId);
    }

    statusEntryData["equipment"] = equipmentId;
    this.setState({ statusEntryData });
  };

  handleChange = (name) => async (event) => {
    const { statusEntryData, siteSelector } = this.state;
    let isDataInValid = { ...this.state.isDataInValid };
    isDataInValid[name] = false;

    // if (name === 'equipment') {
    //   this.getEquipmentData(event.target.value);
    // }

    statusEntryData[name] = event.target.value;
    this.setState({ statusEntryData, isDataInValid });
  };
  getEquipmentData = async (equipmentId) => {
    let statusEntryData = { ...this.state.statusEntryData };

    const service = app.service(
      `equipment-latest-notes?equipmentId=${equipmentId}`
    );

    await service
      .find({})
      .then((response) => {
        // console.log('response', response);
        this.setState({
          statusEntryData: {
            ...this.state.statusEntryData,
            notes: response.notes,
            additionalDetails:
              localStorage.getItem("text") === "Edit"
                ? response.additionalDetails
                : "",
          },
        });
      })

      .catch((err) => {
        toast.error("Faild to get Equipment data, Try again.");
      })
      .finally(() => {});
  };
  getStatusEntry = (statusEntryId) => {
    axios
      .get(apiEndpoint + "status-entries/" + statusEntryId, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(async (response) => {
        // handle success
        const resData = response.data;
        const statusEntryData = {
          id: resData._id,
          timestamp: resData.timestamp,
          site: resData.site._id,
          location: resData.location,
          equipment: resData.equipment._id,
          equipmentStatus: resData.equipmentStatus,
          currentSMU: resData.currentSMU,
          estimatedDateOfRepair: resData.estimatedDateOfRepair,
          additionalDetails: resData.additionalDetails,
          notes: resData.notes,
        };
        const editLog = resData.editLog;
        let editLogData = [];
        editLog.forEach((log) => {
          editLogData.push({
            id: log._id,
            timestamp: new Date(log.updatedAt).toLocaleString(),
            userName: log.user.firstName[0] + "." + log.user.lastName,
          });
        });
        const tempStatusEntryData = { ...statusEntryData };
        if (localStorage.getItem("text") === "Edit") {
          await this.getSiteEquipments(
            statusEntryData.site,
            statusEntryData.equipment
          );
        }
        this.setState({
          NDG: resData.estimatedDateOfRepair ? false : true,
          tempStatusEntryData,
          statusEntryData,
          dataLoaded: this.state.dataLoaded + 1,
          editLogData,
        });
      })
      .catch((error) => {
        // handle error
        // console.log('error', error);
      })
      .finally(() => {
        // always executed
      });
  };
  componentDidMount = async (query) => {
    let estimatedDateOfRepair = new Date(
      this.state.statusEntryData.estimatedDateOfRepair
    );
    estimatedDateOfRepair.setDate(estimatedDateOfRepair.getDate() + 1);
    this.setState((prevState) => ({
      statusEntryData: {
        ...prevState.statusEntryData,
        estimatedDateOfRepair,
      },
    }));

    [{ url: "sites", data: "sites" }].map((item) => {
      const service = app.service(item.url);
      service
        .find({
          query: {
            ...query,
            $limit: null,
          },
        })
        .then((response) => {
          // handle success
          const resData = response.data;
          // console.log('resData', resData);
          let sites = [];
          let sitesMapper = {};
          resData.sort((a, b) => (a.name > b.name ? 1 : -1));
          resData.forEach((res, idx) => {
            res.locations.sort((a, b) => (a > b ? 1 : -1));
            res.equipments = [];
            sites.push(res._id);
            sitesMapper[res._id] = res;
          });
          // console.log('sites', sites);
          // console.log('sitesMapper', sitesMapper);
          let siteSelector = resData;
          let statusEntryData = this.state.statusEntryData;
          // statusEntryData.timestamp = new Date();
          // statusEntryData.estimatedDateOfRepair = new Date().toLocaleString();
          this.setState({
            sites,
            sitesMapper,
            statusEntryData,
            siteSelector,
            dataLoaded: this.state.dataLoaded + 1,
          });

          if (localStorage.getItem("text") === "Edit") {
            this.getStatusEntry(this.props.match.params.id);
            //
          } else {
            this.setState({ dataLoaded: this.state.dataLoaded + 5 });
          }
        })
        .catch((error) => {
          // handle error
        })
        .finally(() => {
          // always executed
        });
      return true;
    });
  };

  evalData = (dataObject) => {
    let isDataInValid = { ...this.state.isDataInValid };
    let errMessages = { ...this.state.errMessages };
    let error = false;
    for (var item in dataObject) {
      if (item === "estimatedDateOfRepair") {
        if (dataObject[item] < new Date()) {
          isDataInValid[item] = true;
          toast.error("Estimated Date of repair must be a future date.");
          error = true;
        }
      }
      if (
        dataObject[item] === "" &&
        item !== "additionalDetails" &&
        item !== "notes"
      ) {
        isDataInValid[item] = true;
        toast.error(errMessages[item]);
        error = true;
      }
    }
    if (error) {
      this.setState({ isLoading: false, isDataInValid });
      return false;
    }
    return true;
  };
  postItem = (itemUrl) => {
    const bodyData = { ...this.state.statusEntryData };
    let isDataInValid = { ...this.state.isDataInValid };
    // bodyData.site = this.state.siteSelector[bodyData.site]._id;
    this.setState({ isLoading: true });

    if (bodyData.equipmentStatus === "AV" || this.state.NDG) {
      delete bodyData.estimatedDateOfRepair;
    }

    if (!this.evalData(bodyData)) {
      return;
    }
    axios
      .post(apiEndpoint + itemUrl, bodyData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const statusEntryData = {
          timestamp: new Date(),
          site: -1,
          location: null,
          equipment: null,
          equipmentStatus: "",
          currentSMU: "",
          estimatedDateOfRepair: new Date(),
          additionalDetails: "",
          notes: "",
        };
        this.setState({ statusEntryData });
        toast.success("Status Entry created successful");
        this.props.history.push("/admin-portal/status-entries");
      })
      .catch((err) => {
        // console.log('err', err.response);
        if (err.response) {
          const errorObject = err.response.data.errors;

          if (Object.keys(errorObject).length > 0) {
            for (var key in errorObject) {
              isDataInValid[key] = true;
              if (key === "site") {
                toast.error("Site is required.");
              } else if (key === "equipment") {
                toast.error("Equipment is required.");
              } else if (key === "location") {
                toast.error("Location is required.");
              } else if (key === "equipmentStatus") {
                toast.error("Equipment Status is required.");
              } else {
                toast.error(errorObject[key].message);
              }
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      })
      .finally(() => {
        this.setState({ isLoading: false, isDataInValid });
      });
  };
  updateData = (itemUrl) => {
    itemUrl += "/" + this.props.match.params.id;
    const tempStatusEntryData = { ...this.state.tempStatusEntryData };
    const {
      timestamp,
      estimatedDateOfRepair,
      notes,
      additionalDetails,
      location,
    } = this.state.statusEntryData;
    const newItemData = {
      location,
      timestamp,
      estimatedDateOfRepair,
      notes,
      additionalDetails,
    };
    this.setState({ isLoading: true });
    Object.keys(newItemData).forEach((key) => {
      if (tempStatusEntryData[key] === newItemData[key]) {
        delete newItemData[key];
      }
    });

    if (this.state.statusEntryData.equipmentStatus === "AV") {
      delete newItemData.estimatedDateOfRepair;
    } else if (this.state.NDG) {
      newItemData.estimatedDateOfRepair = null;
    }

    axios
      .patch(apiEndpoint + itemUrl, newItemData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success("Status Entry updated successfully");
        this.props.history.push("/admin-portal/status-entries");
      })
      .catch((err) => {
        if (err.response) {
          // this.setState({ isEmailValid: false });
          toast.error(err.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  submitHandler = (event) => {
    event.preventDefault();
    const submitType = localStorage.getItem("text");
    if (submitType === "Add") {
      this.postItem("status-entries");
    } else {
      this.updateData("status-entries");
    }
  };
  render() {
    const { classes } = this.props;
    const {
      statusSelector,
      statusEntryData,
      columns,
      editLogData,
      tableOptions,
      isDataInValid,
      errMessages,
      dataLoaded,
      sites,
      sitesMapper,
      equipments,
      equipmentsMapper,
      isSiteSelected,
    } = this.state;
    const siteIdx = statusEntryData.site;
    const pageType = localStorage.getItem("text");
    // console.log('statusEntryData', statusEntryData);

    return (
      <Box>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Typography variant="h4" gutterBottom>
              {localStorage.getItem("text")} Status Entry
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                General information
              </Typography>
              {dataLoaded >= 2 && (
                <form
                  // className={classes.container}
                  onSubmit={this.submitHandler}
                >
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          className={classes.textField}
                          // disabled={pageType === 'Edit'}
                          autoFocus
                          ampm={false}
                          inputVariant="outlined"
                          format="dd/MM/yyyy HH:mm"
                          margin="dense"
                          label="Timestamp"
                          value={statusEntryData.timestamp}
                          onChange={this.handleTimestampDateChange}
                        />
                      </MuiPickersUtilsProvider>
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["timestamp"]}
                      >
                        {errMessages["timestamp"]}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        id="sites-autocomplete"
                        classes={{ endAdornment: classes.endAdornmentStyle }}
                        disabled={pageType === "Edit"}
                        options={sites}
                        getOptionLabel={(option) =>
                          sitesMapper[option] ? sitesMapper[option].name : ""
                        }
                        value={statusEntryData["site"]}
                        onChange={this.handleSiteChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Sites"
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                      />
                      {/* <TextField
                        select
                        label='Site'
                        error={isDataInValid['site']}
                        required
                        disabled={pageType === 'Edit'}
                        className={classes.textField}
                        value={this.state.statusEntryData['site']}
                        onChange={this.handleChange('site')}
                        margin='dense'
                        variant='outlined'
                      >
                        {siteSelector.map((option, idx) => (
                          <MenuItem key={option.name} value={idx}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField> */}
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["site"]}
                      >
                        {errMessages["site"]}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        id="locations-autocomplete"
                        disabled={
                          siteIdx === -1 ||
                          // pageType === "Edit" ||
                          !isSiteSelected
                        }
                        error={isDataInValid["location"]}
                        classes={{ endAdornment: classes.endAdornmentStyle }}
                        options={
                          sitesMapper[siteIdx]
                            ? sitesMapper[siteIdx].locations
                            : []
                        }
                        getOptionLabel={(option) => option}
                        value={statusEntryData["location"]}
                        onChange={this.handleLocationChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Location"
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                      />
                      {/* <TextField
                        select={siteIdx !== -1}
                        disabled={
                          siteIdx === -1 ||
                          pageType === 'Edit' ||
                          !isSiteSelected
                        }
                        error={isDataInValid['location']}
                        label='Location'
                        required
                        className={classes.textField}
                        value={this.state.statusEntryData['location']}
                        onChange={this.handleChange('location')}
                        margin='dense'
                        variant='outlined'
                      >
                        {siteIdx !== -1 &&
                          sitesMapper[siteIdx] &&
                          sitesMapper[siteIdx].locations.map((location) => (
                            <MenuItem key={location} value={location}>
                              {location}
                            </MenuItem>
                          ))}
                      </TextField> */}
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["location"]}
                      >
                        {errMessages["location"]}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        id="sites-autocomplete"
                        classes={{ endAdornment: classes.endAdornmentStyle }}
                        disabled={
                          siteIdx === -1 ||
                          pageType === "Edit" ||
                          !isSiteSelected
                        }
                        options={equipments}
                        getOptionLabel={(option) => {
                          // console.log('option', option);
                          return equipmentsMapper[option];
                        }}
                        onChange={this.handleEquipmentChange}
                        value={statusEntryData.equipment}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Equipment Number"
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                      />
                      {/* <TextField
                        select={siteIdx !== -1}
                        disabled={
                          siteIdx === -1 ||
                          pageType === 'Edit' ||
                          !isSiteSelected
                        }
                        error={isDataInValid['equipment']}
                        label='Equipment Number'
                        required
                        className={classes.textField}
                        value={this.state.statusEntryData['equipment']}
                        onChange={this.handleChange('equipment')}
                        margin='dense'
                        variant='outlined'
                      >
                        {siteIdx !== -1 &&
                          siteSelector[siteIdx] &&
                          siteSelector[siteIdx].equipments &&
                          siteSelector[siteIdx].equipments.map((equipment) => (
                            <MenuItem key={equipment._id} value={equipment._id}>
                              {equipment.number}
                            </MenuItem>
                          ))}
                      </TextField> */}
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["equipment"]}
                      >
                        {errMessages["equipment"]}
                      </FormHelperText>
                    </Grid>
                    {/* ))} */}
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <Typography variant="h6" gutterBottom>
                      Entry Details
                    </Typography>
                    <Grid container item xs={12} spacing={2}>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent:
                            statusEntryData.equipmentStatus !== "DN"
                              ? "flex-start"
                              : "space-around",
                        }}
                      >
                        <TextField
                          select
                          label="Status"
                          disabled={pageType === "Edit"}
                          error={isDataInValid["equipmentStatus"]}
                          className={classes.textField}
                          value={this.state.statusEntryData.equipmentStatus}
                          onChange={this.handleChange("equipmentStatus")}
                          margin="dense"
                          variant="outlined"
                        >
                          {statusSelector.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <FormHelperText
                          className={classes.errorStyle}
                          hidden={!isDataInValid["equipmentStatus"]}
                        >
                          {errMessages["equipmentStatus"]}
                        </FormHelperText>
                        <TextField
                          label="Current SMU"
                          required
                          type="number"
                          error={isDataInValid["currentSMU"]}
                          disabled={pageType === "Edit"}
                          className={classes.textField}
                          value={statusEntryData.currentSMU}
                          onChange={this.handleChange("currentSMU")}
                          margin="dense"
                          variant="outlined"
                        />
                        <FormHelperText
                          className={classes.errorStyle}
                          hidden={!isDataInValid["currentSMU"]}
                        >
                          {errMessages["currentSMU"]}
                        </FormHelperText>

                        {statusEntryData.equipmentStatus !== "AV" &&
                          statusEntryData.equipmentStatus !== "" && (
                            <Box>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  className={classes.textField}
                                  disabled={
                                    statusEntryData.equipmentStatus === "AV" ||
                                    this.state.NDG
                                  }
                                  inputVariant="outlined"
                                  format="dd/MM/yyyy"
                                  margin="dense"
                                  label="Estimated Date of Repair"
                                  value={statusEntryData.estimatedDateOfRepair}
                                  onChange={this.handleDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                              <Box ml={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="NDG"
                                      checked={this.state.NDG}
                                      onChange={this.handleChangeNDG}
                                    />
                                  }
                                  label="No Date Given"
                                />
                              </Box>
                            </Box>
                          )}
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          autoFocus={localStorage.getItem("text") === "Edit"}
                          label="Current Issues"
                          multiline
                          rows="4"
                          value={statusEntryData.additionalDetails}
                          className={classes.textField}
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChange("additionalDetails")}
                        />
                        <TextField
                          label="Comments"
                          multiline
                          rows="4"
                          value={statusEntryData.notes}
                          className={classes.textField}
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChange("notes")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {localStorage.getItem("text") === "Edit" && (
                    <Grid item xs={12} style={{ marginTop: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        Edit History
                      </Typography>
                      <MaterialTable
                        columns={columns}
                        data={editLogData}
                        onRowClick={(evt, selectedRow) => {
                          // this.props.history.push();
                          this.setState({ selectedRow });
                        }}
                        options={tableOptions}
                        components={{
                          Header: () => (
                            <TableHead>
                              <TableRow
                                style={{
                                  backgroundColor: "#525252",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {["Timestamp", "User"].map((item, idx) => (
                                  <TableCell
                                    style={{
                                      color: "#fff",
                                    }}
                                    align="left"
                                    key={idx}
                                  >
                                    <Box style={{ display: "flex" }}>
                                      {item}
                                    </Box>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                          ),
                          Toolbar: (props) => <Box mt={3}></Box>,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Link
                        color="inherit"
                        to={`/admin-portal/status-entries`}
                        className={classes.listText}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </Link>
                      {!this.state.isLoading && (
                        <Button
                          disabled={siteIdx === -1}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          Save
                        </Button>
                      )}
                      {this.state.isLoading && (
                        <CircularProgress
                          className={classes.button}
                          color="primary"
                          size={32}
                        />
                      )}
                    </Box>
                  </Grid>
                </form>
              )}
              {dataLoaded < 2 && (
                <Grid item container xs={12} spacing={2}>
                  {[1, 2, 3, 4].map((item) => {
                    return (
                      <Grid item xs={3} key={item}>
                        <Skeleton height={35} />
                      </Grid>
                    );
                  })}

                  <Grid container item xs={12} spacing={2}>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Skeleton height={40} style={{ marginBottom: "30px" }} />
                      <Skeleton height={40} style={{ marginBottom: "30px" }} />
                    </Grid>
                    <Grid item xs={9} style={{ alignItems: "center" }}>
                      <Skeleton height={90} />
                      <Skeleton height={90} />
                    </Grid>
                    {pageType === "Edit" && (
                      <Grid item xs={12}>
                        <Skeleton height={30} />
                        <Skeleton height={85} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Skeleton height={40} className={classes.button} />
                        <Skeleton height={40} className={classes.button} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(AddEditStatus));

import React from "react";
import {
  withStyles,
  Grid,
  Box,
  IconButton,
  Button,
  Tooltip,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  Chip,
  CircularProgress,
  Typography,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faFilter,
  faArrowUp,
  faArrowsAltV,
} from "@fortawesome/free-solid-svg-icons";
import SideFilter from "../../SideFilter";
import app from "../../../../appConfig";
import moment from "moment";
import { useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
const download = require("downloadjs");

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const queryNameMapper = {
  "Site Name": "name",
  Timezone: "timezone",
  "Day Shift": "dayShift",
  "Night Shift": "nightShift",
};

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    borderRadius: 5,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    // margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

class SiteSetup extends React.Component {
  state = {
    isDrawerOpen: false,
    isDialogOpen: false,
    columns: [
      {
        title: "Site Name",
        field: "siteName",
        filtering: false,
      },
      {
        title: "Timezone",
        field: "timezone",
        filtering: false,
        searchable: false,
      },
      {
        title: "Day Shift",
        field: "dayShift",
        filtering: false,
        searchable: false,
      },
      {
        title: "Night Shift",
        field: "nightShift",
        filtering: false,
        searchable: false,
      },
    ],
    data: [],
    tableOptions: {
      search: false,
      filtering: false,
      sorting: false,
      actionsColumnIndex: -1,
      pageSize: 10,
      paginationType: "stepped",
      pageSizeOptions: [5, 10, 15, 20],
      headerStyle: {
        whiteSpace: "noWrap",
        backgroundColor: "#525252",
        color: "#FFF",
        padding: 10,
      },
      rowStyle: (rowData) => ({
        whiteSpace: "noWrap",
        padding: 0,
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? "#EEE"
            : "#FFF",
      }),
    },
    paginationOptions: { page: 0, limit: 10, total: 0, skip: 0 },
    query: {},
    filterQuery: {},
    filterDetails: {},
    sortQuery: {},
    isDataFiltered: false,
    sortList: {
      "Site Name": 0,
      Timezone: 0,
      "Day Shift": 0,
      "Night Shift": 0,
    },
    labels: ["Site Name"],
  };

  setFilter = (name, data) => {
    let query = { ...this.state.query };
    query[name] = data;
    this.setState({ query });
  };
  convertTimestampToTime = (timeStamp, timezone) => {
    let time = timezone
      ? moment(timeStamp).tz(timezone).format("HH:mm")
      : moment(timeStamp).format("HH:mm");
    // let timeTag = time[time.length - 2] + time[time.length - 1];
    // time = time.slice(0, time.length - 6) + timeTag;

    return time;
  };
  setFilterStatus = (isFilterEnabled) => {
    this.setState({ isDataFiltered: isFilterEnabled });
  };
  changeQuery = (query) => {
    const newQuery = { ...this.state.query, ...query };
    this.setState({ query: newQuery }, () => {});
  };
  resetPages = () => {
    const paginationOptions = { ...this.state.paginationOptions };
    paginationOptions.page = 0;
    paginationOptions.skip = 0;
    this.setState({ paginationOptions });
  };

  getItems = () => {
    const { paginationOptions } = this.state;
    let query = {
      ...this.state.query,
      ...this.state.filterQuery,
      $skip: paginationOptions.limit * paginationOptions.page,
      $limit: paginationOptions.limit,
    };
    const service = app.service("sites");

    this.setState({ isTableLoading: true });
    service
      .find({
        query,
      })
      .then((response) => {
        // handle success
        const resData = response.data;
        let tableData = [];
        const paginationOptions = {
          skip: response.skip,
          total: response.total,
          limit: response.limit,
        };
        this.setState({
          paginationOptions: {
            ...this.state.paginationOptions,
            ...paginationOptions,
          },
        });
        resData.forEach((site) => {
          this.convertTimestampToTime(site.shifts.day.start, site.timezone);
          const dayShift =
            this.convertTimestampToTime(site.shifts.day.start, site.timezone) +
            " - " +
            this.convertTimestampToTime(site.shifts.day.end, site.timezone);
          const nightShift =
            this.convertTimestampToTime(
              site.shifts.night.start,
              site.timezone
            ) +
            " - " +
            this.convertTimestampToTime(site.shifts.night.end, site.timezone);

          tableData.push({
            id: site._id,
            siteName: site.name,
            timezone: site.timezone,
            dayShift,
            nightShift,
          });
        });
        this.setState({ data: tableData });
      })
      .catch((err) => {
        // handle error
        if (err) {
        }
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };
  deleteItem = (itemUrl, itemData) => {
    itemUrl += "/" + itemData.id;
    axios
      .delete(apiEndpoint + itemUrl, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success(`Site deleted successfully.`);
        this.getItems();
      })
      .catch((err) => {
        if (err.response) {
          // console.log(err.response);
          // toast.error(err.response.data.message);
        }
      })
      .finally(() => {});
  };
  componentDidMount = () => {
    this.props.changeHeader("Sites");

    this.getItems();
  };

  downloadFunc = (itemName, itemUrl, fileName, token) => {
    axios
      .get(`${itemUrl}${fileName}?token=${token}`)
      .then((response) => {
        download(
          response.data,
          `sites-${moment().format("MM-DD-YYYY")}.csv`,
          "text/csv",
          () => {}
        );
      })
      .finally(() => {});
  };

  handleExport = (itemName) => {
    let token = null;
    let fileName = null;

    let itemUrl = apiEndpoint;
    const io = require("socket.io-client");
    const socket = io(apiEndpoint, {
      transports: ["websocket", "polling"],
    });

    socket.on("connect", () => {
      console.log("Connected.");
      socket.emit(
        "create",
        "authentication",
        {
          strategy: "jwt",
          accessToken: localStorage.getItem("token"),
        },
        (error, authResult) => {
          socket.emit(
            "find",
            "export-csv",
            {
              model: "sites",
              ...this.state.filterQuery,
            },
            (error, data) => {
              if (error) return 0;
            }
          );
        }
      );
    });

    socket.on("export-csv-complete", (data) => {
      fileName = data.fileName;
      token = data.token;

      // const service = app.service(`${itemUrl}${fileName}?token=${token}`);
      this.props.snackbar.current.download(this.downloadFunc, [
        itemName,
        itemUrl,
        fileName,
        token,
      ]);

      socket.disconnect();
    });
  };
  sortData = (itemName, sortIdx) => {
    if (itemName === "Actions") {
      return;
    }
    let sortList = { ...this.state.sortList };
    const sortQuery = {};
    let currentState = sortList[itemName];
    sortList = {
      "Site Name": 0,
      Timezone: 0,
      "Day Shift": 0,
      "Night Shift": 0,
    };
    if (currentState === 1) {
      currentState = -1;
    } else if (currentState === -1) {
      currentState = 1;
    } else {
      currentState = 1;
    }
    sortList[itemName] = currentState;
    sortQuery["$sort"] = { [`${queryNameMapper[itemName]}`]: currentState };
    this.setState({ sortList, sortIdx, sortQuery }, () => {
      this.getItems();
    });
  };
  componentWillUnmount = () => {
    const labels = { ...this.state.labels };
    for (var i in labels) {
      localStorage.removeItem(`${labels[i].label}SelectedItems`);
    }
  };
  setFilterQuery = (filterQuery, filterDetails) => {
    this.setState({ filterQuery, filterDetails }, () => {});
  };

  render() {
    const { classes, snackbar } = this.props;
    const {
      columns,
      data,
      tableOptions,
      isTableLoading,
      labels,

      filterDetails,
    } = this.state;
    const { isCsvLoading } = this.props;
    function TablePaginationActions(props) {
      const theme = useTheme();
      const { count, page, rowsPerPage, onChangePage } = props;

      const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
      };

      const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
      };

      const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
      };

      const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
        <div
          style={{
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
          }}
        >
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      );
    }
    return (
      <Box mt={2}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Box>
              {Object.keys(filterDetails).length > 0 && (
                <Box>
                  {Object.keys(filterDetails).map((key) => (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ marginRight: "5px" }}
                      >
                        {key}:
                      </Typography>
                      {filterDetails[key] &&
                        filterDetails[key].map((itemName) => (
                          <Chip
                            color="primary"
                            variant="outlined"
                            label={itemName}
                            size="small"
                            style={{ marginRight: "3px" }}
                          />
                        ))}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              {isCsvLoading ? (
                <CircularProgress size={22} />
              ) : (
                <Tooltip title="Export CSV">
                  <IconButton
                    className={classes.button}
                    aria-label="exportCSV"
                    onClick={(event) => {
                      this.handleExport("sites");
                      snackbar.current.handleClick();
                    }}
                  >
                    <FontAwesomeIcon icon={faFileCsv} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip Open title="Open Filter">
                <IconButton
                  className={classes.button}
                  aria-label="Open Filter"
                  onClick={(event) => this.sideFilter.openDrawer()}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </IconButton>
              </Tooltip>
              <Button
                variant="outlined"
                color="default"
                className={classes.button}
                size="small"
                onClick={(event, rowData) => {
                  localStorage.setItem("text", "Add");
                  this.props.history.push(
                    "/admin-portal/setup/sites/add-edit-site/0"
                  );
                }}
              >
                Add
                <AddIcon className={classes.rightIcon} />
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mt={2} style={{ maxWidth: "100%", height: "80%" }}>
              <MaterialTable
                isLoading={isTableLoading}
                columns={columns}
                data={data}
                title=""
                onRowClick={(evt, selectedRow) =>
                  this.setState({ selectedRow })
                }
                options={tableOptions}
                editable={{
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        this.deleteItem("sites", oldData);
                      }, 200);
                    }),
                }}
                components={{
                  Pagination: () => (
                    <TableRow
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <TablePagination
                        ActionsComponent={TablePaginationActions}
                        rowsPerPageOptions={[5, 10, 20]}
                        style={{ width: "100%" }}
                        count={this.state.paginationOptions.total}
                        rowsPerPage={this.state.paginationOptions.limit}
                        page={this.state.paginationOptions.page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                        }}
                        onChangePage={async (event, page) => {
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              page: page,
                            },
                          });

                          this.getItems();
                        }}
                        onChangeRowsPerPage={async (event) => {
                          const pageSize = parseInt(event.target.value);
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              limit: pageSize,
                              page: 0,
                            },
                          });
                          this.setState({
                            tableOptions: {
                              ...this.state.tableOptions,
                              pageSize: pageSize,
                            },
                          });
                          this.getItems();
                        }}
                      />
                    </TableRow>
                  ),
                  Header: () => (
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "#525252",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {[
                          "Site Name",
                          "Timezone",
                          "Day Shift",
                          "Night Shift",
                          "Actions",
                        ].map((item, idx) => (
                          <TableCell
                            style={{
                              color: "#fff",
                              cursor:
                                item !== "Actions" ? "pointer" : "default",
                            }}
                            onClick={() => this.sortData(item, idx)}
                            align="left"
                            key={idx}
                          >
                            <Box style={{ display: "flex" }}>
                              {item}
                              {this.state.sortList[item] === 0 && (
                                <Box ml={1}>
                                  <FontAwesomeIcon
                                    icon={faArrowsAltV}
                                  ></FontAwesomeIcon>
                                </Box>
                              )}
                              {this.state.sortList[item] !== 0 && (
                                <Box ml={1}>
                                  {item !== "Actions" && (
                                    <FontAwesomeIcon
                                      rotation={
                                        this.state.sortList[item] === 1
                                          ? 0
                                          : 180
                                      }
                                      // onClick={this.sortData}
                                      icon={faArrowUp}
                                    />
                                  )}
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  ),
                  Toolbar: () => <Box></Box>,
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit Site",
                    onClick: (event, rowData) => {
                      localStorage.setItem("text", "Edit");
                      const siteId = rowData.id;
                      //
                      this.props.history.push(
                        `/admin-portal/setup/sites/add-edit-site/${siteId}`
                      );
                    },
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        <SideFilter
          ref={(sideFilter) => {
            this.sideFilter = sideFilter;
          }}
          labels={labels}
          getItems={this.getItems}
          changeQuery={this.changeQuery}
          setFilterStatus={this.setFilterStatus}
          setFilterQuery={this.setFilterQuery}
          resetPages={this.resetPages}
        ></SideFilter>
      </Box>
    );
  }
}
export default withStyles(styles)(SiteSetup);

import React from 'react';
import {
  withStyles,
  withWidth,
  Grid,
  Box,
  Paper,
  TablePagination,
  TableCell,
  TableRow,
  TableHead
} from '@material-ui/core/';
import MaterialTable from 'material-table';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import app from '../../../../appConfig';

import { faArrowsAltV, faArrowUp } from '@fortawesome/free-solid-svg-icons';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const queryNameMapper = {
  'Billing Rate Group': 'name',
  'Equipment Class': 'name',
  'Custom Tags': 'name'
};

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 5
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button: {
    width: theme.spacing(12),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1)
  },
  listText: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: 'none'
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
});

class ReportingTags extends React.Component {
  state = {
    sortList: {
      'Billing Rate Group': 0,
      'Equipment Class': 0,
      'Custom Tags': 0
    },

    isDrawerOpen: false,
    billingRateColumns: [
      {
        title: 'Billing Rate Group',
        field: 'billingRate'
      }
    ],
    equipmetClassColumns: [
      {
        title: 'Equipment Class',
        field: 'equipmentClass'
      }
    ],
    customTagsColumns: [
      {
        title: 'Custom Tag',
        field: 'customTag'
      }
    ],
    billingRate: [],
    equipmentClass: [],
    customTag: [],

    billingRateTableOptions: {
      search: false,
      filtering: false,
      actionsColumnIndex: -1,
      paging: true,
      paginationType: 'stepped',
      pageSize: 2,
      addRowPosition: 'first',
      showTitle: true,
      headerStyle: {
        whiteSpace: 'noWrap',
        backgroundColor: '#525252',
        color: '#FFF',
        padding: 10,
        fontSize: 14
      },
      rowStyle: rowData => ({
        whiteSpace: 'noWrap',
        padding: 0,
        backgroundColor:
          this.state.selectedRow_1 &&
          this.state.selectedRow_1.tableData.id === rowData.tableData.id
            ? '#EEE'
            : '#FFF'
      })
    },
    equipmentClassTableOptions: {
      search: false,
      filtering: false,
      actionsColumnIndex: -1,
      paging: true,
      paginationType: 'stepped',
      pageSize: 2,
      addRowPosition: 'first',
      showTitle: true,
      headerStyle: {
        whiteSpace: 'noWrap',
        backgroundColor: '#525252',
        color: '#FFF',
        padding: 10,
        fontSize: 14
      },
      rowStyle: rowData => ({
        whiteSpace: 'noWrap',
        padding: 0,
        backgroundColor:
          this.state.selectedRow_2 &&
          this.state.selectedRow_2.tableData.id === rowData.tableData.id
            ? '#EEE'
            : '#FFF'
      })
    },
    customTagsTableOptions: {
      search: false,
      filtering: false,
      actionsColumnIndex: -1,
      paging: true,
      paginationType: 'stepped',
      pageSize: 2,
      addRowPosition: 'first',
      showTitle: true,
      headerStyle: {
        whiteSpace: 'noWrap',
        backgroundColor: '#525252',
        color: '#FFF',
        padding: 10,
        fontSize: 14
      },
      rowStyle: rowData => ({
        whiteSpace: 'noWrap',
        padding: 0,
        backgroundColor:
          this.state.selectedRow_3 &&
          this.state.selectedRow_3.tableData.id === rowData.tableData.id
            ? '#EEE'
            : '#FFF'
      })
    },
    billingRatePaginationOptions: { page: 0, limit: 2, total: 0, skip: 0 },
    equipmentClassPaginationOptions: { page: 0, limit: 2, total: 0, skip: 0 },
    customTagsPaginationOptions: { page: 0, limit: 2, total: 0, skip: 0 },
    isbillingRateLoading: true,
    iscustomTagLoading: true,
    isequipmentClassLoading: true,
    query: {}
  };
  getItems = (itemUrl, itemName, paginationOptions) => {
    let query = { ...this.state.query };
    const service = app.service(itemUrl);
    this.setState({ [`is${itemName}Loading`]: true });
    query = {
      ...query,
      $skip:
        this.state[paginationOptions].limit *
        this.state[paginationOptions].page,
      $limit: this.state[paginationOptions].limit
    };

    service
      .find({ query })
      .then(response => {
        // handle success

        const resData = response.data;
        const tempPaginationsOprions = {
          skip: response.skip,
          total: response.total
        };
        this.setState({
          [`${paginationOptions}`]: {
            ...this.state[paginationOptions],
            ...tempPaginationsOprions
          }
        });
        let newData = [];
        resData.map(resItem => {
          newData.push({
            id: resItem._id,
            [`${itemName}`]: resItem.name
          });
          return true;
        });
        this.setState({
          [`${itemName}`]: newData,
          [`is${itemName}Loading`]: false
        });
      })
      .catch(error => {
        // handle error
      })
      .finally(() => {
        // always executed
      });
  };
  componentDidMount = () => {
    this.props.changeHeader('Setup | Reporting tags');
    this.getItems(
      'billing-rates',
      'billingRate',
      'billingRatePaginationOptions'
    );
    this.getItems(
      'equipment-classes',
      'equipmentClass',
      'equipmentClassPaginationOptions'
    );
    this.getItems('custom-tags', 'customTag', 'customTagsPaginationOptions');
  };

  sortData = (itemName, sortIdx) => {
    if (itemName === 'Actions') {
      return;
    }
    let sortList = { ...this.state.sortList };
    const query = { ...this.state.query };
    let currentState = sortList[itemName];

    if (currentState === 1) {
      currentState = -1;
    } else if (currentState === -1) {
      currentState = 1;
    } else {
      currentState = 1;
    }
    sortList[itemName] = currentState;
    query['$sort'] = { [`${queryNameMapper[itemName]}`]: currentState };
    this.setState({ sortList, sortIdx, query }, () => {
      if (itemName === 'Billing Rate Group') {
        this.getItems(
          'billing-rates',
          'billingRate',
          'billingRatePaginationOptions'
        );
      } else if (itemName === 'Equipment Class') {
        this.getItems(
          'equipment-classes',
          'equipmentClass',
          'equipmentClassPaginationOptions'
        );
      } else {
        this.getItems(
          'custom-tags',
          'customTag',
          'customTagsPaginationOptions'
        );
      }
    });
  };

  postItem = (itemName, itemUrl, itemData) => {
    const bodyData = {
      name: itemData[itemName]
    };
    axios
      .post(apiEndpoint + itemUrl, bodyData, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => {
        const data = this.state[itemName];
        data.push({
          id: res.data._id,
          [`${itemName}`]: res.data.name
        });
        this.setState({ [`${itemName}`]: data });
        const names = {
          billingRate: 'Billing rate',
          customTag: 'Custom tag',
          equipmentClass: 'Equipment class'
        };
        toast.success(`${names[itemName]} created successfully`);
      })
      .catch(err => {
        if (err.response) {
          // this.setState({ isEmailValid: false });
          toast.error(err.response.data.message);
        }
      })
      .finally(() => {});
  };
  deleteItem = (itemName, itemUrl, itemData) => {
    itemUrl += '/' + itemData.id;
    axios
      .delete(apiEndpoint + itemUrl, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => {
        const names = {
          billingRate: 'Billing rate',
          customTag: 'Custom tag',
          equipmentClass: 'Equipment class'
        };
        toast.success(`${names[itemName]} deleted successfully`);
      })
      .catch(err => {
        if (err.response) {
          // this.setState({ isEmailValid: false });

          toast.error(err.response.data.message);
        }
      })
      .finally(() => {});
  };
  updateData = (itemName, itemUrl, itemData) => {
    itemUrl += '/' + itemData.id;

    const bodyData = {
      name: itemData[itemName]
    };
    axios
      .patch(apiEndpoint + itemUrl, bodyData, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => {
        //
        const resItem = res.data;
        const data = this.state[itemName];
        data[data.indexOf(itemData)] = {
          id: resItem._id,
          [`${itemName}`]: resItem.name
        };
        this.setState({ [`${itemName}`]: data });
        toast.success(`${itemName} Updated Successfully`);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      })
      .finally(() => {});
  };
  render() {
    const { classes } = this.props;
    const {
      customTagsTableOptions,
      billingRateTableOptions,
      equipmentClassTableOptions,
      billingRateColumns,
      equipmetClassColumns,
      customTagsColumns,
      billingRate,
      customTag,
      equipmentClass,
      isequipmentClassLoading,
      iscustomTagLoading,
      isbillingRateLoading
    } = this.state;

    return (
      <Box mt={3}>
        <Grid container className={classes.container} spacing={2}>
          {/* <Grid item xs={12} className={classes.tableHeader}>
            <Typography variant='h4' gutterBottom>
              Reporting Tags
            </Typography>
          </Grid> */}
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <Box>
                <MaterialTable
                  isLoading={isbillingRateLoading}
                  columns={billingRateColumns}
                  data={billingRate}
                  title='Billing Rate Groups'
                  onRowClick={(evt, selectedRow_1) =>
                    this.setState({ selectedRow_1 })
                  }
                  options={billingRateTableOptions}
                  components={{
                    Pagination: () => (
                      <TableRow
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <TablePagination
                          rowsPerPageOptions={[2, 4, 6, 8]}
                          style={{ width: '100%' }}
                          count={this.state.billingRatePaginationOptions.total}
                          rowsPerPage={
                            this.state.billingRatePaginationOptions.limit
                          }
                          page={this.state.billingRatePaginationOptions.page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' }
                          }}
                          onChangePage={async (event, page) => {
                            await this.setState({
                              billingRatePaginationOptions: {
                                ...this.state.billingRatePaginationOptions,
                                page: page
                              }
                            });
                            this.getItems(
                              'billing-rates',
                              'billingRate',
                              'billingRatePaginationOptions'
                            );
                          }}
                          onChangeRowsPerPage={async event => {
                            const pageSize = parseInt(event.target.value);
                            await this.setState({
                              billingRatePaginationOptions: {
                                ...this.state.billingRatePaginationOptions,
                                limit: pageSize,
                                page: 0
                              }
                            });
                            this.setState({
                              billingRateTableOptions: {
                                ...this.state.billingRateTableOptions,
                                pageSize: pageSize
                              }
                            });
                            this.getItems(
                              'billing-rates',
                              'billingRate',
                              'billingRatePaginationOptions'
                            );
                          }}
                        />
                      </TableRow>
                    ),
                    Header: () => (
                      <TableHead>
                        <TableRow style={{ backgroundColor: '#525252' }}>
                          {['Billing Rate Group', 'Actions'].map(
                            (item, idx) => (
                              <TableCell
                                style={{
                                  color: '#fff',
                                  cursor:
                                    item !== 'Actions' ? 'pointer' : 'default'
                                }}
                                onClick={() => this.sortData(item, idx)}
                                align='left'
                                key={idx}
                              >
                                <Box style={{ display: 'flex' }}>
                                  {item}
                                  {this.state.sortList[item] === 0 && (
                                    <Box ml={1}>
                                      <FontAwesomeIcon
                                        icon={faArrowsAltV}
                                      ></FontAwesomeIcon>
                                    </Box>
                                  )}
                                  {this.state.sortList[item] !== 0 && (
                                    <Box ml={1}>
                                      {item !== 'Actions' && (
                                        <FontAwesomeIcon
                                          rotation={
                                            this.state.sortList[item] === 1
                                              ? 0
                                              : 180
                                          }
                                          icon={faArrowUp}
                                        />
                                      )}
                                    </Box>
                                  )}
                                </Box>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                    )
                  }}
                  editable={{
                    onRowAdd: newData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          if (newData.billingRate) {
                            if (newData.billingRate.trim() !== '') {
                              this.postItem(
                                'billingRate',
                                'billing-rates',
                                newData
                              );
                            }
                          }
                        }, 150);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          const data = [...this.state.billingRate];
                          data[data.indexOf(oldData)] = newData;
                          this.setState({ billingRate: data });
                          this.updateData(
                            'billingRate',
                            'billing-rates',
                            newData
                          );
                        }, 150);
                      }),
                    onRowDelete: oldData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          const data = [...this.state.billingRate];
                          data.splice(data.indexOf(oldData), 1);
                          this.setState({ billingRate: data });
                          this.deleteItem(
                            'billingRate',
                            'billing-rates',
                            oldData
                          );
                        }, 150);
                      })
                  }}
                />
              </Box>
              <Box mt={2}>
                <MaterialTable
                  isLoading={isequipmentClassLoading}
                  columns={equipmetClassColumns}
                  data={equipmentClass}
                  title='Equipment Classes'
                  onRowClick={(evt, selectedRow_2) =>
                    this.setState({ selectedRow_2 })
                  }
                  options={equipmentClassTableOptions}
                  components={{
                    Pagination: () => (
                      <TableRow
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <TablePagination
                          rowsPerPageOptions={[2, 4, 6, 8]}
                          style={{ width: '100%' }}
                          count={
                            this.state.equipmentClassPaginationOptions.total
                          }
                          rowsPerPage={
                            this.state.equipmentClassPaginationOptions.limit
                          }
                          page={this.state.equipmentClassPaginationOptions.page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' }
                          }}
                          onChangePage={async (event, page) => {
                            await this.setState({
                              equipmentClassPaginationOptions: {
                                ...this.state.equipmentClassPaginationOptions,
                                page: page
                              }
                            });
                            this.getItems(
                              'equipment-classes',
                              'equipmentClass',
                              'equipmentClassPaginationOptions'
                            );
                          }}
                          onChangeRowsPerPage={async event => {
                            const pageSize = parseInt(event.target.value);
                            await this.setState({
                              equipmentClassPaginationOptions: {
                                ...this.state.equipmentClassPaginationOptions,
                                limit: pageSize,
                                page: 0
                              }
                            });
                            this.setState({
                              equipmentClassTableOptions: {
                                ...this.state.equipmentClassTableOptions,
                                pageSize: pageSize
                              }
                            });
                            this.getItems(
                              'equipment-classes',
                              'equipmentClass',
                              'equipmentClassPaginationOptions'
                            );
                          }}
                        />
                      </TableRow>
                    ),
                    Header: () => (
                      <TableHead>
                        <TableRow style={{ backgroundColor: '#525252' }}>
                          {['Equipment Class', 'Actions'].map((item, idx) => (
                            <TableCell
                              style={{
                                color: '#fff',
                                cursor:
                                  item !== 'Actions' ? 'pointer' : 'default'
                              }}
                              onClick={() => this.sortData(item, idx)}
                              align='left'
                              key={idx}
                            >
                              <Box style={{ display: 'flex' }}>
                                {item}
                                {this.state.sortList[item] === 0 && (
                                  <Box ml={1}>
                                    <FontAwesomeIcon
                                      icon={faArrowsAltV}
                                    ></FontAwesomeIcon>
                                  </Box>
                                )}
                                {this.state.sortList[item] !== 0 && (
                                  <Box ml={1}>
                                    {item !== 'Actions' && (
                                      <FontAwesomeIcon
                                        rotation={
                                          this.state.sortList[item] === 1
                                            ? 0
                                            : 180
                                        }
                                        icon={faArrowUp}
                                      />
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                    )
                  }}
                  editable={{
                    onRowAdd: newData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          if (newData.equipmentClass) {
                            if (newData.equipmentClass.trim() !== '') {
                              this.postItem(
                                'equipmentClass',
                                'equipment-classes',
                                newData
                              );
                            }
                          }
                        }, 150);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          const data = [...this.state.equipmentClass];
                          data[data.indexOf(oldData)] = newData;
                          this.setState({ equipmentClass: data });
                          this.updateData(
                            'equipmentClass',
                            'equipment-classes',
                            newData
                          );
                        }, 150);
                      }),
                    onRowDelete: oldData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          const data = [...this.state.equipmentClass];
                          data.splice(data.indexOf(oldData), 1);
                          this.setState({ equipmentClass: data });
                          this.deleteItem(
                            'equipmentClass',
                            'equipment-classes',
                            oldData
                          );
                        }, 150);
                      })
                  }}
                />
              </Box>
              <Box mt={2} mb={3}>
                <MaterialTable
                  isLoading={iscustomTagLoading}
                  columns={customTagsColumns}
                  data={customTag}
                  title='Custom Tags'
                  onRowClick={(evt, selectedRow_3) =>
                    this.setState({ selectedRow_3 })
                  }
                  options={customTagsTableOptions}
                  components={{
                    Pagination: () => (
                      <TableRow
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <TablePagination
                          rowsPerPageOptions={[2, 4, 6, 8]}
                          style={{ width: '100%' }}
                          count={this.state.customTagsPaginationOptions.total}
                          rowsPerPage={
                            this.state.customTagsPaginationOptions.limit
                          }
                          page={this.state.customTagsPaginationOptions.page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' }
                          }}
                          onChangePage={async (event, page) => {
                            await this.setState({
                              customTagsPaginationOptions: {
                                ...this.state.customTagsPaginationOptions,
                                page: page
                              }
                            });

                            this.getItems(
                              'custom-tags',
                              'customTag',
                              'customTagsPaginationOptions'
                            );
                          }}
                          onChangeRowsPerPage={async event => {
                            const pageSize = parseInt(event.target.value);
                            await this.setState({
                              customTagsPaginationOptions: {
                                ...this.state.customTagsPaginationOptions,
                                limit: pageSize,
                                page: 0
                              }
                            });
                            this.setState({
                              customTagsTableOptions: {
                                ...this.state.customTagsTableOptions,
                                pageSize: pageSize
                              }
                            });
                            this.getItems(
                              'custom-tags',
                              'customTag',
                              'customTagsPaginationOptions'
                            );
                          }}
                        />
                      </TableRow>
                    ),
                    Header: () => (
                      <TableHead>
                        <TableRow style={{ backgroundColor: '#525252' }}>
                          {['Custom Tags', 'Actions'].map((item, idx) => (
                            <TableCell
                              style={{
                                color: '#fff',
                                cursor:
                                  item !== 'Actions' ? 'pointer' : 'default'
                              }}
                              onClick={() => this.sortData(item, idx)}
                              align='left'
                              key={idx}
                            >
                              <Box style={{ display: 'flex' }}>
                                {item}
                                {this.state.sortList[item] === 0 && (
                                  <Box ml={1}>
                                    <FontAwesomeIcon
                                      icon={faArrowsAltV}
                                    ></FontAwesomeIcon>
                                  </Box>
                                )}
                                {this.state.sortList[item] !== 0 && (
                                  <Box ml={1}>
                                    {item !== 'Actions' && (
                                      <FontAwesomeIcon
                                        rotation={
                                          this.state.sortList[item] === 1
                                            ? 0
                                            : 180
                                        }
                                        icon={faArrowUp}
                                      />
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                    )
                  }}
                  editable={{
                    onRowAdd: newData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          // console.log('newData', newData);
                          if (newData.customTag) {
                            if (newData.customTag.trim() !== '') {
                              this.postItem(
                                'customTag',
                                'custom-tags',
                                newData
                              );
                            }
                          }
                        }, 150);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          this.updateData('customTag', 'custom-tags', newData);
                        }, 150);
                      }),
                    onRowDelete: oldData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          const data = [...this.state.customTag];
                          data.splice(data.indexOf(oldData), 1);
                          this.setState({ customTag: data });
                          this.deleteItem('customTag', 'custom-tags', oldData);
                        }, 150);
                      })
                  }}
                />
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(ReportingTags));

import React from 'react';
import { withStyles, withWidth, Box } from '@material-ui/core/';
import { Route } from 'react-router-dom';
import EquipmentStatus from './EquipmentStatus';
import EquipmentAudit from './EquipmentAudit';
import EquipmentSite from './EquipmentSite';
const styles = theme => ({
  root: {
    display: 'flex'
  }
});

class Reports extends React.Component {
  state = { isDrawerOpen: false };

  render() {
    const { changeHeader, snackbar, isCsvLoading } = this.props;
    return (
      <Box>
        <Route
          exact
          path={`/admin-portal/reports/`}
          render={props => (
            <EquipmentStatus changeHeader={changeHeader}></EquipmentStatus>
          )}
        />
        <Route
          path={`/admin-portal/reports/equipment-status`}
          render={props => (
            <EquipmentStatus
              snackbar={snackbar}
              changeHeader={changeHeader}
              isCsvLoading={isCsvLoading}
            ></EquipmentStatus>
          )}
        />
        <Route
          path={`/admin-portal/reports/equipment-audit`}
          render={props => (
            <EquipmentAudit 
              snackbar={snackbar}
              changeHeader={changeHeader}
              isCsvLoading={isCsvLoading}
            />
          )}
        />
        <Route
          path={`/admin-portal/reports/equipment-site`}
          render={props => (
            <EquipmentSite changeHeader={changeHeader}></EquipmentSite>
          )}
        />
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(Reports));

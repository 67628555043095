import React from 'react';
import clsx from 'clsx';
import {
  Typography,
  Box,
  TextField,
  MenuItem,
  Chip,
  IconButton,
  Icon
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import BarChart from './BarChart';
import DataCard from './DataCard';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // Make sure to import the default stylesheet
import { withStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import app from '../../../appConfig';
import _ from 'lodash';
import moment from 'moment';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: '0px 1px 16px -2px rgba(0,0,0,0.2)',
    display: 'flex',
    overflow: 'auto'
  },
  textField: {
    width: '200px'
  },
  dashboardItem: { height: '100%' },

  chip: {
    margin: theme.spacing(0.5)
  }
});

class Dashboard extends React.Component {
  state = {
    billingRate: '',
    site: '',
    upEquipment: 0,
    downEquipment: 0,
    chartData: [],
    billingRateSelector: [],
    sitesSelector: [],
    dates: { start: Date(), end: Date() },
    tableLoading: true,
    selectedBillingRates: [],
    selectedSites: [],
    isAddBillingRateOpen: false,
    isAddSiteOpen: false,
  };

  componentDidMount = () => {
    this.props.changeHeader('Dashboard');

    const today = new Date();
    this.setState({
      dates: {
        start: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 6
        ),
        end: today
      }
    });
    const billingRatesService = app.service('billing-rates');
    const sitesService = app.service('sites');

    billingRatesService
      .find({
        query: {
          $limit: 100
        }
      })
      .then(response => {
        // handle success
        //
        const resData = response.data;
        this.setState(
          {
            billingRateSelector: resData,
            billingRate: resData[0]._id
          },
          this.getData
        );
      })
      .catch(error => {
        // handle error
      });

    sitesService
      .find()
      .then(response => {
        // handle success
        //
        const resData = response.data;
        this.setState(
          {
            sitesSelector: resData,
            site: resData[0]._id
          },
          this.getData
        );
      })
      .catch(error => {
        // handle error
      });
  };

  getData = () => {
    this.setState({ tableLoading: true, fourYearsError: false });
    const { selectedBillingRates, selectedSites } = this.state;
    let { dates } = this.state;
    if (moment(dates.end).diff(moment(dates.start), 'years') >= 4) {
      this.setState({ fourYearsError: true });
    } else {
      dates.end.setHours(23);
      dates.end.setMinutes(59);
      dates.end.setSeconds(59);
      const dashboardService = app.service('dashboard');
      dashboardService
        .find({
          query: {
            billingRate: { $in: selectedBillingRates },
            site: { $in: selectedSites },
            startDate: dates.start,
            endDate: dates.end
          }
        })

        .then(response => {
          const { rangeData } = response;
          let chartData = {};
          const a = Object.keys(rangeData.data).sort((a, b) =>
            new Date(a).getTime() < new Date(b).getTime() ? -1 : 1
          );
          a.forEach(item => {
            let key;
            if (moment(item) < dates.start) {
              key = (() => {
                let temp = moment(dates.start).toString();
                return temp.substr(0, temp.indexOf('GMT')) + 'GMT+0000';
              })();
            } else if (moment(item) > dates.end) {
              key = (() => {
                let temp = moment(dates.end).toString();
                return temp.substr(0, temp.indexOf('GMT')) + 'GMT+0000';
              })();
            } else {
              key = item;
            }

            chartData[key] = chartData[key]
              ? {
                  Up: rangeData.data[item].up + chartData[key].Up,
                  Down: rangeData.data[item].dn + chartData[key].Down
                }
              : { Up: rangeData.data[item].up, Down: rangeData.data[item].dn };
          });
          chartData = Object.keys(chartData).map(item => ({
            name: item,
            ...chartData[item]
          }));

          this.setState({
            upEquipment: response.upEquipments,
            downEquipment: response.downEquipments,
            chartData: chartData,
            tableLoading: false,
            periodType: rangeData.by
          });
        })
        .catch(error => {
          // console.log(error);
          // handle error
        });
    }
  };

  handleAddBillingRate = event => {
    const { selectedBillingRates } = this.state;
    selectedBillingRates.push(event.target.value);
    this.setState({ isAddBillingRateOpen: false }, () => {
      this.getData();
    });
  };

  handleAddSite = event => {
    const { selectedSites } = this.state;
    selectedSites.push(event.target.value);
    this.setState({ isAddSiteOpen: false }, () => {
      this.getData();
    });
  };

  handleDeleteBillingRate = idx => {
    let { selectedBillingRates } = this.state;

    if (selectedBillingRates !== null && selectedBillingRates.length === 1) {
      selectedBillingRates = [];
    } else {
      const filteredBillingRates = selectedBillingRates.filter(function(
        item,
        index
      ) {
        return index !== idx;
      });
      selectedBillingRates = filteredBillingRates;
    }
    this.setState({ selectedBillingRates }, () => {
      this.getData();
    });
  };

  handleDeleteSite = idx => {
    let { selectedSites } = this.state;

    if (selectedSites !== null && selectedSites.length === 1) {
      selectedSites = [];
    } else {
      const filteredSites = selectedSites.filter(function(
        item,
        index
      ) {
        return index !== idx;
      });
      selectedSites = filteredSites;
    }
    this.setState({ selectedSites }, () => {
      this.getData();
    });
  };

  toggleAddBillingRate = () => {
    this.setState({ isAddBillingRateOpen: !this.state.isAddBillingRateOpen });
  };

  toggleAddSite = () => {
    this.setState({ isAddSiteOpen: !this.state.isAddSiteOpen });
  };

  render() {
    const { classes } = this.props;
    const {
      upEquipment,
      downEquipment,
      dates,
      chartData,
      billingRateSelector,
      sitesSelector,
      tableLoading,
      fourYearsError,
      selectedBillingRates,
      selectedSites,
      isAddBillingRateOpen,
      isAddSiteOpen
    } = this.state;

    const billingRateOptions = billingRateSelector.filter(
      billingRate1 =>
        !selectedBillingRates.find(
          billingRate2 => billingRate2 === billingRate1._id
        )
    );

    const sitesOptions = sitesSelector.filter(
      site =>
        !selectedSites.find(
          $site => $site === site._id
        )
    )

    return (
      <Box style={{ padding: 20 }}>
        <Grid container spacing={2} style={{ padding: '10px 20px' }}>
          <Grid item xs={6}>
            <Paper
              className={clsx(classes.dashboardItem, classes.paper)}
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start'
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ marginRight: 8 }}>
                  Billing Rate Group
                </Typography>
                <TextField
                  select
                  value={this.state.customTag}
                  onChange={this.handleAddBillingRate}
                  margin='dense'
                  variant='outlined'
                  label={isAddBillingRateOpen && 'Billing Rates'}
                  style={{
                    display: isAddBillingRateOpen ? 'flex' : 'none'
                  }}
                  SelectProps={{
                    style: {
                      width: 130
                    }
                  }}
                >
                  {billingRateOptions.map(option => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                {!isAddBillingRateOpen && billingRateOptions.length > 0 && (
                  <IconButton onClick={this.toggleAddBillingRate}>
                    <Icon>add_circle</Icon>
                  </IconButton>
                )}
              </Box>

              <Box>
                {selectedBillingRates.map((billingRateValue, idx) => {
                  let billingRateLabel = billingRateSelector.find(
                    billingRate => billingRate._id === billingRateValue
                  ).name;
                  return (
                    <Chip
                      key={idx}
                      variant='outlined'
                      color='primary'
                      className={classes.chip}
                      label={billingRateLabel}
                      onDelete={() => this.handleDeleteBillingRate(idx)}
                    />
                  );
                })}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              className={clsx(classes.dashboardItem, classes.paper)}
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start'
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ marginRight: 8 }}>
                  Site
                </Typography>
                <TextField
                  select
                  onChange={this.handleAddSite}
                  margin='dense'
                  variant='outlined'
                  label={isAddSiteOpen && 'Sites'}
                  style={{
                    display: isAddSiteOpen ? 'flex' : 'none'
                  }}
                  SelectProps={{
                    style: {
                      width: 130
                    }
                  }}
                >
                  {sitesOptions.map(option => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                {!isAddSiteOpen && sitesOptions.length > 0 && (
                  <IconButton onClick={this.toggleAddSite}>
                    <Icon>add_circle</Icon>
                  </IconButton>
                )}
              </Box>

              <Box>
                {selectedSites.map((siteValue, idx) => {
                  let siteLabel = sitesSelector.find(
                    site => site._id === siteValue
                  ).name;
                  return (
                    <Chip
                      key={idx}
                      variant='outlined'
                      color='primary'
                      className={classes.chip}
                      label={siteLabel}
                      onDelete={() => this.handleDeleteSite(idx)}
                    />
                  );
                })}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              className={clsx(classes.dashboardItem, classes.paper)}
              style={{ backgroundColor: '#2e86ab' }}
            >
              <DataCard
                text='Up Equipment'
                value={upEquipment}
                loading={tableLoading}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              className={clsx(classes.dashboardItem, classes.paper)}
              style={{ backgroundColor: '#f18f01' }}
            >
              <DataCard
                text='Down Equipment'
                value={downEquipment}
                loading={tableLoading}
              />
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Paper
              className={classes.paper}
              style={{ height: '100%', marginRight: '16px' }}
            >
              <InfiniteCalendar
                Component={withRange(Calendar)}
                width={390}
                height={300}
                locale={{
                  headerFormat: 'MMM Do'
                }}
                selected={dates}
                onSelect={event => {
                  //
                  if (event.eventType === 3) {
                    this.setState(
                      {
                        dates: { start: event.start, end: event.end }
                      },
                      this.getData
                    );
                  }
                }}
                theme={{
                  selectionColor: '#b7303b',
                  weekdayColor: '#b7303b',
                  headerColor: '#a32630',
                  floatingNav: {
                    background: '#bf1d2a',
                    chevron: '#bf1d2a00'
                  },
                  accentColor: '#a32630'
                }}
              />
            </Paper>

            <Paper className={classes.paper} style={{ flexGrow: 100 }}>
              {fourYearsError ? (
                <Box
                  style={{
                    display: 'flex',
                    placeItems: 'center',
                    placeContent: 'center',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <Typography style={{ color: 'red' }}>
                    The range must be less than 4 years
                  </Typography>
                </Box>
              ) : tableLoading ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Skeleton variant='rect' width={5} height='100%'></Skeleton>
                    {_.range(6).map(item => (
                      <div
                        key={item}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          margin: '0 2px'
                        }}
                      >
                        <Skeleton
                          variant='rect'
                          width={25}
                          height={`${((item + 1) * 77) % 100}%`}
                          // style={{ margin: "0 2px" }}
                        ></Skeleton>
                        <Skeleton
                          variant='rect'
                          width={25}
                          height={`${((item + 1) * 55) % 100}%`}
                          // style={{ margin: "0 2px" }}
                        ></Skeleton>
                      </div>
                    ))}
                    <div style={{ width: 20 }}></div>
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <Skeleton width='100%' variant='rect' height={5}></Skeleton>
                  </Box>
                </div>
              ) : chartData.length > 0 ? (
                <BarChart
                  periodType={this.state.periodType}
                  data={chartData}
                  className={classes.dashboardItem}
                />
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    placeItems: 'center',
                    placeContent: 'center',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <Typography>No records to display</Typography>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(Dashboard);

import React from "react";
import {
  withStyles,
  Grid,
  Box,
  IconButton,
  Tooltip,
  TablePagination,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Chip,
} from "@material-ui/core/";
import MaterialTable from "material-table";
import SideFilter from "../SideFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faArrowUp,
  faArrowsAltV,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import app from "../../../appConfig";

const queryNameMapper = {
  "Site Name": "name",
};
const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    borderRadius: 5,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: theme.spacing(0.7),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

class SitesChat extends React.Component {
  state = {
    isDialogOpen: false,
    columns: [{ title: "Site Name", field: "siteName", filtering: false }],
    tableData: [],
    tableOptions: {
      search: false,
      filtering: false,
      sorting: false,
      actionsColumnIndex: -1,
      pageSize: 10,
      pageSizeOptions: [5, 10, 15, 20],
      paginationType: "stepped",
      headerStyle: {
        whiteSpace: "noWrap",
        backgroundColor: "#525252",
        color: "#FFF",
        padding: 10,
      },
      rowStyle: (rowData) => ({
        transition: "all ease-in .2S",
        whiteSpace: "noWrap",
        padding: 0,
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? "#EEE"
            : "#FFF",
      }),
    },
    paginationOptions: { page: 0, limit: 10, total: 0, skip: 0 },
    sortList: {
      "Site Name": 0,
    },
    labels: ["Site Name"],
    isDataFiltered: false,
    filterQuery: {},
    sortQuery: {},
    filterDetails: {},
    isCsvLoading: false,
  };

  resetPages = () => {
    const paginationOptions = { ...this.state.paginationOptions };
    paginationOptions.page = 0;
    paginationOptions.skip = 0;
    this.setState({ paginationOptions });
  };

  getItems = () => {
    const { paginationOptions } = this.state;
    const service = app.service("sites");
    let query = {
      $skip: paginationOptions.limit * paginationOptions.page,
      $limit: paginationOptions.limit,
      ...this.state.sortQuery,
      ...this.state.filterQuery,
    };
    console.log(`query sites`, query);
    this.setState({ isTableLoading: true });

    service
      .find({ query })
      .then((response) => {
        // handle success
        let sitesData = [];
        const resData = response.data;

        const paginationOptions = {
          skip: response.skip,
          total: response.total,
          limit: response.limit,
        };
        this.setState({
          paginationOptions: {
            ...this.state.paginationOptions,
            ...paginationOptions,
          },
        });
        resData.forEach((site) => {
          sitesData.push({
            id: site._id,
            siteName: site.name,
            chatId: site.chat !== null ? site.chat._id : "",
          });
        });
        this.setState({ tableData: sitesData });
      })
      .catch((error) => {
        // handle error
        // console.log('error', error);
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };

  componentDidMount = () => {
    this.props.changeHeader("Chat");
    this.getItems();
  };

  setFilterStatus = (isFilterEnabled) => {
    this.setState({ isDataFiltered: isFilterEnabled });
  };

  sortData = (itemName, sortIdx) => {
    if (itemName === "Actions") {
      return;
    }
    let sortList = { ...this.state.sortList };
    const sortQuery = {};
    let currentState = sortList[itemName];
    sortList = {
      "Site Name": 0,
    };
    if (currentState === 1) {
      currentState = -1;
    } else if (currentState === -1) {
      currentState = 1;
    } else {
      currentState = 1;
    }
    sortList[itemName] = currentState;
    sortQuery["$sort"] = { [`${queryNameMapper[itemName]}`]: currentState };

    this.setState({ sortList, sortIdx, sortQuery }, () => {
      this.getItems();
    });
  };
  componentWillUnmount = () => {
    const labels = { ...this.state.labels };
    for (var i in labels) {
      localStorage.removeItem(`${labels[i]}SelectedItems`);
    }
  };
  setFilterQuery = (filterQuery, filterDetails) => {
    this.setState({ filterQuery, filterDetails }, () => {});
  };

  render() {
    const { classes } = this.props;
    const {
      columns,
      tableData,
      tableOptions,
      labels,
      isTableLoading,
      filterDetails,
    } = this.state;

    function TablePaginationActions(props) {
      const theme = useTheme();
      const { count, page, rowsPerPage, onChangePage } = props;

      const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
      };

      const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
      };

      const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
      };

      const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
        <div
          style={{
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
          }}
        >
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      );
    }

    return (
      <Box mt={2}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Box>
              {Object.keys(filterDetails).length > 0 && (
                <Box>
                  {Object.keys(filterDetails).map((key) => (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ marginRight: "5px" }}
                      >
                        {key}:
                      </Typography>
                      {key === "Date Range" && (
                        <Chip
                          color="primary"
                          variant="outlined"
                          label={`${filterDetails[key].start.format(
                            "D/M/Y"
                          )} - ${filterDetails[key].end.format("D/M/Y")}`}
                          size="small"
                        ></Chip>
                      )}
                      {key !== "Date Range" &&
                        filterDetails[key].map((itemName) => (
                          <Chip
                            color="primary"
                            variant="outlined"
                            label={itemName}
                            size="small"
                            style={{ marginRight: "3px" }}
                          />
                        ))}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Tooltip Open title="Open Filter">
                <IconButton
                  className={classes.button}
                  aria-label="Open Filter"
                  onClick={(event) => this.sideFilter.openDrawer()}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} style={{ maxWidth: "100%", height: "80%" }}>
              <MaterialTable
                isLoading={isTableLoading}
                columns={columns}
                data={tableData}
                title=""
                onRowClick={(evt, selectedRow) =>
                  this.setState({ selectedRow })
                }
                onColumnDragged={() => {}}
                options={tableOptions}
                components={{
                  Pagination: () => (
                    <TableRow
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <TablePagination
                        ActionsComponent={TablePaginationActions}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        style={{ width: "100%" }}
                        count={this.state.paginationOptions.total}
                        rowsPerPage={this.state.paginationOptions.limit}
                        page={this.state.paginationOptions.page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                        }}
                        onChangePage={async (event, page) => {
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              page: page,
                            },
                          });

                          this.getItems();
                        }}
                        onChangeRowsPerPage={async (event) => {
                          const pageSize = parseInt(event.target.value);
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              limit: pageSize,
                              page: 0,
                            },
                          });
                          this.setState({
                            tableOptions: {
                              ...this.state.tableOptions,
                              pageSize: pageSize,
                            },
                          });
                          this.getItems();
                        }}
                      />
                    </TableRow>
                  ),
                  Header: () => (
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "#525252",
                          whiteSpace: "nowrap",
                          transition: "all ease-in .2S",
                        }}
                      >
                        {["Site Name", "Actions"].map((item, idx) => (
                          <TableCell
                            style={{
                              color: "#fff",
                              cursor:
                                item !== "Actions" ? "pointer" : "default",
                            }}
                            onClick={() => this.sortData(item, idx)}
                            align="left"
                            key={idx}
                          >
                            <Box style={{ display: "flex" }}>
                              {item}
                              {this.state.sortList[item] === 0 && (
                                <Box ml={1}>
                                  <FontAwesomeIcon
                                    icon={faArrowsAltV}
                                  ></FontAwesomeIcon>
                                </Box>
                              )}
                              {this.state.sortList[item] !== 0 && (
                                <Box ml={1}>
                                  {item !== "Actions" && (
                                    <FontAwesomeIcon
                                      rotation={
                                        this.state.sortList[item] === 1
                                          ? 0
                                          : 180
                                      }
                                      // onClick={this.sortData}
                                      icon={faArrowUp}
                                    />
                                  )}
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  ),
                  Toolbar: () => <Box></Box>,
                }}
                actions={[
                  {
                    icon: "message",
                    tooltip: "Open Chat",
                    onClick: (event, rowData) => {
                      // Go to chat page
                      const { chatId } = rowData;
                      let chatData = {
                        siteName: rowData.siteName,
                      };
                      chatData = JSON.stringify(chatData);
                      this.props.history.push(
                        `/admin-portal/chat/${chatId}/${chatData}`
                      );
                    },
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        <SideFilter
          ref={(sideFilter) => {
            this.sideFilter = sideFilter;
          }}
          labels={labels}
          getItems={this.getItems}
          setFilterStatus={this.setFilterStatus}
          setFilterQuery={this.setFilterQuery}
          resetPages={this.resetPages}
        ></SideFilter>
      </Box>
    );
  }
}
export default withStyles(styles)(SitesChat);

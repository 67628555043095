import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  IconButton,
  Grid,
  Box,
  Typography,
  TextField,
  TableHead,
  TableCell,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';
import moment from 'moment';
import axios from 'axios';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const useStyles = makeStyles((theme) => ({
  dataField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
}));

function SubmitDialog({ handleSubmite }) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='contained' color='primary' va onClick={handleClickOpen}>
        Submit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Warning!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Please note that by submitting this request, you are confirming the
            history changes of this equipment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={isLoading}
            onClick={() => {
              handleSubmite();
              handleClose();
            }}
            color='primary'
          >
            {isLoading && <CircularProgress color='primary' size={32} />}
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function ChangeHistory({
  statusEntryId,
  open,
  getItems,
  handleClose,
}) {
  const classes = useStyles();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: 'Timestamp',
      field: 'timestamp',
      editable: false,
      render: (rowData) => {
        return (
          <Typography>
            {moment(rowData.timestamp).format('MM/DD/YYYY')}
          </Typography>
        );
      },
    },
    {
      title: 'User',
      field: 'user',
      editable: false,
    },
    {
      title: 'Status',
      field: 'equipmentStatus',
      editable: false,
      render: (rowData) => {
        return (
          <Typography>
            {rowData.equipmentStatus === 'DN' ? 'DM' : 'AV'}
          </Typography>
        );
      },
    },
    {
      title: 'SMU',
      field: 'currentSMU',
      type: 'numeric',
      align: 'left',
    },
  ]);
  const [statusEntryData, setStatusEntryData] = useState({
    statusEntryId: 0,
    timestamp: Date(),
    equipmentNumber: '',
    SMU: 0,
    status: '',
    user: '',
  });
  const [data, setData] = useState([
    // { timestamp: Date(), user: 'Baran', status: 'AV', SMU: 1987 },
    // { timestamp: Date(), user: 'Baran', status: 'DM', SMU: 2017 },
  ]);

  useEffect(() => {
    getStatusEntry(statusEntryId);
    // console.log('statusEntryId', statusEntryId);
  }, [statusEntryId]);

  const getStatusEntry = async (statusEntryId) => {
    if (statusEntryId)
      try {
        setIsTableLoading(true);
        const response = await axios.get(
          apiEndpoint + 'status-entries/' + statusEntryId,
          {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
            cancelToken: source.token,
          }
        );
        if (response) {
          const resData = response.data;
          const newStatusEntryData = {
            statusEntryId: resData._id,
            timestamp: resData.timestamp,
            equipmentNumber: resData.equipment.number,
            equipmentStatus: resData.equipmentStatus,
            currentSMU: resData.currentSMU,
            user: resData.user.firstName + ' ' + resData.user.lastName,
            isSystemEntry: resData.endOfShift,
          };

          setStatusEntryData(newStatusEntryData);
          getNextStatusEntries(statusEntryId);
        }
      } catch (error) {
        // console.log('error', error);
      }
  };
  const getNextStatusEntries = async (statusEntryId) => {
    // source.cancel('Operation canceled by the user.');
    // source = CancelToken.source();
    try {
      setIsTableLoading(true);
      const response = await axios.get(
        apiEndpoint + 'history/latest-entries?statusEntryId=' + statusEntryId,
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          cancelToken: source.token,
        }
      );
      if (response) {
        // console.log('response', response);
        setData(response.data);
        setIsTableLoading(false);
      }
    } catch (error) {
      // console.log('error', error);
    }
  };

  const handleSubmite = () => {
    smuBulkUpdate();
    handleClose();
    getItems();
  };

  const handelChangeSMU = (event) => {
    const tempStatusEntry = { ...statusEntryData };
    const newSMU = event.target.value;
    tempStatusEntry['currentSMU'] = Number(newSMU);
    setStatusEntryData(tempStatusEntry);
  };

  const recalculateSMU = (startIdx, newSMU) => {
    let tempData = [statusEntryData, ...data];
    // console.log('newSMU', newSMU);
    tempData[startIdx].currentSMU = newSMU;
    // console.log('tempData BEFORE', tempData);
    setIsTableLoading(true);

    tempData.forEach((statusEntry, idx) => {
      // console.log(`${idx} > ${startIdx}`, idx > startIdx);
      if (idx > startIdx) {
        // console.log('statusEntry', statusEntry);
        // console.log('idx', idx);
        // console.log('statusEntry[idx - 1]', tempData[idx - 1]);
        if (statusEntry.isSystemEntry) {
          if (tempData[idx - 1].equipmentStatus === 'AV') {
            statusEntry.currentSMU = tempData[idx - 1].currentSMU + 12;
          } else {
            statusEntry.currentSMU = tempData[idx - 1].currentSMU;
          }
        } else {
          statusEntry.currentSMU = tempData[idx - 1].currentSMU;
        }
      }
    });

    // console.log('tempData', tempData);
    tempData = tempData.slice(1);
    // console.log('tempData', tempData);
    setData(tempData);
    setIsTableLoading(false);
  };
  const smuBulkUpdate = async () => {
    let entries = [];
    entries.push({
      statusEntryId: statusEntryData.statusEntryId,
      currentSMU: statusEntryData.currentSMU,
    });
    data.forEach((statusEntry) => {
      entries.push({
        statusEntryId: statusEntry.statusEntryId,
        currentSMU: statusEntry.currentSMU,
      });
    });
    // console.log('entries', entries);
    try {
      const response = await axios.post(
        apiEndpoint + 'history/smu-bulk-update',
        {
          entries,
        },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          cancelToken: source.token,
        }
      );
      if (response) {
        toast.success('Status Entry History Updated Successfully.');
      }
    } catch (error) {
      // console.log('error', error);
    }
  };

  return (
    <Dialog maxWidth='md' open={open} onClose={handleClose}>
      <DialogTitle id='change-history-dialog-title'>
        {'Change History'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Box className={classes.dataField}>
              <Typography> Equipment Number</Typography>
              <TextField
                disabled
                variant='outlined'
                margin='dense'
                variant='outlined'
                value={statusEntryData.equipmentNumber}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.dataField}>
              <Typography> Current SMU</Typography>
              <TextField
                autoFocus
                variant='outlined'
                type='number'
                margin='dense'
                value={statusEntryData.currentSMU}
                onChange={handelChangeSMU}
                onKeyDown={(event) => {
                  if (event.key === 'Enter')
                    recalculateSMU(0, statusEntryData.currentSMU);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.dataField}>
              <Typography> Timestamp</Typography>
              <TextField
                disabled
                variant='outlined'
                margin='dense'
                value={moment(statusEntryData.timestamp).format('MM/DD/YYYY')}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.dataField}>
              <Typography> User</Typography>
              <TextField
                disabled
                variant='outlined'
                margin='dense'
                value={statusEntryData.user}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.dataField}>
              <Typography> Status</Typography>
              <TextField
                disabled
                variant='outlined'
                margin='dense'
                name='AAA'
                value={statusEntryData.equipmentStatus}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={3} />
            <MaterialTable
              isLoading={isTableLoading}
              title='Editable Preview'
              columns={columns}
              data={data}
              options={{
                actionsColumnIndex: -1,
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(async () => {
                      const index = oldData.tableData.id;
                      const tempData = [...data];
                      tempData[index] = newData;
                      // console.log('newData', newData);

                      recalculateSMU(
                        Number(index + 1),
                        Number(newData.currentSMU)
                      );
                      resolve();
                    }, 250);
                  }),
              }}
              components={{
                Header: () => (
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: '#525252',
                        whiteSpace: 'nowrap',
                        transition: 'all ease-in .2S',
                      }}
                    >
                      {['Timestamp', 'User', 'Status', 'SMU', 'Actions'].map(
                        (item, idx) => (
                          <TableCell
                            style={{
                              color: '#fff',
                            }}
                            align='left'
                            key={idx}
                          >
                            <Box style={{ display: 'flex' }}>{item}</Box>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                ),
                Toolbar: () => <Box></Box>,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <SubmitDialog handleSubmite={handleSubmite} />
      </DialogActions>
    </Dialog>
  );
}

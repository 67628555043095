import React from 'react';
import {
  withStyles,
  withWidth,
  Grid,
  Typography,
  Box,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  Icon,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import app from '../../../../appConfig';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 5,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    width: theme.spacing(12),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  chipContainer: {
    margin: theme.spacing(1.2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    placeItems: 'center',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tagTextField: { marginLeft: theme.spacing(1) },
  listText: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: 'none',
  },
  errorStyle: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#f00',
  },
  endAdormentStyle: { top: 'unset' },
});

class AddEditEquipment extends React.Component {
  state = {
    text: '',
    open: false,
    isLoading: false,
    dataLoaded: 0,
    isAddTagOpen: false,
    isDataInValid: {
      number: false,
      billingRate: false,
      equipmentClass: false,
      site: false,
      ownOrRent: false,
    },
    errMessages: {
      number: 'Name should be between 3 and 50 characters.',
      billingRate: 'Billing rate is required.',
      equipmentClass: 'Equipment class is required',
      site: 'Site is required.',
      ownOrRent: 'Equipment ownership is required.',
    },
    equipmentData: {
      number: '',
      billingRate: '',
      equipmentClass: '',
      site: '',
      ownOrRent: '',
      description: '',
      mechanicalStatus: '',
      tags: [],
    },
    equipmentClass: [],
    billingRate: [],
    sites: [],
    tags: [],
    tagsId: {},
    columns: [],
    data: [],
    tableOptions: {
      search: false,
      filtering: false,
      pageSize: 2,
      pageSizeOptions: [2],
      showTitle: false,
      headerStyle: {
        whiteSpace: 'noWrap',
        backgroundColor: '#525252',
        color: '#FFF',
        padding: 10,
        fontSize: 14,
      },
      rowStyle: (rowData) => ({
        whiteSpace: 'noWrap',
        padding: 0,
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? '#EEE'
            : '#FFF',
      }),
    },
  };

  handleClose = () => {
    this.props.handleClose();
  };
  handleSelectionChange = (event, newValue, name) => {
    const { equipmentData } = this.state;
    let isDataInValid = { ...this.state.isDataInValid };
    isDataInValid[name] = false;

    equipmentData[name] = newValue;
    this.setState({ equipmentData, isDataInValid });
  };

  handleChange = (name) => (event) => {
    const { equipmentData } = this.state;
    let isDataInValid = { ...this.state.isDataInValid };
    isDataInValid[name] = false;

    equipmentData[name] = event.target.value;
    this.setState({ equipmentData, isDataInValid });
  };

  handleAddTagChange = (event, newValue) => {
    const equipmentData = { ...this.state.equipmentData };
    equipmentData.tags.push(newValue);

    this.setState({ equipmentData, isAddTagOpen: false });
  };
  handleDelete = (idx) => {
    const { equipmentData } = this.state;

    if (equipmentData.tags !== null && equipmentData.tags.length === 1) {
      equipmentData.tags = [];
    } else {
      const filteredTags = equipmentData.tags.filter(function (
        equipment,
        index
      ) {
        return index !== idx;
      });
      equipmentData.tags = filteredTags;
    }
    this.setState({ equipmentData });
  };
  toggleAddTag = () => {
    this.setState({ isAddTagOpen: !this.state.isAddTagOpen });
  };

  evalData = (dataObject) => {
    let isDataInValid = { ...this.state.isDataInValid };
    let errMessages = { ...this.state.errMessages };
    let error = false;
    for (var item in dataObject) {
      if (item === 'number') {
        if (
          dataObject['number'].length < 2 ||
          dataObject['number'].length > 50
        ) {
          isDataInValid['number'] = true;
          toast.error(errMessages['number']);

          error = true;
        }
      }
      if (
        dataObject[item] === '' &&
        item !== 'description' &&
        item !== 'mechanicalStatus'
      ) {
        isDataInValid[item] = true;
        toast.error(errMessages[item]);
        error = true;
      }
    }
    if (error) {
      this.setState({ isLoading: false, isDataInValid });
      return false;
    }

    return true;
  };
  postItem = (itemUrl) => {
    const bodyData = { ...this.state.equipmentData };
    let isDataInValid = { ...this.state.isDataInValid };
    let errMessages = { ...this.state.errMessages };

    if (!this.evalData(bodyData)) {
      return;
    }
    this.setState({ isLoading: true });

    axios
      .post(apiEndpoint + itemUrl, bodyData, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then((res) => {
        toast.success(`Equipment created successfully`);
        const equipmentData = {
          number: '',
          billingRate: '',
          equipmentClass: '',
          site: '',
          ownOrRent: '',
          description: '',
          mechanicalStatus: '',
          tags: [],
        };
        this.setState({ equipmentData });

        this.props.history.push(`/admin-portal/setup/equipment`);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          errMessages['number'] =
            'An equipment with the same number already exists!';
          isDataInValid['number'] = true;
          toast.error(errMessages['number']);
        } else {
          errMessages['number'] = 'Name should be between 3 and 50 characters.';
        }
        this.setState({ errMessages });

        if (err.response) {
          const errorObject = err.response.data.errors;

          if (Object.keys(errorObject).length > 0) {
            for (var key in errorObject) {
              isDataInValid[key] = true;
              if (key === 'billingRate') {
                toast.error('Billing rate is required.');
              } else if (key === 'equipmentClass') {
                toast.error('Equipment class is required.');
              } else if (key === 'site') {
                toast.error('Site is required.');
              } else {
                toast.error(errorObject[key].message);
              }
            }
          }
        }
      })
      .finally(() => {
        this.setState({ isLoading: false, isDataInValid });
      });
  };

  updateData = (itemUrl) => {
    itemUrl += '/' + this.props.match.params.id;

    const newItemData = this.state.equipmentData;
    axios
      .patch(apiEndpoint + itemUrl, newItemData, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then((res) => {
        toast.success(`Equipment updated successfully`);

        this.props.history.push(`/admin-portal/setup/equipment`);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      })
      .finally(() => {});
  };
  componentDidMount = () => {
    [
      { url: 'billing-rates', data: 'billingRate' },
      { url: 'equipment-classes', data: 'equipmentClass' },
      { url: 'sites', data: 'sites' },
      { url: 'custom-tags', data: 'tags' },
    ].map((item) => {
      const service = app.service(item.url);
      service
        .find({
          query: {
            $limit: null,
          },
        })
        .then((response) => {
          // handle success
          let resData = response.data;

          resData = resData.sort((a, b) => (a.name > b.name ? 1 : -1));

          let newData = [];
          resData.forEach((resItem) => {
            newData.push(String(resItem._id));
          });
          let newDataMapper = {};
          resData.forEach((resItem) => {
            newDataMapper[`${resItem._id}`] = resItem.name;
          });
          //
          this.setState({
            [`${item.data}`]: newData,
            [`${item.data}Mapper`]: newDataMapper,
            dataLoaded: this.state.dataLoaded + 1,
          });
        })
        .catch((error) => {
          // handle error
          // toast.error(error.response.data.message);
        })
        .finally(() => {
          // always executed
        });
      return true;
    });

    if (localStorage.getItem('text') === 'Edit') {
      const equipmentId = this.props.match.params.id;
      axios
        .get(apiEndpoint + 'equipments/' + equipmentId, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          // handle success
          const resData = response.data;
          const equipmentData = {
            id: resData._id,
            number: resData.number,
            billingRate: resData.billingRate._id,
            equipmentClass: resData.equipmentClass._id,
            site: resData.site._id,
            description: resData.description,
            mechanicalStatus: resData.mechanicalStatus,
            ownOrRent: resData.ownOrRent,
          };
          let tempTags = resData.tags;
          const tags = [];
          if (tempTags) {
            if (!Array.isArray(tempTags)) {
              tempTags = [resData.tags];
            }
            tempTags.map((tag) => {
              tags.push(tag._id);
              return true;
            });
          }
          equipmentData['tags'] = tags;
          this.setState({
            equipmentData,
            dataLoaded: this.state.dataLoaded + 1,
          });
        })
        .catch((error) => {
          // handle error
        })
        .finally(() => {
          // always executed
        });
    } else {
      this.setState({ dataLoaded: this.state.dataLoaded + 5 });
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    const submitType = localStorage.getItem('text');
    if (submitType === 'Add') {
      this.postItem('equipments');
    } else {
      this.updateData('equipments');
    }
  };
  render() {
    const { classes } = this.props;
    const {
      sites,
      equipmentClass,
      billingRate,
      sitesMapper,
      equipmentClassMapper,
      billingRateMapper,
      equipmentData,
      isAddTagOpen,
      tags,
      tagsMapper,
      dataLoaded,
      isDataInValid,
      errMessages,
    } = this.state;
    console.log('equipmentData', equipmentData);
    const Filteredtags = tags.filter(
      (tag1) => !equipmentData.tags.find((tag2) => tag2 === tag1)
    );

    return (
      <Box>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Typography variant='h4' gutterBottom>
              {localStorage.getItem('text')} Equipment
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant='h6' gutterBottom>
                Equipment Information
              </Typography>
              {dataLoaded >= 5 && (
                <form
                  className={classes.container}
                  autoComplete='off'
                  onSubmit={this.submitHandler}
                >
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={3}>
                      <TextField
                        label='Equipment Number'
                        error={isDataInValid['number']}
                        required
                        className={classes.textField}
                        onChange={this.handleChange('number')}
                        value={equipmentData.number}
                        margin='dense'
                        variant='outlined'
                      />
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid['number']}
                      >
                        {errMessages['number']}
                      </FormHelperText>
                    </Grid>

                    {[
                      {
                        name: 'Billing Rate Group',
                        value: 'billingRate',
                        selection: billingRate,
                        mapper: billingRateMapper,
                      },
                      {
                        name: 'Equipment Class',
                        value: 'equipmentClass',
                        selection: equipmentClass,
                        mapper: equipmentClassMapper,
                      },
                      {
                        name: 'Sites',
                        value: 'site',
                        selection: sites,
                        mapper: sitesMapper,
                      },
                    ].map((item) => (
                      <Grid item xs={3} key={item.name}>
                        <Autocomplete
                          classes={{ endAdornment: classes.endAdormentStyle }}
                          id={`${item.name}-selection`}
                          options={item.selection}
                          value={equipmentData[item.value]}
                          getOptionLabel={(option) =>
                            item.mapper ? item.mapper[option] : ''
                          }
                          onChange={(event, newValue, name) =>
                            this.handleSelectionChange(
                              event,
                              newValue,
                              item.value
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              margin='dense'
                              {...params}
                              label={item.name}
                              variant='outlined'
                            />
                          )}
                        />
                        {/* <TextField
                          select
                          required
                          label={item.name}
                          error={isDataInValid[item.value]}
                          className={classes.textField}
                          value={equipmentData[item.value]}
                          // defaultValue={equipmentData[item.value]}
                          onChange={this.handleChange(item.value)}
                          margin='dense'
                          variant='outlined'
                          SelectProps={{ required: true }}
                        >
                          {item.selection.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField> */}
                        <FormHelperText
                          className={classes.errorStyle}
                          hidden={!isDataInValid[item.value]}
                        >
                          {errMessages[item.value]}
                        </FormHelperText>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      value={equipmentData.ownOrRent}
                      aria-label='Rent or Own'
                      name='ownOrRent'
                      onChange={this.handleChange('ownOrRent')}
                      error={isDataInValid['ownOrRent']}
                      row
                    >
                      <Typography
                        gutterBottom
                        style={{ alignSelf: 'center', margin: 10 }}
                      >
                        Rent or Own
                      </Typography>
                      <FormControlLabel
                        value='rent'
                        control={<Radio color='primary' />}
                        label='Rent'
                        labelPlacement='start'
                      />
                      <FormControlLabel
                        value='own'
                        control={<Radio color='primary' />}
                        label='Own'
                        labelPlacement='start'
                      />
                    </RadioGroup>
                    <FormHelperText
                      className={classes.errorStyle}
                      hidden={!isDataInValid['ownOrRent']}
                    >
                      {errMessages['ownOrRent']}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label='Description'
                      multiline
                      rows='2'
                      value={equipmentData.description}
                      className={classes.textField}
                      margin='dense'
                      variant='outlined'
                      onChange={this.handleChange('description')}
                    />
                    <TextField
                      label='Mechanical Status'
                      multiline
                      rows='2'
                      value={equipmentData.mechanicalStatus}
                      className={classes.textField}
                      margin='dense'
                      variant='outlined'
                      onChange={this.handleChange('mechanicalStatus')}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.chipContainer}>
                    <Typography style={{ marginRight: 8 }}>
                      Associated Tags
                    </Typography>
                    <Box>
                      {equipmentData.tags.map((tagValue, idx) => {
                        // let tagLabel = tags.find(
                        //   (tag) => tag.value === tagValue
                        // ).label;
                        return (
                          <Chip
                            key={idx}
                            variant='outlined'
                            color='primary'
                            className={classes.chip}
                            label={tagsMapper[tagValue]}
                            onDelete={() => this.handleDelete(idx)}
                          />
                        );
                      })}
                    </Box>
                    {!isAddTagOpen && tags.length > 0 && (
                      <IconButton onClick={this.toggleAddTag}>
                        <Icon>add_circle</Icon>
                      </IconButton>
                    )}
                    <Autocomplete
                      classes={{ endAdornment: classes.endAdormentStyle }}
                      id={`tags-selection`}
                      options={Filteredtags}
                      value={null}
                      getOptionLabel={(option) =>
                        tagsMapper ? tagsMapper[option] : ''
                      }
                      onChange={this.handleAddTagChange}
                      renderInput={(params) => (
                        <TextField
                          margin='dense'
                          {...params}
                          label={isAddTagOpen && 'Tags'}
                          variant='outlined'
                          style={{
                            display: isAddTagOpen ? 'flex' : 'none',
                          }}
                          SelectProps={{
                            style: {
                              width: 170,
                            },
                          }}
                        />
                      )}
                    />
                    {/* <TextField
                      select
                      value={this.state.customTag}
                      onChange={this.handleAddTagChange}
                      margin='dense'
                      variant='outlined'
                      label={isAddTagOpen && 'Custom Tags'}
                      style={{
                        display: isAddTagOpen ? 'flex' : 'none',
                      }}
                      SelectProps={{
                        style: {
                          width: 170,
                        },
                      }}
                    >
                      {tagOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Link
                        color='inherit'
                        to={`/admin-portal/setup/equipment`}
                        className={classes.listText}
                      >
                        <Button
                          variant='outlined'
                          color='secondary'
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </Link>
                      {!this.state.isLoading && (
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.button}
                        >
                          Save
                        </Button>
                      )}
                      {this.state.isLoading && (
                        <Box
                          mx={4.1}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress
                            className={classes.button}
                            color='primary'
                            size={32}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </form>
              )}
              {dataLoaded < 5 && (
                <Grid item container xs={12} spacing={2}>
                  {[1, 2, 3, 4].map((item) => {
                    return (
                      <Grid item xs={3}>
                        <Skeleton height={35} />
                      </Grid>
                    );
                  })}
                  {/* <Skeleton width={60} height={20}></Skeleton> */}
                  <Grid item xs={12}>
                    <Skeleton width={330} height={35} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton width={160} height={30} />
                    <Box
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Skeleton height={40} className={classes.button} />
                      <Skeleton height={40} className={classes.button} />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(AddEditEquipment));

import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';
import { Paper, Typography } from '@material-ui/core/';
import moment from 'moment';

class BarChartComponent extends PureComponent {
  formatDate = date => {
    const { periodType } = this.props;
    // console.log(date);
    switch (periodType) {
      case 'days':
        return moment(date).format('DD MMM YY');
      case 'weeks':
        return moment(date).format('[Week] w (MMM)');
      case 'months':
        return moment(date).format('MMM YY');
      case 'years':
        return moment(date).format('YYYY');
      default:
        return null;
    }
  };

  render() {
    const { data } = this.props;
    return (
      <ResponsiveContainer>
        <BarChart
          barGap={0}
          data={data}
          margin={{
            top: 15,
            right: 15
          }}
        >
          <XAxis dataKey='name' tickFormatter={tick => this.formatDate(tick)}>
            <Label value='Period' position='insideBottomRight' offset={-5} />
          </XAxis>
          <YAxis allowDecimals={false} type='number' />
          <Tooltip
            content={props => (
              <Paper style={{ padding: 10 }}>
                <Typography>{this.formatDate(props.label)}</Typography>
                {props.payload.map((item, idx) => (
                  <Typography
                    style={{ color: item.color }}
                    key={idx}
                  >{`${item.name}: ${item.value}`}</Typography>
                ))}
              </Paper>
            )}
          />
          <Legend verticalAlign='bottom' height={36} />
          <Bar dataKey='Up' fill='#2e86ab' />
          <Bar dataKey='Down' fill='#f18f01' />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default BarChartComponent;

import React from 'react';
import {
  withStyles,
  withWidth,
  Paper,
  Typography,
  Box,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  CircularProgress,
  Chip,
  Avatar
} from '@material-ui/core/';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { Send as SendIcon, Image as ImageIcon } from '@material-ui/icons';
import axios from 'axios';
import io from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faTimes } from '@fortawesome/free-solid-svg-icons';

import { DropzoneDialog } from 'material-ui-dropzone';
import Compressor from 'compressorjs';

import app from '../../../appConfig';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
let socket = null;
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  messagesBox: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 5,
    paddingRight: theme.spacing(2),
    backgroundColor: '',
    scrollBehavior: 'smooth',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    // margin: theme.spacing(1)
  },
  messageCard: {
    padding: theme.spacing(1.1),
    paddingBottom: 0,
    marginTop: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '51%',
  },
  messageInput: {
    width: '100%',
    padding: theme.spacing(0.8),
    display: 'flex',
    justifyContent: 'flex-end',
    // width: '90%'
  },
  input: {
    position: 'relative',
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

class Chat extends React.Component {
  state = {
    isDropZoneOpen: false,
    isDrawerOpen: false,
    isImageZoomOpen: false,
    selectedImageZoomed: '',
    chatMessages: [],
    newMessage: '',
    selectedImage: '',
    selectedImageFile: null,
    isCsvLoading: false,
    isMessagesLoading: false,
    isInputEnabled: true,
    paginationOptions: { page: 0, limit: 10, total: 0, skip: 0 },
    dates: { start: null, end: null, focusedInput: null },
    timeFilter: { $gte: '', $lte: '' },
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSend();
    }
  };

  handleSend = () => {
    const messageText = this.state.newMessage;
    const messageAttachment = this.state.selectedImageFile;
    if (messageText !== '' || messageAttachment !== null) {
      this.setState({ isInputEnabled: false });
      const chatId = this.props.match.params.id;
      let newMessage;
      if (messageAttachment) {
        newMessage = new FormData();
        newMessage.append('chat', chatId);
        newMessage.append('textMessage', messageText);
        newMessage.append('attachement', messageAttachment);        
      } else {
        newMessage = {
          chat: chatId,
          textMessage: messageText,
        };
      }
      this.postNewMessage(newMessage, 'chat-messages');
    }
  };

  postNewMessage = (newMessage, itemUrl) => {
    axios
      .post(apiEndpoint + itemUrl, newMessage, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then((res) => {
        this.setState({ newMessage: '' });
      })
      .catch((err) => {
        if (err.response) {
        }
      })
      .finally(() => {
        this.setState({ 
          isInputEnabled: true,
          selectedImage: '',
          selectedImageFile: null,
        });
        document.getElementById('messageInput').focus();
      });
  };

  getMessages = () => {
    const { paginationOptions } = this.state;
    const chatId = this.props.match.params.id;
    const service = app.service(`chat-messages/`);
    this.setState({ isMessagesLoading: true });
    let query = {
      chat: chatId,
      $sort: { _id: -1 },
      $skip: paginationOptions.limit * paginationOptions.page,
      $limit: paginationOptions.limit,
    };

    service
      .find({
        query,
      })
      .then((response) => {
        // handle success
        const paginationOptions = {
          skip: response.skip,
          total: response.total,
          limit: response.limit,
        };
        const chatMessages = response.data;

        let lastMessageId = 0;
        const Messages = [];
        let isSentByMe = false;
        if (chatMessages) {
          chatMessages.reverse().map((message) => {
            let userName = 'System';
            if (message.user) {
              userName = message.user.firstName + ' ' + message.user.lastName;
              const email = localStorage.getItem('email');
              if (email === message.user.email) {
                isSentByMe = true;
              }
            }

            Messages.push({
              userName,
              textMessage: message.textMessage,
              isSentByMe,
              hasAttachement: message.hasAttachement,
              file: message.attachement?.file,
              thumbnail: message.attachement?.thumbnail,
              timeStamp: new Date(message.createdAt).toLocaleString(),
              id: message._id,
            });
            isSentByMe = false;
            return true;
          });
          this.setState({
            isMessagesLoading: false,
            paginationOptions: {
              ...this.state.paginationOptions,
              ...paginationOptions,
            },
            chatMessages: [...Messages, ...this.state.chatMessages],
          });
          if (Messages.length > 0) {
            lastMessageId = Messages[Messages.length - 1].id;
            document.getElementById(lastMessageId).scrollIntoView();
          }
        }
      })
      .catch((error) => {
        // handle error
      })
      .finally(() => {
        // always executed
      });
  };

  handleScroll = (event) => {
    const { paginationOptions } = this.state;
    if (
      document.getElementById('messageBox').scrollTop === 0 &&
      paginationOptions.skip < paginationOptions.total
    ) {
      paginationOptions.page = paginationOptions.page + 1;
      this.setState(
        {
          paginationOptions: {
            ...this.state.paginationOptions,
            ...paginationOptions,
          },
        },
        () => {
          this.getMessages();
        }
      );
    }

    // console.log('offsetTop', event.target.offsetTop);
  };

  componentDidMount = () => {
    const chatId = this.props.match.params.id;
    document
      .getElementById('messageBox')
      .addEventListener('scroll', this.handleScroll);
    socket = io(process.env.REACT_APP_API_ENDPOINT, {
      transports: ['websocket', 'polling'],
      query: {
        room: chatId,
      },
    });

    socket.on('connect', function () {
      console.log('Connected to chat.');

      socket.emit(
        'create',
        'authentication',
        {
          strategy: 'jwt',
          accessToken: localStorage.getItem('token'),
        },
        function (error, authResult) {}
      );
    });

    socket.on('chat-messages created', (data) => {
      const newMessage = data;
      let chatMessages = [...this.state.chatMessages];
      let isSentByMe = false;

      let userName = 'System';

      if (newMessage.user.firstName) {
        userName = newMessage.user.firstName + ' ' + newMessage.user.lastName;
        if (this.props.userData.email === newMessage.user.email) {
          isSentByMe = true;
        }
      }

      chatMessages.push({
        userName,
        textMessage: newMessage.textMessage,
        isSentByMe: isSentByMe,
        hasAttachement: newMessage.hasAttachement,
        file: newMessage.attachement?.file,
        thumbnail: newMessage.attachement?.thumbnail,
        timeStamp: new Date(newMessage.createdAt).toLocaleString(),
        id: newMessage._id,
      });
      this.setState({ chatMessages });
      document.getElementById(newMessage._id).scrollIntoView();
    });
    socket.on('disconnect', function () {
      console.log('Disconnected from chat');
    });
    this.getMessages();
  };

  componentWillUnmount = () => {
    socket.disconnect();
    document
      .getElementById('messageBox')
      .removeEventListener('scroll', this.handleScroll);
  };

  handleOpenDropZone = () => {
    this.setState({
      isDropZoneOpen: true,
    })
  }

  handleCloseDropZone = () => {
    this.setState({
      isDropZoneOpen: false,
    })
  }

  handleImageSelected = (file) => {
    this.setState({
      isDropZoneOpen: false,
    }, () => {
      const selectedImageFile = file[0];
      new Compressor(selectedImageFile, {
        quality: 0.4,
        success: (compressedImage) => {
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            this.setState({
              selectedImage: e.target.result,
              selectedImageFile: compressedImage
            })
          };
          fileReader.readAsDataURL(compressedImage);
        }
      })
    });
  }

  handleDeleteImage = () => {
    this.setState({
      selectedImage: '',
      selectedImageFile: null
    });
  }

  handleImageZoomDialog = () => {
    this.setState({
      isImageZoomOpen: false,
      selectedImageZoomed: ''
    })
  }

  handleExport = (chatId) => {
    this.setState({ isCsvLoading: true });
    let itemUrl = 'export-csv';
    const { timeFilter } = this.state;
    if (timeFilter['$lte'] === '') delete timeFilter['$lte'];
    if (timeFilter['$gte'] === '') delete timeFilter['$gte'];
    const service = app.service(itemUrl);
    service
      .find({
        query: {
          model: 'chat-messages',
          chat: chatId,
          createdAt: { ...timeFilter },
        },
      })
      .then((response) => {
        toast.info('A download link will be sent to your email.');
        this.setState({ isCsvLoading: false });
      })
      .catch((err) => {
        // console.log('err', err);
        this.setState({ isCsvLoading: false });
      });
  };

  clearDates = () => {
    const dates = this.state.dates;
    const timeFilter = this.state.timeFilter;
    dates.start = null;
    dates.end = null;
    timeFilter['$gte'] = '';
    timeFilter['$lte'] = '';
    this.setState({ dates, timeFilter });
  };

  render() {
    const { classes } = this.props;
    const {
      chatMessages,
      isCsvLoading,
      isInputEnabled,
      isMessagesLoading,
      dates,
      selectedImage,
      selectedImageFile,
      isImageZoomOpen,
      selectedImageZoomed,
    } = this.state;
    const chatData = JSON.parse(this.props.match.params.chatData);
    const siteName = chatData.siteName;
    const chatId = this.props.match.params.id;
    return (
      <Box
        style={{
          padding: '16px',
          display: 'flex',
          flexFlow: 'column',
          height: 'calc(100% - 64px)',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flex: '0 1 auto',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant='h5' style={{ fontWeight: 200 }} gutterBottom>
              <b>Site Name: </b>
              {siteName}
            </Typography>
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{ alignSelf: 'center' }}
              variant='body1'
              // className={classes.errorStyle}
              // hidden={!isDataInValid['timezone']}
            >
              Export Date:
            </Typography>
            <Box mr={2}>
              <DateRangePicker
                small
                daySize={35}
                firstDayOfWeek={6}
                hideKeyboardShortcutsPanel
                isOutsideRange={(item) => false}
                // enableOutsideDays

                startDateId={`startDate`}
                endDateId={`endDate`}
                // name={tabelHeadNames[idx]}
                startDate={dates.start}
                endDate={dates.end}
                showClearDates
                customCloseIcon={
                  <FontAwesomeIcon
                    size='1x'
                    onClick={(e) => this.clearDates(e)}
                    style={{ visibility: 'visible' }}
                    icon={faTimes}
                  />
                }
                // reopenPickerOnClearDates
                onDatesChange={async ({ startDate, endDate }) => {
                  const timeFilter = this.state.timeFilter;
                  const dates = this.state.dates;
                  switch (dates.focusedInput) {
                    case 'startDate':
                      if (startDate !== null) {
                        const start = new Date(
                          startDate.toDate()
                        ).toISOString();
                        dates.start = startDate;

                        timeFilter['$gte'] = start;
                      } else {
                        dates.start = null;
                        // delete timeFilter;
                      }
                      break;
                    case 'endDate':
                      if (endDate !== null) {
                        const end = new Date(
                          endDate.add(1, 'days').subtract(1, 'seconds').toDate()
                        );
                        dates.end = endDate;
                        timeFilter['$lte'] = end.toISOString();
                      } else {
                        dates.end = null;
                        // delete timeFilter;
                      }
                      break;
                    default:
                      break;
                  }
                  await this.setState({
                    dates,
                    timeFilter,
                  });
                }}
                focusedInput={dates.focusedInput}
                onFocusChange={async (focusedInput) => {
                  const dates = this.state.dates;
                  dates.focusedInput = focusedInput;
                  await this.setState({
                    dates,
                  });
                }}
              />
            </Box>
            {isCsvLoading ? (
              <CircularProgress size={22} />
            ) : (
              <Tooltip title='Export CSV'>
                <IconButton
                  className={classes.button}
                  aria-label='exportCSV'
                  onClick={(event) => this.handleExport(chatId)}
                >
                  <FontAwesomeIcon icon={faFileCsv} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Box className={classes.messagesBox} id='messageBox'>
            <Box>
              {isMessagesLoading && (
                <LinearProgress style={{ height: '3px', width: '100%' }} />
              )}
            </Box>
            {chatMessages.map((message, idx) => {
              return (
                <Paper
                  id={message.id}
                  className={classes.messageCard}
                  style={{
                    alignSelf: message.isSentByMe ? 'flex-end' : 'flex-start',
                  }}
                  key={idx}
                >
                  <Typography
                    style={{
                      color: '#bc313a',
                    }}
                    variant='subtitle2'
                  >
                    {message.userName}
                  </Typography>
                  <Box>
                    <pre style={{ fontFamily: 'inherit', color: '#000' }}>
                      {message.textMessage}
                    </pre>
                    {message.hasAttachement && (
                      <img alt="" src={message.thumbnail} onClick={() => {
                        this.setState({
                          selectedImageZoomed: message.file,
                          isImageZoomOpen: true,
                        })
                      }} />
                    )}
                    <Dialog
                      open={isImageZoomOpen}
                      keepMounted
                      onClose={this.handleImageZoomDialog.bind(this)}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <img alt="" src={selectedImageZoomed} />
                      </DialogContent>
                    </Dialog>
                  </Box>
                  <Tooltip
                    title={moment(message.timeStamp).format('MM/DD/YYYY HH:mm')}
                  >
                    <Box style={{ alignSelf: 'flex-end' }}>
                      <Typography
                        variant='caption'
                        display='block'
                        gutterBottom
                        style={{
                          color: '#0000008a',
                        }}
                      >
                        {moment(message.timeStamp).fromNow().includes('hours')
                          ? moment(message.timeStamp).fromNow()
                          : moment(message.timeStamp).format(
                              'MM/DD/YYYY HH:mm'
                            )}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Paper>
              );
            })}
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flex: '0 1 60px',
            bottom: '0',
          }}
        >
          <Paper className={classes.messageInput}>
            <InputBase
              autoFocus
              id='messageInput'
              className={classes.input}
              disabled={!isInputEnabled}
              placeholder='Write Your Message Here...'
              inputProps={{ 'aria-label': 'Message Input' }}
              onChange={this.handleChange('newMessage')}
              onKeyPress={this.handleKeyPress}
              value={this.state.newMessage}
            />
            <Divider className={classes.divider} orientation='vertical' />
            <IconButton
              color='primary'
              className={classes.iconButton}
              aria-label='directions'
              onClick={this.handleOpenDropZone}
              disabled={!isInputEnabled}
            >
              <ImageIcon />
            </IconButton>
            <IconButton
              color='primary'
              className={classes.iconButton}
              aria-label='directions'
              onClick={this.handleSend}
              disabled={!isInputEnabled}
            >
              <SendIcon />
            </IconButton>
            <DropzoneDialog
              open={this.state.isDropZoneOpen}
              onSave={this.handleImageSelected.bind(this)}
              acceptedFiles={['image/jpeg']}
              showPreviews={false}
              showPreviewsInDropzone={true}
              filesLimit={1}
              maxFileSize={5000000}
              onClose={this.handleCloseDropZone.bind(this)}
            />
          </Paper>
        </Box>
        <Box>
          {selectedImage && (
            <Chip
              style={{
                marginTop: '1vh'
              }}
              avatar={<Avatar alt="" src={selectedImage} />}
              label={selectedImageFile.name}
              onDelete={this.handleDeleteImage.bind(this)}
            />
          )}
        </Box>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(Chat));

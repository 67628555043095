import React, { Component } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Grid,
  Typography,
  withWidth,
  withStyles,
  FormHelperText,
  CircularProgress
} from '@material-ui/core/';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import axios from 'axios';
import Background from '../../assets/images/login.png';
import feathers from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import { toast } from 'react-toastify';

const loginApp = feathers();
loginApp.configure(restClient().axios(axios));

const styles = theme => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorMsg: {
    color: '#f00',
    fontSize: theme.spacing(1.2)
  },
  progress: {
    marginTop: theme.spacing(1.5)
  }
});

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    isLoading: false,
    emailErrorMsg: 'Invalid Email or Password!',
    passwordErrorMsg: 'Invalid Password!',
    isClickedOnce: false,
    isEmailValid: true,
    isPasswordValid: true
  };

  inputchangeHandler = name => event => {
    this.setState({ [name]: event.target.value });
  };

  submitHandler = event => {
    event.preventDefault();
    if (this.state.email !== '' && this.state.password !== '') {
      const { email, password } = this.state;
      const reqBody = {
        strategy: 'local',
        email,
        password
      };
      this.setState({ isLoading: true });
      loginApp
        .service('authentication')
        .create(reqBody)
        .then(res => {
          if (res) {
            this.setState({ isEmailValid: true });
            if (res.user.role !== 'het') {
              localStorage.setItem('token', res.accessToken);
              // localStorage.setItem('role', res.user.role);
              //experimental shit
              axios.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${res.accessToken}`;

              this.props.history.push('/admin-portal');
            } else {
              toast.error('Unauthorized Access.');
            }
          }
        })
        .catch(err => {
          if (err.code === 401) {
            this.setState({ isEmailValid: false, password: '' });
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      // show error msg
      this.setState({ isPasswordValid: false, isEmailValid: false });
    }
  };
  componentDidMount = () => {
    try {
      const service = loginApp.service('authentication');
      if (localStorage.getItem('token')) {
        service
          .create({
            strategy: 'jwt',
            accessToken: localStorage.getItem('token')
          })
          .then(response => {
            axios.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${localStorage.getItem('token')}`;

            this.props.history.push('/admin-portal');
          })
          .catch(error => {
            if (error.code === 401) {
              localStorage.clear();
            }
          });
      }
    } catch (error) {}
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image}></Grid>

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              NACG | Sign in
            </Typography>

            <form className={classes.form} onSubmit={this.submitHandler}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                onChange={this.inputchangeHandler('email')}
                error={!this.state.isEmailValid}
                value={this.state.email}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                error={!this.state.isPasswordValid}
                onChange={this.inputchangeHandler('password')}
                value={this.state.password}
              />
              {!this.state.isEmailValid && (
                <FormHelperText className={classes.errorMsg}>
                  {this.state.emailErrorMsg}
                </FormHelperText>
              )}
              {!this.state.isLoading && (
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                >
                  Sign In
                </Button>
              )}
            </form>
            {this.state.isLoading && (
              <CircularProgress
                className={classes.progress}
                color='primary'
                size={50}
              />
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(withWidth()(SignIn));

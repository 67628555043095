import React from "react";
import {
  withStyles,
  withWidth,
  Grid,
  Typography,
  Box,
  Paper,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core/";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import axios from "axios";
import timezoneSelector from "./timezoneSelector";
import TimePicker from "./TimePicker";
import { toast } from "react-toastify";
import _ from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import "moment-timezone";
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    borderRadius: 5,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    width: theme.spacing(12),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  chipContainer: {
    margin: theme.spacing(1.2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    placeItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tagTextField: { marginLeft: theme.spacing(1) },
  listText: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: "none",
  },
  errorStyle: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#f00",
  },
});

class AddEditSite extends React.Component {
  state = {
    text: "",
    open: false,
    isLoading: false,
    isDataLoaded: false,
    isDataInValid: {
      name: false,
      timezone: false,
      startDate: false,
      endDate: false,
      location: false,
    },
    errMessages: {
      name: "Name should be between 2 and 50 characters.",
      timezone: "Timezone Required.",
      startDate: "Shift start date is not valid.",
      endDate: "Shift end date is not valid.",
      location: "Duplicated locations are not allowed.",
    },
    siteData: {
      name: "",
      timezone: moment.tz.guess(),
      shifts: {
        day: {
          start: moment().toDate(),
          end: moment().toDate(),
        },
        night: {
          start: moment().toDate(),
          end: moment().toDate(),
        },
      },
      locations: [],
    },
    timezoneSelector: timezoneSelector,
    columns: [
      { title: "Location Name", field: "locationName", filtering: false },
    ],
    locationData: [],
    tableOptions: {
      search: false,
      filtering: false,
      actionsColumnIndex: -1,
      pagging: false,
      paginationType: "stepped",
      pageSize: 2,
      paging: false,
      showTitle: true,
      addRowPosition: "first",
      headerStyle: {
        whiteSpace: "noWrap",
        backgroundColor: "#525252",
        color: "#FFF",
        padding: 10,
        fontSize: 14,
      },
      rowStyle: (rowData) => ({
        whiteSpace: "noWrap",
        padding: 0,
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? "#EEE"
            : "#FFF",
      }),
    },
  };

  validateTimes = (shift, startOrEnd) => {
    let shifts = { ...this.state.siteData.shifts };

    const setSecondDateToMatchFirst = (first, second) => {
      if (
        first.getHours() < second.getHours() ||
        (first.getHours() === second.getHours() &&
          first.getMinutes() < second.getMinutes())
      ) {
        second.setDate(first.getDate());
      } else {
        second.setDate(first.getDate() + 1);
      }
    };

    if (
      shifts.day.end.getHours() === shifts.night.start.getHours() &&
      shifts.day.end.getMinutes() === shifts.night.start.getMinutes()
    ) {
      shifts.night.start.setMinutes(shifts.night.start.getMinutes() + 1);
    }

    setSecondDateToMatchFirst(shifts.day.start, shifts.day.end);
    setSecondDateToMatchFirst(shifts.day.end, shifts.night.start);
    setSecondDateToMatchFirst(shifts.night.start, shifts.night.end);

    let state = { ...this.state };
    state.siteData.shifts = shifts;

    this.setState(state);
    // console.log('day start', new Date(shifts.day.start));
    // console.log('day end', new Date(shifts.day.end));
    // console.log('night start', new Date(shifts.night.start));
    // console.log('night end', new Date(shifts.night.end));
  };

  handleTimeChange = (time, shift, startOrEnd) => {
    if (time) {
      let { siteData } = this.state;
      // siteData.shifts[shift][startOrEnd] = moment.tz(moment(time).format('YYYY-MM-DD hh:mm:ss'), siteData.timezone).toDate();
      siteData.shifts[shift][startOrEnd] = time.toDate();
      // console.log('siteData', siteData)
      this.setState({ siteData });
      this.validateTimes(shift, startOrEnd);
    }
  };
  handleChange = (name) => (event) => {
    const { siteData } = this.state;
    let isDataInValid = { ...this.state.isDataInValid };
    siteData[name] = event.target.value;
    isDataInValid[name] = false;
    if (name === "timezone") {
      // console.log(event.target.value)
      moment.tz.setDefault(event.target.value);
    }
    this.setState({ siteData, isDataInValid });
    //
  };

  evalData = (dataObject) => {
    let isDataInValid = { ...this.state.isDataInValid };
    let errMessages = { ...this.state.errMessages };
    let error = false;
    for (var item in dataObject) {
      if (item === "name") {
        if (dataObject["name"].length < 1 || dataObject["name"].length > 50) {
          toast.error(errMessages["name"]);
          isDataInValid["name"] = true;
          error = true;
        }
      }
      if (dataObject[item] === "") {
        isDataInValid[item] = true;
        toast.error(errMessages[item]);
        error = true;
      }
    }
    if (error) {
      this.setState({ isLoading: false, isDataInValid });
      return false;
    }
    return true;
  };
  postItem = (itemUrl) => {
    let bodyData = _.cloneDeep(this.state.siteData);
    let isDataInValid = { ...this.state.isDataInValid };
    let errMessages = { ...this.state.errMessages };
    if (!this.evalData(bodyData)) {
      return;
    }

    bodyData.shifts.day.start = bodyData.shifts.day.start.toISOString();
    bodyData.shifts.day.end = bodyData.shifts.day.end.toISOString();
    bodyData.shifts.night.start = bodyData.shifts.night.start.toISOString();
    bodyData.shifts.night.end = bodyData.shifts.night.end.toISOString();
    // console.log('bodyData', bodyData)
    this.setState({ isLoading: true, isDataInValid });

    axios
      .post(apiEndpoint + itemUrl, bodyData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success(`Site created successfully`);
        let shifts = { ...this.state.siteData.shifts };

        shifts.day.start.setHours(0);
        shifts.day.start.setMinutes(0);
        shifts.day.end.setHours(12);
        shifts.day.end.setMinutes(0);
        shifts.night.start.setHours(12);
        shifts.night.start.setMinutes(1);
        shifts.night.end.setHours(23);
        shifts.night.end.setMinutes(59);

        const siteData = {
          name: "",
          timezone: "",
          shifts: shifts,
          locations: [],
        };
        this.setState({ siteData, locationData: [] });
        this.props.history.push(`/admin-portal/setup/sites`);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          errMessages["name"] = "A site with the same name already exists!";
          isDataInValid["name"] = true;
          toast.error(errMessages["name"]);
        } else {
          errMessages["name"] = "Name should be between 3 and 50 characters.";
        }
        this.setState({ errMessages });

        if (err.response) {
          const errorObject = err.response.data.errors;

          if (Object.keys(errorObject).length > 0) {
            for (var key in errorObject) {
              isDataInValid[key] = true;
              if (key === "timezone") {
                toast.error("Timezone is required.");
              } else {
                toast.error(errorObject[key].message);
              }
            }
          }
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateData = (itemUrl) => {
    itemUrl += "/" + this.props.match.params.id;
    const { name, timezone, shifts, locations } = this.state.siteData;
    const newItemData = {
      name,
      timezone,
      shifts,
      locations,
    };

    axios
      .patch(apiEndpoint + itemUrl, newItemData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success(`Site updated successfully`);
        this.props.history.push(`/admin-portal/setup/sites`);
      })
      .catch((err) => {
        if (err.response) {
          // this.setState({ isEmailValid: false });
          toast.error(err.response.data.message);
        }
      });
  };

  componentDidMount = () => {
    let shifts = { ...this.state.siteData.shifts };
    shifts.day.start.setHours(0);
    shifts.day.start.setMinutes(0);
    shifts.day.end.setHours(12);
    shifts.day.end.setMinutes(0);
    shifts.night.start.setHours(12);
    shifts.night.start.setMinutes(1);
    shifts.night.end.setHours(23);
    shifts.night.end.setMinutes(59);
    let state = { ...this.state };
    state.siteData.shifts = shifts;

    this.setState(state);
    this.setState({ isDataLoaded: true });
    if (localStorage.getItem("text") === "Edit") {
      this.setState({ isDataLoaded: false });
      const siteId = this.props.match.params.id;
      axios
        .get(apiEndpoint + "sites/" + siteId, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // handle success
          let resData = response.data;
          const locations = [];
          resData.shifts.day.start = new Date(resData.shifts.day.start);
          resData.shifts.day.end = new Date(resData.shifts.day.end);
          resData.shifts.night.start = new Date(resData.shifts.night.start);
          resData.shifts.night.end = new Date(resData.shifts.night.end);
          resData.locations.map((location) => {
            locations.push({
              locationName: location,
            });
            return true;
          });

          this.setState({
            siteData: resData,
            locationData: locations,
            isDataLoaded: true,
          });
        })
        .catch((error) => {
          // handle error
        })
        .finally(() => {
          // this.setState({isDataLoaded:true})
          // always executed
        });
    }
  };
  submitHandler = (event) => {
    event.preventDefault();
    const submitType = localStorage.getItem("text");
    if (submitType === "Add") {
      this.postItem("sites");
    } else {
      this.updateData("sites");
    }
  };
  render() {
    const { classes } = this.props;
    const {
      timezoneSelector,
      columns,
      locationData,
      tableOptions,
      isDataInValid,
      errMessages,
      isDataLoaded,
    } = this.state;
    const { shifts, timezone } = this.state.siteData;

    return (
      <Box>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Typography variant="h4" gutterBottom>
              {localStorage.getItem("text")} Site
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" style={{ marginLeft: 20 }} gutterBottom>
                Site Information
              </Typography>
              {isDataLoaded && (
                <form
                  className={classes.formContainer}
                  autoComplete="off"
                  onSubmit={this.submitHandler}
                >
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={3}>
                      <TextField
                        autoFocus
                        error={isDataInValid["name"]}
                        required
                        label="Site Name"
                        className={classes.textField}
                        onChange={this.handleChange("name")}
                        margin="dense"
                        variant="outlined"
                        value={this.state.siteData.name}
                      />
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["name"]}
                      >
                        {errMessages["name"]}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        select
                        required
                        label="Timezone"
                        error={isDataInValid["timezone"]}
                        className={classes.textField}
                        value={this.state.siteData["timezone"]}
                        onChange={this.handleChange("timezone")}
                        margin="dense"
                        variant="outlined"
                      >
                        {timezoneSelector.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["timezone"]}
                      >
                        {errMessages["timezone"]}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={3}>
                      <TimePicker
                        handleTimeChange={this.handleTimeChange}
                        error={isDataInValid["startDate"]}
                        required
                        shift="day"
                        startDate={shifts.day.start}
                        endDate={shifts.day.end}
                        timezone={timezone}
                        disabled={!timezone}
                      ></TimePicker>
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["startDate"]}
                      >
                        {errMessages["startDate"]}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                      <TimePicker
                        handleTimeChange={this.handleTimeChange}
                        error={isDataInValid["endDate"]}
                        required
                        shift="night"
                        startDate={shifts.night.start}
                        endDate={shifts.night.end}
                        timezone={timezone}
                        disabled={!timezone}
                      ></TimePicker>
                      <FormHelperText
                        className={classes.errorStyle}
                        hidden={!isDataInValid["endDate"]}
                      >
                        {errMessages["endDate"]}
                      </FormHelperText>
                    </Grid>
                    {/* ))} */}
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <MaterialTable
                        columns={columns}
                        data={locationData}
                        title="Locations"
                        options={tableOptions}
                        onRowClick={(evt, selectedRow) =>
                          this.setState({ selectedRow })
                        }
                        editable={{
                          onRowAdd: (newData) =>
                            new Promise((resolve) => {
                              setTimeout(() => {
                                resolve();
                                const data = [...this.state.locationData];
                                const { siteData } = this.state;
                                if (
                                  siteData.locations.indexOf(
                                    newData.locationName
                                  ) === -1
                                ) {
                                  data.push(newData);
                                  siteData.locations.push(newData.locationName);
                                  this.setState({
                                    locationData: data,
                                    siteData,
                                  });
                                } else {
                                  toast.error(
                                    "A location with the same name already exists."
                                  );
                                }
                              }, 150);
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                              setTimeout(() => {
                                resolve();
                                const data = [...this.state.locationData];
                                const { siteData } = this.state;
                                data[data.indexOf(oldData)] = newData;
                                siteData.locations[
                                  siteData.locations.indexOf(
                                    oldData.locationName
                                  )
                                ] = newData.locationName;
                                this.setState({ locationData: data, siteData });
                              }, 150);
                            }),
                          onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                              setTimeout(() => {
                                resolve();
                                const data = [...this.state.locationData];
                                const { siteData } = this.state;
                                data.splice(data.indexOf(oldData), 1);
                                siteData.locations.splice(
                                  siteData.locations.indexOf(
                                    oldData.locationName
                                  ),
                                  1
                                );
                                this.setState({ locationData: data, siteData });
                              }, 150);
                            }),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Link
                        color="inherit"
                        to={`/admin-portal/setup/sites`}
                        className={classes.listText}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </Link>
                      {!this.state.isLoading && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          Save
                        </Button>
                      )}
                      {this.state.isLoading && (
                        <Box
                          mx={4.1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress
                            className={classes.button}
                            color="primary"
                            size={32}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </form>
              )}
              {!isDataLoaded && (
                <Grid item container xs={12} spacing={2}>
                  {[1, 2, 3, 4].map((item) => {
                    return (
                      <Grid item xs={3}>
                        <Skeleton height={35} />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Skeleton height={40} />
                    <Skeleton height={80} />
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Skeleton height={40} className={classes.button} />
                      <Skeleton height={40} className={classes.button} />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(AddEditSite));

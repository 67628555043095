import React, { Fragment } from "react";
import {
  withStyles,
  withWidth,
  Grid,
  Box,
  TextField,
  MenuItem,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  Table,
  TableHead,
  Tooltip,
  TablePagination,
  TableFooter,
} from "@material-ui/core/";
import { Skeleton } from "@material-ui/lab";
import app from "../../../appConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

const download = require("downloadjs");
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    borderRadius: 20,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    width: theme.spacing(15),
    margin: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  parentRow: {
    backgroundColor: "#525252",
  },
});

class EquipmentSite extends React.Component {
  state = {
    sites: [],
    selectedSite: 0,
    tableData: [],
    sitesLoading: true,
    tableLoading: true,
    isXlsLoading: false,
    groupsLoading: true,
    equipmentGroups: {},
    paginationOptions: { page: 0, limit: 50, total: 0, skip: 0 },
  };

  componentDidMount = async () => {
    this.props.changeHeader("Reports | Equipment/Sites");
    let sites;
    const sitesRoute = app.service("sites");

    try {
      sites = (await sitesRoute.find({ query: { $limit: null } })).data;
      this.setState({ sites, sitesLoading: false, selectedSite: sites[0]._id });
      await this.getSiteData(sites[0]._id);
    } catch (error) {
      this.setState({ sitesLoading: false });
    }

    //
  };

  handleExport = (statusId) => {
    this.setState({ isXlsLoading: true });
    let itemUrl = "/export-xlsx/" + statusId;

    const service = app.service(itemUrl);
    service
      .find()
      .then((response) => {
        var x = new XMLHttpRequest();
        x.open("GET", `${apiEndpoint}${response.fileName}`, true);
        x.responseType = "blob";
        x.onload = (e) => {
          download(
            e.target.response,
            `equipment-status-${moment().format("MM-DD-YYYY")}.xlsx`
          );
        };
        x.send();
      })
      .finally(() => {
        this.setState({ isXlsLoading: false });
      });
  };
  getSiteData = (siteId) => {
    let paginationOptions = { ...this.state.paginationOptions };
    this.setState({ groupsLoading: true });
    const equipmentStatus = app.service("br-ec-pairs");
    let query = {
      $skip: paginationOptions.limit * paginationOptions.page,
      $limit: paginationOptions.limit,
    };
    equipmentStatus.get(siteId, { query }).then((res) => {
      const equipmentGroups = {};
      paginationOptions = {
        skip: res.skip,
        total: res.total,
        limit: res.limit,
      };
      // eslint-disable-next-line no-unused-expressions
      res?.data?.forEach((group) => {
        equipmentGroups[group.name] = {
          data: [],
          paginationOptions: { page: 0, limit: 5, total: 0, skip: 0 },
          query: {
            billingRate: group.billingRate,
            equipmentClass: group.equipmentClass,
          },
          isLoading: false,
          emptyRows: 5,
        };
      });
      this.setState({
        tableData: res,
        tableLoading: false,
        equipmentGroups,
        groupsLoading: false,
        paginationOptions: {
          ...this.state.paginationOptions,
          ...paginationOptions,
        },
      });
      setTimeout(() => {
        // const { equipmentGroups } = this.state;
        this.fetchGroupData(Object.keys(equipmentGroups)[0]);
      }, 250);
    });
  };

  fetchGroupData = (groupName) => {
    const { equipmentGroups } = this.state;
    const equipmentGroup = equipmentGroups[groupName];
    if (equipmentGroup) {
      if (equipmentGroup.data.length > 0) return;
      this.getGroupData(groupName);
    }
  };
  getGroupData = async (groupName) => {
    const { equipmentGroups, selectedSite } = this.state;
    const equipmentGroup = equipmentGroups[groupName];
    equipmentGroup.isLoading = true;
    await this.setState({ equipmentGroups });

    const equipmentStatus = app.service(`equipment-status/${selectedSite}`);

    let query = {
      ...equipmentGroup.query,
      $skip:
        equipmentGroup.paginationOptions.limit *
        equipmentGroup.paginationOptions.page,
      $limit: equipmentGroup.paginationOptions.limit,
    };
    try {
      equipmentStatus.find({ query }).then((res) => {
        equipmentGroup.data = res.data;
        equipmentGroup.paginationOptions.total = res.total;
        equipmentGroup.paginationOptions.skip = res.skip;
        equipmentGroup.isLoading = false;
        equipmentGroup.emptyRows = res.limit - res.data.length;
        equipmentGroups[groupName] = equipmentGroup;
        this.setState({ equipmentGroups });
      });
    } catch (err) {
      equipmentGroup.data = [];
      equipmentGroup.isLoading = false;
      equipmentGroups[groupName] = equipmentGroup;
      await this.setState({ equipmentGroups });
    }
  };
  render() {
    const { classes } = this.props;
    const {
      sites,
      selectedSite,
      sitesLoading,

      isXlsLoading,
      equipmentGroups,
      groupsLoading,
    } = this.state;
    //
    function TablePaginationActions(props) {
      const theme = useTheme();
      const { count, page, rowsPerPage, onChangePage } = props;

      const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
      };

      const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
      };

      const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
      };

      const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
        <div
          style={{
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
          }}
        >
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      );
    }

    //
    return (
      <Box mt={2}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            {/* <Box>
              <Typography variant='h4' gutterBottom>
                Equipment/Sites
              </Typography>
            </Box> */}

            <Box style={{ display: "flex", placeItems: "center" }}>
              {isXlsLoading ? (
                <CircularProgress size={32} />
              ) : (
                <Tooltip title="Export XlS">
                  <IconButton
                    className={classes.button}
                    aria-label="exportCSV"
                    onClick={(event) => this.handleExport(selectedSite)}
                  >
                    <img
                      style={{ height: "30px" }}
                      alt="Xls"
                      src={require("../../../assets/images/xls.png")}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {sitesLoading ? (
                <Skeleton width={120} height={40}></Skeleton>
              ) : (
                <TextField
                  select
                  label="Site"
                  className={classes.textField}
                  value={selectedSite}
                  onChange={(event) => {
                    this.setState(
                      {
                        tableLoading: true,
                        selectedSite: event.target.value,
                        paginationOptions: {
                          ...this.state.paginationOptions,
                          page: 0,
                        },
                      },
                      () => this.getSiteData(event.target.value)
                    );
                  }}
                  margin="dense"
                  variant="outlined"
                >
                  {sites.map((site) => (
                    <MenuItem key={site._id} value={site._id}>
                      {site.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ maxWidth: "100%", height: "80%" }}>
              {/* <Paper> */}

              {groupsLoading ? (
                <Box>
                  <Skeleton width="100%" height={45}></Skeleton>
                  <Skeleton width="100%" height={45}></Skeleton>
                  <Skeleton width="100%" height={45}></Skeleton>
                  <Skeleton width="100%" height={45}></Skeleton>
                  <Skeleton width="100%" height={45}></Skeleton>
                  <Skeleton width="100%" height={45}></Skeleton>
                  <Skeleton width="100%" height={45}></Skeleton>
                  <Skeleton width="100%" height={45}></Skeleton>
                </Box>
              ) : (
                Object.keys(equipmentGroups).map((groupName, idx) => {
                  return (
                    <ExpansionPanel
                      id={`panel${idx}`}
                      style={{ marginBottom: 5 }}
                      key={idx}
                      defaultExpanded={idx === 0}
                      onClick={() => this.fetchGroupData(groupName)}
                    >
                      <ExpansionPanelSummary
                        style={{ backgroundColor: "#525252", color: "#fff" }}
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#fff" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {groupName}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        style={{ flexDirection: "column" }}
                      >
                        <Table
                          style={
                            {
                              // height: 100 * equipmentGroups[groupName].data.length
                            }
                          }
                        >
                          <TableHead>
                            <TableRow>
                              {[
                                "Index",
                                "Equipment Number",
                                "Status",
                                "Est. Date of Repair",
                                "Current Issues",
                                "Location",
                                "Comments",
                              ].map((item, idx) => (
                                <TableCell align="left" key={idx}>
                                  {item}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {equipmentGroups[groupName].isLoading ? (
                              <TableRow>
                                <TableCell colSpan={7}>
                                  <Box
                                    style={{
                                      width: "100%",

                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      opacity: 0.6,
                                    }}
                                  >
                                    <CircularProgress size={45} />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ) : (
                              equipmentGroups[groupName].data.map(
                                (rowData, idx) => (
                                  <Fragment key={rowData._id}>
                                    {/* <TableRow className={classes.parentRow}>
                                <TableCell
                                  colSpan={2}
                                  style={{ color: '#ffffff' }}
                                  align='left'
                                >
                                  {item1}
                                </TableCell>
                                <TableCell align='left'></TableCell>
                                <TableCell align='left'></TableCell>
                                <TableCell align='left'></TableCell>
                                <TableCell align='left'></TableCell>
                                <TableCell align='left'></TableCell>
                              </TableRow> */}
                                    <TableRow>
                                      <TableCell align="left">
                                        {idx + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.number}
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.equipmentStatus === "DN"
                                          ? "DM"
                                          : "AV"}
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.estimatedDateOfRepair
                                          ? new Date(
                                              rowData.estimatedDateOfRepair
                                            ).toLocaleDateString()
                                          : null}
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.additionalDetails}
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.location}
                                      </TableCell>
                                      <TableCell align="left">
                                        {rowData.notes}
                                      </TableCell>
                                    </TableRow>
                                  </Fragment>
                                )
                              )
                            )}
                          </TableBody>
                          <TableFooter>
                            <TablePagination
                              ActionsComponent={TablePaginationActions}
                              rowsPerPageOptions={[5, 10, 20]}
                              style={{ width: "100%" }}
                              count={
                                equipmentGroups[groupName].paginationOptions
                                  .total
                              }
                              rowsPerPage={
                                equipmentGroups[groupName].paginationOptions
                                  .limit
                              }
                              page={
                                equipmentGroups[groupName].paginationOptions
                                  .page
                              }
                              SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                              }}
                              onChangePage={async (event, page) => {
                                const equipmentGroups = {
                                  ...this.state.equipmentGroups,
                                };
                                equipmentGroups[
                                  groupName
                                ].paginationOptions.page = page;
                                this.setState({
                                  equipmentGroups,
                                });
                                this.getGroupData(groupName);
                              }}
                              onChangeRowsPerPage={async (event) => {
                                const pageSize = parseInt(event.target.value);
                                const equipmentGroups = {
                                  ...this.state.equipmentGroups,
                                };
                                equipmentGroups[
                                  groupName
                                ].paginationOptions.page = 0;

                                equipmentGroups[
                                  groupName
                                ].paginationOptions.limit = pageSize;

                                this.setState({
                                  equipmentGroups,
                                });
                                this.getGroupData(groupName);
                              }}
                            />
                          </TableFooter>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  );
                })
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: "flex", placeItems: "center" }}>
              <TablePagination
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={[50, 100, 200]}
                style={{ width: "100%" }}
                count={this.state.paginationOptions.total}
                rowsPerPage={this.state.paginationOptions.limit}
                page={this.state.paginationOptions.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                }}
                onChangePage={async (event, page) => {
                  const paginationOptions = {
                    ...this.state.paginationOptions,
                  };
                  paginationOptions.page = page;
                  console.log(`page`, page);
                  this.setState(
                    {
                      paginationOptions,
                    },
                    () => this.getSiteData(selectedSite)
                  );
                }}
                onChangeRowsPerPage={async (event) => {
                  const pageSize = parseInt(event.target.value);
                  const paginationOptions = {
                    ...this.state.paginationOptions,
                  };
                  paginationOptions.page = 0;
                  paginationOptions.limit = pageSize;

                  this.setState(
                    {
                      paginationOptions,
                    },
                    () => this.getSiteData(selectedSite)
                  );
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(EquipmentSite));

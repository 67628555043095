import React, { useEffect } from "react";
import clsx from "clsx";
import Select from "react-select";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core/";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";
import app from "../../../appConfig";

const CancelToken = axios.CancelToken;
var cancel;

const routes = {
  Site: "sites",
  "Site Name": "sites",
  "Billing Rate": "billing-rates",
  "Equipment Class": "equipment-classes",
  User: "users",
  "Equipment Number": "equipments",
  "Equipment Number ": "equipments",
  "First Name": "users",
  "Last Name": "users",
  "Custom Tags": "custom-tags",
  Email: "users",
  Location: "locations",
};

const queryNameMapper = {
  "First Name": "firstName",
  "Last Name": "lastName",
  Role: "role",
  Email: "email",
  "Site Name": "name",
  Site: "name",
  "Billing Rate": "name",
  "Equipment Class": "name",
  User: "user",
  Status: "equipmentStatus",
  "Custom Tags": "name",
  Shift: "shiftType",
  "Equipment Number": "number",
  "Equipment Number ": "number",
  Location: "_id",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // height: 250,
    minWidth: 290,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

export default function FilterInput(props) {
  const { fieldName, setFilterQuery } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [selectedItems, setSelectedItems] = React.useState(null);
  const [suggestions, setSuggestions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    getFilterSuggestions(fieldName, {});
    const selectedItems = JSON.parse(
      localStorage.getItem(`${fieldName}SelectedItems`)
    );

    if (selectedItems !== null) {
      setSelectedItems(selectedItems);
      setFilterQuery(fieldName, selectedItems);
    }
  }, []);

  const handleChangeMulti = (value) => {
    setFilterQuery(fieldName, value);

    localStorage.setItem(`${fieldName}SelectedItems`, JSON.stringify(value));
    setSelectedItems(value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    const query = {};
    if (fieldName === "User") {
      query["$or"] = [];
      query["$or"].push({ firstName: { $search: value } });
      query["$or"].push({ lastName: { $search: value } });
    } else {
      query[queryNameMapper[fieldName]] = { $search: value };
    }
    if (typeof cancel == "function") {
      cancel();
    }
    getFilterSuggestions(fieldName, query);
  };

  const getFilterSuggestions = (itemName, query) => {
    setIsLoading(true);
    let resData = [];

    if (itemName === "Location") {
      query = {};
    }
    const service = app.service(routes[itemName]);

    service
      .find({
        query: {
          ...query,
          $limit: 4,
        },
        connection: {
          cancelToken: new CancelToken(function (c) {
            cancel = c;
          }),
        },
      })
      .then((response) => {
        if (fieldName === "Location") {
          resData = response;
        } else {
          resData = response.data;
        }

        if (resData) {
          setSuggestions(mapSuggestions(itemName, resData));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // handle error
      });
  };

  const mapSuggestions = (label, resData) => {
    let userSuggestions = [];

    switch (label) {
      case "Site Name":
        let siteSuggestions = [];
        if (resData) {
          resData.forEach((item) => {
            siteSuggestions.push({
              id: item._id,
              label: item.name,
              name: item.name,
              value: item.name,
            });
          });
        }
        return siteSuggestions;
      case "Site":
      case "Billing Rate":
      case "Equipment Class":
      case "Custom Tags":
        let suggestions = [];
        if (resData) {
          resData.forEach((item) => {
            suggestions.push({
              id: item._id,
              label: item.name,
              name: item.name,
              value: item._id,
            });
          });
        }
        return suggestions;
      case "Equipment Number":
        let equipmentSuggestions = [];
        if (resData) {
          resData.forEach((equipment) => {
            equipmentSuggestions.push({
              id: equipment._id,
              label: equipment.number,
              value: equipment._id,
              equipment: equipment._id,
            });
          });
        }
        return equipmentSuggestions;
      case "Equipment Number ":
        let numberSuggestions = [];
        if (resData) {
          resData.forEach((equipment) => {
            numberSuggestions.push({
              id: equipment._id,
              label: equipment.number,
              value: equipment._id,
              equipment: equipment._id,
            });
          });
        }
        return numberSuggestions;
      case "User":
        if (resData) {
          resData.forEach((user) => {
            userSuggestions.push({
              id: user._id,
              label: user.firstName + " " + user.lastName,
              value: user._id,
              user: user._id,
            });
          });
        }
        return userSuggestions;
      case "First Name":
        if (resData) {
          resData.forEach((user) => {
            userSuggestions.push({
              id: user._id,
              label: user.firstName,
              value: user.firstName,
              firstName: user.firstName,
            });
          });
        }
        return userSuggestions;
      case "Last Name":
        if (resData) {
          resData.forEach((user) => {
            userSuggestions.push({
              id: user._id,
              label: user.lastName,
              value: user.lastName,
              lastName: user.lastName,
            });
          });
        }
        return userSuggestions;
      case "Email":
        let emailSuggestinos = [];
        if (resData) {
          resData.forEach((user) => {
            emailSuggestinos.push({
              id: user._id,
              label: user.email,
              value: user.email,
              emailSuggestinos: user.email,
            });
          });
        }
        return emailSuggestinos;
      case "Location":
        let locationSugesstions = [];
        if (resData) {
          resData.forEach((location) => {
            locationSugesstions.push({
              label: location._id,
              value: location._id,
              site: location.value,
            });
          });
        }
        return locationSugesstions;
      default:
        return resData;
    }
  };

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
  };
  // const filedNameMapper={
  //   'User':'users',
  //   'Location':'locations',
  //   'Equipment Number':'equipments',
  //   'Billing Rate':'billing rates',
  //   'Custom Tag':'tags',

  // }
  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId={fieldName}
          TextFieldProps={{
            InputLabelProps: {
              htmlFor: "react-select-multiple",
              shrink: true,
            },
            onChange: handleInputChange,
          }}
          placeholder={`Select multiple`}
          options={suggestions}
          components={components}
          value={selectedItems}
          onChange={handleChangeMulti}
          isMulti
          isLoading={isLoading}
          autoFocus
        />
      </NoSsr>
    </div>
  );
}

import React from 'react';
import { withStyles, withWidth } from '@material-ui/core/';
import StatusEntries from './StatusEntries/StatusEntries';
import { Route, Redirect } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Layout from './Layout';
import Reports from './Reports/Reports';
import Setup from './Setup/Setup';
import Chat from './SitesChat/Chat';
import SitesChat from './SitesChat';
import AddEditStatus from './StatusEntries/AddEditStatus';
import 'react-toastify/dist/ReactToastify.css';
import app from '../../appConfig';
import axios from 'axios';
import { ThemeProvider } from '@material-ui/styles';

import Snackbar from './Snackbar';
const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  }
});

class AdminPortal extends React.Component {
  state = {
    isDrawerOpen: false,
    pageName: 'Dashboard',
    userData: '',
    isCsvLoading: false
  };
  constructor(props) {
    super(props);
    this.snackbar = React.createRef();
  }
  componentDidMount = async () => {
    try {
      if (localStorage.getItem('token')) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${localStorage.getItem('token')}`;
        const service = app.service('authentication');
        service
          .create({
            strategy: 'jwt',
            accessToken: localStorage.getItem('token')
          })
          .then(response => {
            //
            const { user } = response;
            // toast(`Welcome ${user.firstName} ${user.lastName}!`);
            this.setState({ userData: user });
            localStorage.setItem('email', user.email);
            axios.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${response.accessToken}`;
            // console.log(
            //   'axios.defaults.headers.common["Authorization"]',
            //   axios.defaults.headers.common['Authorization']
            // );
          })
          .catch(error => {
            if (error.code === 401) {
              localStorage.clear();
              this.props.history.push('/signin');
            }
          });
      } else {
        localStorage.clear();
        this.props.history.push('/signin');
      }
    } catch (error) {}
  };
  spinCsvLoading = () => {
    this.setState({ isCsvLoading: true });
  };
  stopCsvLoading = () => {
    this.setState({ isCsvLoading: false });
  };
  RedirectToDashboard = () => {
    return <Redirect to='/admin-portal/dashboard' />;
  };
  changeHeader = name => {
    this.setState({ pageName: name });
  };
  render() {
    const { classes } = this.props;
    const { userData, isCsvLoading } = this.state;
    return (
      <div className={classes.root}>
        <Layout pageName={this.state.pageName} userData={userData}></Layout>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Route
            exact
            path={'/admin-portal/'}
            userData={userData}
            component={this.RedirectToDashboard}
          />
          <Route
            path={'/admin-portal/dashboard'}
            render={props => (
              <Dashboard
                {...props}
                userData={userData}
                changeHeader={this.changeHeader}
              ></Dashboard>
            )}
          />
          <Route
            path={'/admin-portal/status-entries'}
            render={props => (
              <StatusEntries
                {...props}
                userData={userData}
                changeHeader={this.changeHeader}
                snackbar={this.snackbar}
                isCsvLoading={isCsvLoading}
              ></StatusEntries>
            )}
          />
          <Route
            path={'/admin-portal/reports'}
            render={props => (
              <Reports
                {...props}
                userData={userData}
                changeHeader={this.changeHeader}
                snackbar={this.snackbar}
                isCsvLoading={isCsvLoading}
              ></Reports>
            )}
          />
          <Route
            path={'/admin-portal/setup'}
            render={props => (
              <Setup
                {...props}
                userData={userData}
                changeHeader={this.changeHeader}
                snackbar={this.snackbar}
                isCsvLoading={isCsvLoading}
              ></Setup>
            )}
          />
          <Route
            path={'/admin-portal/sites-chat'}
            render={props => (
              <SitesChat
                {...props}
                userData={userData}
                changeHeader={this.changeHeader}
              ></SitesChat>
            )}
          />
          <Route
            path={'/admin-portal/chat/:id/:chatData'}
            render={props => (
              <Chat
                {...props}
                snackbar={this.snackbar}
                isCsvLoading={isCsvLoading}
                userData={userData}
                changeHeader={this.changeHeader}
              ></Chat>
            )}
          />
          <Route
            path={'/admin-portal/status-equipment/add-edit-status/:id'}
            render={props => (
              <AddEditStatus
                {...props}
                userData={userData}
                changeHeader={this.changeHeader}
              ></AddEditStatus>
            )}
          />
          <ThemeProvider>
            <Snackbar
              ref={this.snackbar}
              spinCsvLoading={this.spinCsvLoading}
              stopCsvLoading={this.stopCsvLoading}
            />
          </ThemeProvider>
        </main>
      </div>
    );
  }
}
export default withStyles(styles)(withWidth()(AdminPortal));

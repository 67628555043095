import feathers from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import axios from 'axios';

const app = feathers();

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.timeout = 5000;
app.configure(restClient().axios(axios));

export default app;

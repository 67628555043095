import React from "react";
import {
  withStyles,
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core/";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DatePicker from "../DatePicker/";
import FilterInput from "./FilterInput";

const statusSuggesstions = [
  {
    name: "Available",
    value: "AV",
  },
  {
    name: "Down",
    value: "DN",
  },
];
const shiftSuggestions = [
  {
    name: "Day",
    value: "day",
  },
  { name: "Night", value: "night" },
];
const roleSuggestions = [
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "Coordinator/Planner",
    value: "planner",
  },
  {
    name: "Foreman/HET",
    value: "het",
  },
];

const filterQueryNameMapper = {
  "First Name": "firstName",
  "Last Name": "lastName",
  Role: "role",
  Email: "email",
  "Site Name": "name",
  Site: "site",
  "Billing Rate": "billingRate",
  "Equipment Class": "equipmentClass",
  User: "user",
  Status: "equipmentStatus",
  "Custom Tags": "tags",
  Shift: "shiftType",
  "Date Range": "timestamp",
  "Equipment Number": "equipment",
  "Equipment Number ": "_id",
  Location: "location",
  "Equipment Status": "equipmentStatus",
};

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  list: {
    width: 360,
  },
  sideFilterHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1.2),
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  drawerPaper: {
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  button: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    width: theme.spacing(15),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  listText: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: "none",
  },
});

class SideFilter extends React.Component {
  state = {
    open: false,
    gilad: false,
    filterLabels: [],
    labels: this.props.labels,
    dateRange: null,
    query: {},
    isLoading: false,
    statusFilter: "",
    shiftFilter: "",
    roleFilter: "",
    didFilter: false,
    canFilter: false,
    filterQuery: {},
    filterDetails: {},
  };

  openDrawer = () => {
    this.setState({ open: true });
  };
  enbleFilter = (query) => {
    if (Object.keys(query).length > 0) {
      return true;
    }
    return false;
  };
  setDateRange = (dateRange) => {
    // localStorage.setItem(`Date RangeSelectedItems`, JSON.stringify(dateRange));
    this.setFilterQuery("Date Range", [dateRange]);
    this.setState({ dateRange });
  };
  closeDrawer = (event) => {
    this.setState({ open: false });
  };

  handleChange = (id) => async (event) => {
    const { filterLabels } = this.state;
    const query = { ...this.state.query };
    filterLabels.find((filterLabel, i) => {
      if (filterLabel.id === id) {
        if (filterLabel.isActive === true) {
          if (filterLabel.label === "Status") {
            this.setState({ statusFilter: "" });
          }
          if (filterLabel.label === "Shift") {
            this.setState({ shiftFilter: "" });
          }
          if (filterLabel.label === "Role") {
            this.setState({ roleFilter: "" });
          }

          this.deleteQueryItem(filterLabel.label);
          localStorage.removeItem(`${filterLabel.label}SelectedItems`);
          if (filterLabel.label === "Date Range") {
            this.setState({ dateRange: null });
          }
        }
        filterLabel.isActive = event.target.checked;
        return false; // stop searching
      }
      return false;
    });
    this.setState({
      filterLabels,
      // query,
      canFilter: this.enbleFilter(query),
    });
  };

  clearFilter = async () => {
    const filterLabels = { ...this.state.filterLabels };
    this.clearFilterQuery();
    await this.props.setFilterQuery({}, {});
    this.componentDidMount();
    this.props.getItems();

    for (var i in filterLabels) {
      localStorage.removeItem(`${filterLabels[i].label}SelectedItems`);
    }
  };
  submitHandler = async () => {
    const filterQuery = { ...this.state.filterQuery };
    const filterDetails = { ...this.state.filterDetails };

    this.setState({ isLoading: true });
    this.props.setFilterStatus(true);
    await this.props.resetPages();
    await this.props.setFilterQuery(filterQuery, filterDetails);
    this.props.getItems();
    setTimeout(() => {
      this.setState({ isLoading: false, didFilter: true });
      this.closeDrawer();
    }, 150);
  };

  handleStatusChange = (event) => {
    this.setFilterQuery("Status", [event.target]);
    this.setState({
      statusFilter: event.target.value,
      // canFilter: this.enbleFilter(query)
    });
  };
  handleShiftChange = (event) => {
    this.setFilterQuery("Shift", [event.target]);
    this.setState({
      shiftFilter: event.target.value,
      // canFilter: this.enbleFilter(query)
    });
  };
  hadelRoleChange = (event) => {
    this.setFilterQuery("Role", [event.target]);
    this.setState({
      roleFilter: event.target.value,
      // canFilter: this.enbleFilter(query)
    });
  };

  componentDidMount = () => {
    const { labels } = this.state;
    let filterLabels = [];
    if (labels) {
      labels.map((label, idx) => {
        filterLabels.push({
          id: idx,
          label: label,
          isActive: false,
        });
        return true;
      });
    }
    // const dateRange = JSON.parse(
    //   localStorage.getItem('Date RangeSelectedItems')
    // );
    // if (dateRange != null) {
    //   dateRange.start = moment(dateRange.start);
    //   dateRange.end = moment(dateRange.end);
    //   this.setState({ dateRange });
    //
    //   this.setFilterQuery('Date Range', [dateRange]);
    // }
    this.setState({ filterLabels });
  };

  setFilterQuery = (itemName, data) => {
    const filterQuery = { ...this.state.filterQuery };
    const filterDetails = { ...this.state.filterDetails };
    const queryList = [];
    const detailsList = [];

    if (data !== null && data.length !== 0) {
      data.forEach((item) => {
        queryList.push(item.value);
        if (itemName === "Status") {
          detailsList.push(item.value === "AV" ? "Avilable" : "Down");
        } else if (itemName === "Shift") {
          detailsList.push(item.value === "day" ? "Day" : "Night");
        } else if (itemName === "Role") {
          let role = "";
          if (item.value === "admin") {
            role = "Admin";
          } else if (item.value === "planner") {
            role = "Coordinator/Planner";
          } else {
            role = "Foreman/HET";
          }
          detailsList.push(role);
        } else {
          detailsList.push(item.label);
        }
      });
      if (itemName === "Date Range") {
        const start = new Date(data[0].start.toDate());
        const end = new Date(
          data[0].end.add(1, "days").subtract(1, "seconds").toDate()
        );

        filterQuery["timestamp"] = { $gte: start, $lte: end };
        filterDetails["Date Range"] = data[0];
      } else {
        filterQuery[filterQueryNameMapper[itemName]] = { $in: queryList };
        filterDetails[itemName] = detailsList;
      }
    } else {
      delete filterDetails[itemName];
      delete filterQuery[filterQueryNameMapper[itemName]];
    }
    this.setState({ filterQuery, filterDetails }, () => {});
  };
  clearFilterQuery = () => {
    this.setState({ filterQuery: {}, filterDetails: {} });
  };
  deleteQueryItem = (itemName) => {
    const filterQuery = { ...this.state.filterQuery };
    const filterDetails = { ...this.state.filterDetails };
    delete filterDetails[itemName];
    delete filterQuery[filterQueryNameMapper[itemName]];
    this.setState({ filterQuery, filterDetails }, () => {});
  };
  render() {
    const { classes, setSelectedNames } = this.props;
    const { filterLabels, didFilter } = this.state;

    const sideList = (side) => (
      <div className={classes.list} role="presentation">
        <Box mt={4}>
          {/* <Divider /> */}
          <Box mt={1.5} className={classes.sideFilterHeader}>
            <Typography variant="h5">Filter & Search </Typography>
            <IconButton
              color="secondary"
              aria-label="close Search"
              onClick={this.closeDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <List style={{ marginRight: "17px" }}>
          <FormGroup className={classes.formControl}>
            {filterLabels.map((filterlabel) => (
              <Box mt={3} key={filterlabel.id} style={{ width: "100%" }}>
                <FormControl
                  component="fieldset"
                  style={{ width: "100%", marginTop: 0 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={filterlabel.id}
                        checked={filterlabel.isActive}
                        onChange={this.handleChange(filterlabel.id)}
                        value={filterlabel.label}
                      />
                    }
                    label={filterlabel.label}
                  />
                  {filterlabel.isActive &&
                    filterlabel.label === "Date Range" && (
                      <DatePicker
                        dates={this.state.dateRange}
                        setDateRange={this.setDateRange}
                      ></DatePicker>
                    )}
                  {filterlabel.isActive && filterlabel.label === "Status" && (
                    <Select
                      style={{ width: "100%" }}
                      value={this.state.statusFilter}
                      onChange={this.handleStatusChange}
                    >
                      {statusSuggesstions.map((suggestion, idx) => {
                        return (
                          <MenuItem key={idx} value={suggestion.value}>
                            {suggestion.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  {/* {filterLabel.isActive && filterlabel.label === 'Location'&&()} */}
                  {filterlabel.isActive && filterlabel.label === "Role" && (
                    <Select
                      style={{ width: "100%" }}
                      value={this.state.roleFilter}
                      onChange={this.hadelRoleChange}
                    >
                      {roleSuggestions.map((suggestion, idx) => {
                        return (
                          <MenuItem key={idx} value={suggestion.value}>
                            {suggestion.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  {filterlabel.isActive && filterlabel.label === "Shift" && (
                    <Select
                      style={{ width: "100%" }}
                      value={this.state.shiftFilter}
                      onChange={this.handleShiftChange}
                    >
                      {shiftSuggestions.map((suggestion, idx) => {
                        return (
                          <MenuItem key={idx} value={suggestion.value}>
                            {suggestion.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  {filterlabel.isActive &&
                    filterlabel.label !== "Date Range" &&
                    filterlabel.label !== "Status" &&
                    filterlabel.label !== "Role" &&
                    filterlabel.label !== "Shift" && (
                      <FilterInput
                        color="primary"
                        fieldName={filterlabel.label}
                        setFilter={this.setFilter}
                        setSelectedNames={setSelectedNames}
                        setNewFilterQuery={this.setNewFilterQuery}
                        setFilterQuery={this.setFilterQuery}
                      ></FilterInput>
                    )}
                </FormControl>
              </Box>
            ))}
          </FormGroup>
        </List>
      </div>
    );

    return (
      <Drawer
        anchor="right"
        open={this.state.open}
        onClose={this.closeDrawer}
        classes={{ paper: classes.drawerPaper }}
      >
        {sideList("right")}
        <Box
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 22,
          }}
        >
          <Button
            disabled={!didFilter}
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={this.clearFilter}
          >
            Clear
          </Button>
          {!this.state.isLoading && (
            <Button
              // disabled={!canFilter}
              onClick={() => {
                this.submitHandler();
              }}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Submit
            </Button>
          )}
          {this.state.isLoading && (
            <Box
              mx={4.1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                className={classes.button}
                color="primary"
                size={51}
              />
            </Box>
          )}
        </Box>
      </Drawer>
    );
  }
}
export default withStyles(styles)(SideFilter);

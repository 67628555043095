import React from "react";
import {
  withStyles,
  withWidth,
  Grid,
  Box,
  IconButton,
  Tooltip,
  TablePagination,
  TableCell,
  TableRow,
  TableHead,
  Chip,
  Typography,
} from "@material-ui/core/";
import MaterialTable from "material-table";
import SideFilter from "../SideFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faFilter,
  faArrowUp,
  faArrowsAltV,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import LastPageIcon from "@material-ui/icons/LastPage";
import app from "../../../appConfig";
import moment from "moment";
import axios from "axios";
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const download = require("downloadjs");

const queryNameMapper = {
  Timestamp: "_id",
  "Billing Rate": "billingRate",
  Shift: "shiftType",
  Unit: "equipment",
  Site: "site",
  SMU: "currentSMU",
  "Down Hours": "downHours",
  User: "user",
  Status: "equipmentStatus",
  Location: "location",
  ETA: "estimatedDateOfRepair",
  "Start of shift": "startOfShift",
};

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    borderRadius: 20,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

class EquipmentAudit extends React.Component {
  state = {
    isDrawerOpen: false,
    columns: [
      { title: "Timestamp", field: "timestamp", filtering: false },
      { title: "Billing Rate", field: "billingRate", searchable: false },
      {
        title: "Equipment Class",
        field: "equipmentClass",
        filtering: false,
      },
      { title: "Equipment Number", field: "equipment" },
      {
        title: "User",
        field: "user",
        filtering: false,
        searchable: false,
      },
      {
        title: "Status",
        field: "equipmentStatus",
        searchable: false,
      },
      {
        title: "SMU",
        field: "currentSMU",
        searchable: false,
      },
      {
        title: "ETA",
        field: "estimatedDateOfRepair",
        searchable: false,
      },
      {
        title: "Location",
        field: "location",
        searchable: false,
      },
      {
        title: "Edit Timestamp",
        field: "editTimestamp",
        searchable: false,
        filtering: false,
      },
      {
        title: "Current Issues",
        field: "additionalDetails",
        filtering: false,
        searchable: false,
      },
      {
        title: "Comments",
        field: "comments",
        filtering: false,
        searchable: false,
      },
    ],
    data: [],
    tableOptions: {
      search: false,
      filtering: false,
      sorting: false,
      actionsColumnIndex: -1,
      pageSizeOptions: [5, 10, 15, 20],
      pageSize: 10,
      paginationType: "stepped",
      headerStyle: {
        whiteSpace: "noWrap",
        backgroundColor: "#525252",
        color: "#FFF",
        padding: 10,
      },
      filterCellStyle: {
        padding: 5,
        paddingTop: 5,
        paddingBottom: 0,
      },
      rowStyle: (rowData) => ({
        whiteSpace: "noWrap",
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? "#EEE"
            : "#FFF",
      }),
    },
    paginationOptions: { page: 0, limit: 10, total: 0, skip: 0 },
    sortList: {
      Timestamp: -1,
      "Billing Rate": 0,
      Class: 0,
      "Equipment Number": 0,
      User: 0,
      Status: 0,
      "Current Issues": 0,
      Comments: 0,
      SMU: 0,
      ETA: 0,
      Location: 0,
    },
    labels: [
      "Date Range",
      "Billing Rate",
      "Equipment Class",
      "Equipment Number",
      "User",
      "Status",
    ],
    filterQuery: {},
    sortQuery: {},
    filterDetails: {},
    isDataFiltered: false,
  };
  setFilterStatus = (isFilterEnabled) => {
    this.setState({ isDataFiltered: isFilterEnabled });
  };

  resetPages = () => {
    const paginationOptions = { ...this.state.paginationOptions };
    paginationOptions.page = 0;
    paginationOptions.skip = 0;
    this.setState({ paginationOptions });
  };

  getItems = () => {
    const paginationOptions = { ...this.state.paginationOptions };
    const service = app.service("equipments-audit");
    let query = {
      ...this.state.sortQuery,
      ...this.state.filterQuery,
      $skip: paginationOptions.limit * paginationOptions.page,
      $limit: paginationOptions.limit,
    };

    let params = {
      query: query,
      headers: {
        "X-Request-Source": "admin-portal",
      },
    };

    this.setState({ isTableLoading: true });
    service
      .find(params)
      .then((response) => {
        // handle success

        let equipmentStatus = [];
        const resData = response.data;

        // console.log('response :>> ', response);

        const paginationOptions = {
          total: response.total,
          limit: response.limit,
        };
        this.setState({
          paginationOptions: {
            ...this.state.paginationOptions,
            ...paginationOptions,
          },
        });

        resData.map((status) => {
          equipmentStatus.push({
            id: status._id,
            timestamp: moment(status.timestamp).format("MM/DD/YYYY HH:mm"),
            editTimestamp: status.history?.createdAt
              ? moment(status.history?.createdAt).format("MM/DD/YYYY HH:mm")
              : "",
            billingRate: status.billingRate.name,
            equipmentClass: status.equipmentClass.name,
            equipment: status.equipment.number,
            user: status.user
              ? status.user.firstName[0] + "." + status.user.lastName
              : "System",
            equipmentStatus: status.equipmentStatus === "DN" ? "DM" : "AV",
            additionalDetails: status.history?.additionalDetails?.new
              ? status.history?.additionalDetails?.new
              : status.additionalDetails,
            comments: status.history?.notes?.new
              ? status.history?.notes?.new
              : status.notes,
            currentSMU: status.currentSMU,
            estimatedDateOfRepair: moment(status.estimatedDateOfRepair).format(
              "MM/DD/YYYY"
            ),
            location: status.location,
          });
          return true;
        });
        //
        this.setState({ data: equipmentStatus });
      })
      .catch((error) => {
        // handle error
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };

  componentDidMount = () => {
    this.props.changeHeader("Reports | Equipment Audit");
    const sortQuery = { ...this.state.sortQuery };
    sortQuery["$sort"] = { _id: -1 };
    this.setState({ sortQuery }, () => {
      this.getItems();
    });
  };

  downloadFunc = (itemName, itemUrl, fileName, token) => {
    axios
      .get(`${itemUrl}${fileName}?token=${token}`)
      .then((response) => {
        download(
          response.data,
          `equipment-status-${moment().format("MM-DD-YYYY")}.csv`,
          "text/csv"
        );
      })
      .finally(() => {});
  };

  handleExport = (itemName) => {
    let token = null;
    let fileName = null;
    let itemUrl = apiEndpoint;
    const io = require("socket.io-client");
    const socket = io(apiEndpoint, {
      transports: ["websocket", "polling"],
    });

    socket.on("connect", () => {
      console.log("Connected.");
      socket.emit(
        "create",
        "authentication",
        {
          strategy: "jwt",
          accessToken: localStorage.getItem("token"),
        },
        (error, authResult) => {
          socket.emit(
            "find",
            "export-csv",
            {
              model: "equipments-audit",
              ...this.state.filterQuery,
            },
            (error, data) => {
              if (error) return 0;
            }
          );
        }
      );
    });

    socket.on("export-csv-complete", (data) => {
      fileName = data.fileName;
      token = data.token;
      // const service = app.service(`${itemUrl}${fileName}?token=${token}`);
      this.props.snackbar.current.download(this.downloadFunc, [
        itemName,
        itemUrl,
        fileName,
        token,
      ]);

      socket.disconnect();
    });
  };

  sortData = (itemName, sortIdx) => {
    if (
      (itemName =
        "Edit Timestamp" ||
        itemName === "Comments" ||
        itemName === "Up" ||
        itemName === "Down")
    ) {
      return;
    }
    let sortList = { ...this.state.sortList };
    const sortQuery = {};
    let currentState = sortList[itemName];

    sortList = {
      Timestamp: 0,
      "Billing Rate": 0,
      "Equipment Class": 0,
      "Equipment Number": 0,
      User: 0,
      Status: 0,
      "Current Issues": 0,
      Comments: 0,
    };
    if (currentState === 1) {
      currentState = -1;
    } else if (currentState === -1) {
      currentState = 1;
    } else {
      currentState = 1;
    }
    sortList[itemName] = currentState;
    sortQuery["$sort"] = { [`${queryNameMapper[itemName]}`]: currentState };
    this.setState({ sortList, sortIdx, sortQuery }, () => {
      this.getItems();
    });
  };
  componentWillUnmount = () => {
    const labels = { ...this.state.labels };
    for (var i in labels) {
      localStorage.removeItem(`${labels[i].label}SelectedItems`);
    }
  };

  setFilterQuery = (filterQuery, filterDetails) => {
    this.setState({ filterQuery, filterDetails }, () => {});
  };

  render() {
    const { classes } = this.props;
    const {
      columns,
      data,
      tableOptions,
      isTableLoading,
      labels,

      filterDetails,
    } = this.state;
    const { snackbar, isCsvLoading } = this.props;
    function TablePaginationActions(props) {
      const theme = useTheme();
      const { count, page, rowsPerPage, onChangePage } = props;

      const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
      };

      const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
      };

      const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
      };

      const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
        <div
          style={{
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
          }}
        >
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      );
    }
    return (
      <Box mt={2}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Box>
              {Object.keys(filterDetails).length > 0 && (
                <Box>
                  {Object.keys(filterDetails).map((key) => (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ marginRight: "5px" }}
                      >
                        {key}:
                      </Typography>
                      {key === "Date Range" && (
                        <Chip
                          color="primary"
                          variant="outlined"
                          label={`${filterDetails[key].start.format(
                            "D/M/Y"
                          )} - ${filterDetails[key].end.format("D/M/Y")}`}
                          size="small"
                        ></Chip>
                      )}
                      {key !== "Date Range" &&
                        filterDetails[key].map((itemName) => (
                          <Chip
                            color="primary"
                            variant="outlined"
                            label={itemName}
                            size="small"
                            style={{ marginRight: "3px" }}
                          />
                        ))}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              {isCsvLoading ? (
                <CircularProgress size={22} />
              ) : (
                <Tooltip title="Export CSV">
                  <IconButton
                    className={classes.button}
                    aria-label="exportCSV"
                    onClick={(event) => {
                      this.handleExport("equipment-status");
                      snackbar.current.handleClick();
                    }}
                  >
                    <FontAwesomeIcon icon={faFileCsv} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip Open title="Open Filter">
                <IconButton
                  className={classes.button}
                  aria-label="Open Filter"
                  onClick={(event) => this.sideFilter.openDrawer()}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} style={{ maxWidth: "100%", height: "100%" }}>
              <MaterialTable
                isLoading={isTableLoading}
                columns={columns}
                data={data}
                title=""
                onRowClick={(evt, selectedRow) =>
                  this.setState({ selectedRow })
                }
                options={tableOptions}
                components={{
                  Pagination: () => (
                    <TableRow
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <TablePagination
                        ActionsComponent={TablePaginationActions}
                        rowsPerPageOptions={[5, 10, 20]}
                        style={{ width: "100%" }}
                        count={this.state.paginationOptions.total}
                        rowsPerPage={this.state.paginationOptions.limit}
                        page={this.state.paginationOptions.page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                        }}
                        onChangePage={async (event, page) => {
                          //
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              page: page,
                            },
                          });

                          this.getItems();
                        }}
                        onChangeRowsPerPage={async (event) => {
                          const pageSize = parseInt(event.target.value);
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              limit: pageSize,
                              page: 0,
                            },
                          });
                          this.setState({
                            tableOptions: {
                              ...this.state.tableOptions,
                              pageSize: pageSize,
                            },
                          });
                          this.getItems();
                        }}
                      />
                    </TableRow>
                  ),
                  Header: () => (
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "#525252",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {[
                          "Timestamp",
                          "Billing Rate",
                          "Class",
                          "Equipment Number",
                          "User",
                          "Status",
                          "SMU",
                          "ETA",
                          "Location",
                          "Edit Timestamp",
                          "Current Issues",
                          "Comments",
                        ].map((item, idx) => (
                          <TableCell
                            style={{
                              color: "#fff",
                              cursor:
                                item !== "Comments" &&
                                item !== "Down" &&
                                item !== "Up"
                                  ? "pointer"
                                  : "default",
                            }}
                            onClick={() => this.sortData(item, idx)}
                            align="left"
                            key={idx}
                          >
                            <Box style={{ display: "flex" }}>
                              {item}
                              {this.state.sortList[item] === 0 && (
                                <Box ml={1}>
                                  <FontAwesomeIcon
                                    icon={faArrowsAltV}
                                  ></FontAwesomeIcon>
                                </Box>
                              )}
                              {this.state.sortList[item] !== 0 && (
                                <Box ml={1}>
                                  {item !== "Actions" &&
                                    item !== "Comments" &&
                                    item !== "Down" &&
                                    item !== "Up" && (
                                      <FontAwesomeIcon
                                        rotation={
                                          this.state.sortList[item] === 1
                                            ? 0
                                            : 180
                                        }
                                        // onClick={this.sortData}
                                        icon={faArrowUp}
                                      />
                                    )}
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  ),
                  Toolbar: () => <Box></Box>,
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <SideFilter
          ref={(sideFilter) => {
            this.sideFilter = sideFilter;
          }}
          labels={labels}
          getItems={this.getItems}
          setFilterStatus={this.setFilterStatus}
          setFilterQuery={this.setFilterQuery}
          resetPages={this.resetPages}
        ></SideFilter>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(EquipmentAudit));

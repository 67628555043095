import React from 'react';
import { withStyles, withWidth, Box } from '@material-ui/core/';
import { Route } from 'react-router-dom';
import EquipmentSetup from './Equipment/EquipmentSetup';
import SiteSetup from './Site/SiteSetup';
import UserSetup from './User/UserSetup';
import ReportingTags from './Reporting Tags/ReportingTags';
import AddEditEquipment from './Equipment/AddEditEquipment';
import AddEditSite from './Site/AddEditSite';
import AddEditUser from './User/AddEditUser';
const styles = theme => ({
  root: {
    display: 'flex'
  }
});

class Setup extends React.Component {
  state = { isDrawerOpen: false };

  render() {
    const { changeHeader, snackbar, isCsvLoading } = this.props;
    return (
      <Box>
        <Route
          exact
          path={`/admin-portal/setup/`}
          render={props => (
            <EquipmentSetup
              {...props}
              changeHeader={changeHeader}
              snackbar={snackbar}
              isCsvLoading={isCsvLoading}
            ></EquipmentSetup>
          )}
        />
        <Route
          exact
          path={`/admin-portal/setup/equipment`}
          render={props => (
            <EquipmentSetup
              {...props}
              changeHeader={changeHeader}
              snackbar={snackbar}
              isCsvLoading={isCsvLoading}
            ></EquipmentSetup>
          )}
        />
        <Route
          path={`/admin-portal/setup/equipment/add-edit-equipment/:id`}
          render={props => (
            <AddEditEquipment
              {...props}
              changeHeader={changeHeader}
            ></AddEditEquipment>
          )}
        />
        <Route
          exact
          path={`/admin-portal/setup/sites`}
          render={props => (
            <SiteSetup
              {...props}
              snackbar={snackbar}
              isCsvLoading={isCsvLoading}
              changeHeader={changeHeader}
            ></SiteSetup>
          )}
        />
        <Route
          path={`/admin-portal/setup/sites/add-edit-site/:id`}
          render={props => (
            <AddEditSite {...props} changeHeader={changeHeader}></AddEditSite>
          )}
        />

        <Route
          path={`/admin-portal/setup/reporting-tags`}
          render={props => (
            <ReportingTags
              {...props}
              changeHeader={changeHeader}
            ></ReportingTags>
          )}
        />
        <Route
          exact
          path={`/admin-portal/setup/users`}
          render={props => (
            <UserSetup
              {...props}
              snackbar={snackbar}
              isCsvLoading={isCsvLoading}
              changeHeader={changeHeader}
            ></UserSetup>
          )}
        />
        <Route
          path={`/admin-portal/setup/users/add-edit-user/:id`}
          render={props => (
            <AddEditUser {...props} changeHeader={changeHeader}></AddEditUser>
          )}
        ></Route>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(Setup));

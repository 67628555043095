import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip, CircularProgress, Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const {
    className,
    message,
    onClose,
    variant,
    downloadFunc,
    args,
    ...other
  } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
          <Box style={{ marginRight: 15 }}></Box>
          {variant === 'success' ? (
            <Tooltip title='Download'>
              <IconButton
                key='close'
                size='small'
                aria-label='close'
                color='inherit'
                onClick={() => {
                  downloadFunc.apply(this, args);
                  onClose();
                }}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress size={25} style={{ color: '#fff' }} />
          )}
        </span>
      }
      action={[
        <Tooltip title='Close'>
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

// const useStyles2 = makeStyles(theme => ({
//   margin: {
//     margin: theme.spacing(1)
//   }
// }));

export default class index extends Component {
  state = {
    open: false,
    variant: 'info',
    message: '',
    downloadFunc: null,
    args: []
  };
  handleClick = () => {
    this.setState({
      variant: 'info',
      message: 'Processing your data, please wait.',
      open: true
    });
    this.props.spinCsvLoading();
  };
  download = async (downloadFunc, args) => {
    await this.setState({
      variant: 'success',
      message: `${args[0]}-${moment().format(
        'MM-DD-YYYY'
      )}.csv is ready to download`,
      open: true,
      downloadFunc,
      args
    });
    // downloadFunc.apply(this, args);
    this.props.stopCsvLoading();
  };
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    this.props.stopCsvLoading();
  };
  setVariant = variant => {
    this.setState({ variant });
  };
  setMessage = message => {
    this.setState({ message });
  };
  render() {
    // const classes = useStyles2();
    const { open, variant, message, downloadFunc, args } = this.state;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={open}
          //   autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            downloadFunc={downloadFunc}
            args={args}
            variant={variant}
            message={message}
          />
        </Snackbar>
      </div>
    );
  }
}

import React, { Component } from 'react';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css'; // For some basic styling. (OPTIONAL)
import Button from '@material-ui/core/Button';
import { Paper, Typography, Box } from '@material-ui/core';

class DatePicker extends Component {
  state = {
    open: false
  };

  onSelect = dates => {
    this.props.setDateRange(dates);
    this.handleClick();
  };
  handleClick = () => {
    const open = !this.state.open;
    this.setState({ open });
  };
  onClose = () => {
    const open = !this.state.open;
    this.setState({ open });
  };
  render() {
    const { dates } = this.props;

    return (
      <Box ml={1.5}>
        <Button
          variant='outlined'
          // size="large"
          // margin="dense"
          fullWidth
          onClick={this.handleClick}
        >
          <Typography variant={'caption'} noWrap>
            {dates
              ? `${dates.start.format('M/D/Y')} - ${dates.end.format('M/D/Y')}`
              : 'Select date range'}
          </Typography>
        </Button>
        {this.state.open && (
          <Paper style={{ position: 'absolute', zIndex: 3 }}>
            <DateRangePicker
              // onKeyDown={this.onClose}
              onSelect={this.onSelect}
              value={this.props.dates}
            />
          </Paper>
        )}
      </Box>
    );
  }
}
export default DatePicker;

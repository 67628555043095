import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BuildIcon from "@material-ui/icons/Build";
import BarChartIcon from "@material-ui/icons/BarChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExitIcon from "@material-ui/icons/ExitToAppOutlined";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import ChatIcon from "@material-ui/icons/Chat";

import {
  List,
  withStyles,
  Collapse,
  Box,
  Divider,
  Icon,
  Typography,
  Grid,
  Tooltip,
} from "@material-ui/core/";
import { Link } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const styles = (theme) => ({
  oneLineText: {
    overflow: "hidden",
    display: "-webkit-box",
    lineHeight: "21px",
    maxHeight: "21px",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  },
  listLink: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: "none",
  },
  listItem: {
    backgroundColor: "#1d1d1d",
    "&:hover": { backgroundColor: theme.palette.primary.dark },
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    "&:hover": { backgroundColor: theme.palette.primary.dark },
  },
  listItemText: { color: "#fff" },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  tooltip: {
    backgroundColor: "#000",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
});
class ListItems extends Component {
  state = {
    selectedIndex: null,
    isReportsListOpen: false,
    isSetupListOpen: false,
    open: false,
  };

  handleListItemClick = (index) => {
    if (index === 0 || index === 1 || index === 15) {
      this.setState({ isReportsListOpen: false, isSetupListOpen: false });
    } else if (index === 3 || index === 4 || index === 5) {
      this.setState({ isSetupListOpen: false });
    } else if (index === 7 || index === 8 || index === 9 || index === 10) {
      this.setState({ isReportsListOpen: false });
    }

    if (index === 2) {
      this.setState({
        selectedIndex: index,
        isReportsListOpen: !this.state.isReportsListOpen,
      });

      if (!this.props.isDrawerOpen) {
        this.setState({ isReportsListOpen: true });
      }
      this.props.handleDrawerOpen();
    } else if (index === 6) {
      this.setState({
        selectedIndex: index,
        isSetupListOpen: !this.state.isSetupListOpen,
      });
      if (!this.props.isDrawerOpen) {
        this.setState({ isSetupListOpen: true });
      }
      this.props.handleDrawerOpen();
    } else {
      this.setState({ selectedIndex: index });
      // this.props.handleDrawerClose();
    }
  };
  changeHeader = (name) => {
    this.props.changeHeader(name);
  };
  signout = () => {
    localStorage.clear();
  };

  closeSubMenu(name) {
    if ("Reports") {
      this.setState({ isReportsListOpen: true });
    } else {
      this.setState({ isSetupListOpen: true });
    }
  }
  render() {
    const { classes, isDrawerOpen, userData } = this.props;
    const { isReportsListOpen, isSetupListOpen } = this.state;
    const role = userData.role ? userData.role : null;
    const mainListItems = (
      <div>
        <Tooltip
          disableHoverListener={isDrawerOpen}
          title="Dashboard"
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <Link
            color="inherit"
            to={`/admin-portal/dashboard`}
            className={classes.listLink}
          >
            <ListItem
              // className={classes.listItem}
              classes={{
                root: classes.listItem,
                selected: classes.selectedListItem,
              }}
              button
              onClick={(event) => {
                this.handleListItemClick(0);
              }}
              selected={this.state.selectedIndex === 0}
            >
              <ListItemIcon>
                <BarChartIcon className={classes.listItemText} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Dashboard"
              />
            </ListItem>
          </Link>
        </Tooltip>
        <Tooltip
          disableHoverListener={isDrawerOpen}
          title="Status Entries"
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <Link
            color="inherit"
            to={`/admin-portal/status-entries`}
            className={classes.listLink}
          >
            <ListItem
              // className={}
              classes={{
                root: classes.listItem,
                selected: classes.selectedListItem,
              }}
              button
              onClick={(event) => {
                this.handleListItemClick(1);
              }}
              selected={this.state.selectedIndex === 1}
            >
              <ListItemIcon>
                <MenuIcon className={classes.listItemText} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Status Entries"
              />
            </ListItem>
          </Link>
        </Tooltip>

        <Tooltip
          disableHoverListener={isDrawerOpen}
          title="Chat"
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <Link
            color="inherit"
            to={`/admin-portal/sites-chat`}
            className={classes.listLink}
          >
            <ListItem
              classes={{
                root: classes.listItem,
                selected: classes.selectedListItem,
              }}
              button
              onClick={(event) => {
                this.handleListItemClick(15);
              }}
              selected={this.state.selectedIndex === 15}
            >
              <ListItemIcon>
                <ChatIcon className={classes.listItemText} />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary="Chat" />
            </ListItem>
          </Link>
        </Tooltip>
        <Tooltip
          disableHoverListener={isDrawerOpen}
          title="Reports"
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <ListItem
            className={classes.listItem}
            classes={{ selected: classes.selectedListItem }}
            button
            onClick={(event) => {
              this.handleListItemClick(2);
            }}
            selected={this.state.selectedIndex === 2}
          >
            <ListItemIcon>
              <AssignmentIcon className={classes.listItemText} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Reports" />
            {isReportsListOpen ? (
              <ExpandLess className={classes.listItemText} />
            ) : (
              <ExpandMore className={classes.listItemText} />
            )}
          </ListItem>
        </Tooltip>
        <Collapse
          in={isReportsListOpen && isDrawerOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link
              to="/admin-portal/reports/equipment-status"
              color="inherit"
              className={classes.listLink}
            >
              <ListItem
                classes={{ selected: classes.selectedListItem }}
                button
                onClick={(event) => {
                  this.handleListItemClick(3);
                }}
                selected={this.state.selectedIndex === 3}
                className={clsx(classes.nested, classes.listItem)}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary="Equipment Status"
                />
              </ListItem>
            </Link>
            <Link
              to="/admin-portal/reports/equipment-audit"
              color="inherit"
              className={classes.listLink}
            >
              <ListItem
                classes={{ selected: classes.selectedListItem }}
                button
                onClick={(event) => {
                  this.handleListItemClick(4);
                }}
                selected={this.state.selectedIndex === 4}
                className={clsx(classes.nested, classes.listItem)}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary="Equipment Audit"
                />
              </ListItem>
            </Link>
            <Link
              to="/admin-portal/reports/equipment-site"
              color="inherit"
              className={classes.listLink}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(5);
                }}
                selected={this.state.selectedIndex === 5}
                className={clsx(classes.listItem, classes.nested)}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary="Equipment/Sites"
                />
              </ListItem>
            </Link>
          </List>
        </Collapse>
        {role === "admin" && (
          <Box>
            <Tooltip
              disableHoverListener={isDrawerOpen}
              title="Setup"
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(6);
                }}
                selected={this.state.selectedIndex === 6}
                className={classes.listItem}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemIcon>
                  <BuildIcon className={classes.listItemText} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary="Setup"
                />
                {isSetupListOpen ? (
                  <ExpandLess className={classes.listItemText} />
                ) : (
                  <ExpandMore className={classes.listItemText} />
                )}
              </ListItem>
            </Tooltip>
            <Collapse
              in={isSetupListOpen && isDrawerOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <Link
                  to="/admin-portal/setup/equipment"
                  color="inherit"
                  className={classes.listLink}
                >
                  <ListItem
                    button
                    onClick={(event) => {
                      this.handleListItemClick(7);
                    }}
                    selected={this.state.selectedIndex === 7}
                    className={clsx(classes.nested, classes.listItem)}
                    classes={{ selected: classes.selectedListItem }}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primary="Equipment"
                    />
                  </ListItem>
                </Link>
                <Link
                  to="/admin-portal/setup/sites"
                  color="inherit"
                  className={classes.listLink}
                >
                  <ListItem
                    button
                    onClick={(event) => {
                      this.handleListItemClick(8);
                    }}
                    selected={this.state.selectedIndex === 8}
                    className={clsx(classes.nested, classes.listItem)}
                    classes={{ selected: classes.selectedListItem }}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primary="Sites"
                    />
                  </ListItem>
                </Link>
                <Link
                  to="/admin-portal/setup/reporting-tags"
                  color="inherit"
                  className={classes.listLink}
                >
                  <ListItem
                    button
                    onClick={(event) => {
                      this.handleListItemClick(9);
                    }}
                    selected={this.state.selectedIndex === 9}
                    className={clsx(classes.nested, classes.listItem)}
                    classes={{ selected: classes.selectedListItem }}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primary="Reporting Tags"
                    />
                  </ListItem>
                </Link>
                <Link
                  to="/admin-portal/setup/users"
                  color="inherit"
                  className={classes.listLink}
                >
                  <ListItem
                    button
                    onClick={(event) => {
                      this.handleListItemClick(10);
                    }}
                    selected={this.state.selectedIndex === 10}
                    className={clsx(classes.nested, classes.listItem)}
                    classes={{ selected: classes.selectedListItem }}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primary="Users"
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </Box>
        )}
      </div>
    );

    const secondaryListItems = (
      <div>
        <Grid container>
          {this.props.isDrawerOpen && userData.firstName && (
            <Grid
              item
              xs={9}
              className={classes.selectedListItem}
              style={{
                display: "flex",
                placeItems: "center",
                placeContent: "flex-start",
                padding: "8px",
                transition: "all ease 2s",
              }}
            >
              <Tooltip title={userData.firstName + " " + userData.lastName}>
                <Typography
                  className={classes.oneLineText}
                  style={{
                    color: "#fff",
                    whiteSpace: "pre-line",
                    cursor: "default",
                  }}
                >
                  {userData.firstName[0] + ". " + userData.lastName}
                </Typography>
              </Tooltip>
            </Grid>
          )}
          <Grid
            item
            xs={this.props.isDrawerOpen && userData.firstName ? 3 : 12}
          >
            <Link color="inherit" to={`/signin`} className={classes.listLink}>
              <div
                onClick={(event) => {
                  this.signout();
                }}
                className={classes.listItem}
                // classes={{ selected: classes.selectedListItem }}
                style={{
                  display: "flex",
                  placeItems: "center",
                  justifyContent: this.props.isDrawerOpen ? "center" : "center",
                  padding: "8px",
                  transition: "all ease 2s",
                }}
              >
                {/* {this.props.isDrawerOpen && (
                  <Typography
                    className={classes.listItemText}
                    style={{ margin: '0' }}
                  >
                    Logout
                  </Typography>
                )} */}
                <Tooltip title="Logout">
                  <Icon>
                    <ExitIcon className={classes.listItemText} />
                  </Icon>
                </Tooltip>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div>
    );
    return (
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "#525252 ",
        }}
      >
        <Box>
          <Divider />
          <List style={{ paddingTop: 0 }}>{mainListItems}</List>
        </Box>
        <Box>
          <Divider />
          {secondaryListItems}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(ListItems);

import React, { Component } from 'react';
import './App.css';
import SignIn from './components/SignIn/SignIn';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import AdminPortal from './components/AdminPortal/AdminPortal';
import { ToastContainer } from 'react-toastify';

const theme = createMuiTheme({
  typography: {
    // useNextVariants: true,
    // fontFamily: 'Source Sans Pro'
  },
  palette: {
    primary: {
      light: '#ee404e',
      main: '#b7303b',
      dark: '#992a33',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff7961',
      main: '#525659',
      dark: '#000',
      contrastText: '#000'
    }
  },
  error: {
    light: '#f00',
    main: '#f00',
    dark: '#f00',
    contrastText: '#f00'
  }
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

class App extends Component {
  RedirectToSignin = () => {
    return <Redirect exact to='/signin' />;
  };
  render() {
    return (
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path='/' component={this.RedirectToSignin} />
              <Route path='/signin' component={SignIn} />
              <Route path='/admin-portal' component={AdminPortal} />
            </Switch>
          </Router>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
          />
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}
export default App;

import React from 'react';
import {
  withStyles,
  withWidth,
  Grid,
  Box,
  IconButton,
  Button,
  Tooltip,
  TablePagination,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Chip
} from '@material-ui/core/';
import MaterialTable from 'material-table';
import { useTheme } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import SideFilter from '../../SideFilter';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  faFileCsv,
  faFilter,
  faArrowUp,
  faArrowsAltV
} from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import app from '../../../../appConfig';
import moment from 'moment';
import { toast } from 'react-toastify';

const download = require('downloadjs');
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const queryNameMapper = {
  'First Name': 'firstName',
  'Last Name': 'lastName',
  Role: 'role',
  Email: 'email',
  Active: 'isActive'
};

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 20
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    // margin: theme.spacing(1)
  }
});

class UserSetup extends React.Component {
  state = {
    isDrawerOpen: false,
    columns: [
      {
        title: 'First Name',
        field: 'firstName',
        searchable: false,
        filtering: false
      },
      {
        title: 'Last Name',
        field: 'lastName',
        filtering: false,
        searchable: false
      },
      {
        title: 'Role',
        field: 'role',
        filtering: false,
        searchable: false
      },
      {
        title: 'Email',
        field: 'email',
        filtering: false,
        searchable: false
      },
      {
        title: 'Active',
        field: 'isActive',
        filtering: false,
        searchable: false,
        render: rowData => (
          <TableRow>
            <td
              style={{
                color: rowData.isActive === 'ACTIVE' ? '#008000' : '#B22222'
              }}
            >
              {rowData.isActive}
            </td>
          </TableRow>
        )
      }
    ],
    data: [],
    tableOptions: {
      search: false,
      filtering: false,
      sorting: false,
      actionsColumnIndex: -1,
      pageSize: 5,
      paginationType: 'stepped',
      pageSizeOptions: [5, 10, 15, 20],
      headerStyle: {
        whiteSpace: 'noWrap',
        backgroundColor: '#525252',
        color: '#FFF',
        padding: 10
      },
      rowStyle: rowData => ({
        whiteSpace: 'noWrap',
        padding: 0,
        fontWeight: rowData.isActive ? 700 : 200,
        backgroundColor:
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? '#EEE'
            : '#FFF'
      })
    },
    paginationOptions: { page: 0, limit: 5, total: 0, skip: 0 },
    isDataFiltered: false,
    sortList: {
      'First Name': 0,
      'Last Name': 0,
      Role: 0,
      Email: 0,
      Active: 0
    },
    isTableLoading: true,
    labels: ['First Name', 'Last Name', 'Email', 'Role'],
    filterDetails: {},
    filterQuery: {},
    sortQuery: {}
  };
  componentWillUnmount = () => {
    const labels = { ...this.state.labels };
    for (var i in labels) {
      localStorage.removeItem(`${labels[i].label}SelectedItems`);
    }
  };

  setFilterStatus = isFilterEnabled => {
    this.setState({ isDataFiltered: isFilterEnabled });
  };

  resetPages = () => {
    const paginationOptions = { ...this.state.paginationOptions };
    paginationOptions.page = 0;
    paginationOptions.skip = 0;
    this.setState({ paginationOptions });
  };
  getItems = () => {
    const paginationOptions = { ...this.state.paginationOptions };
    let query = {
      ...this.state.sortQuery,
      ...this.state.filterQuery,
      $skip: paginationOptions.limit * paginationOptions.page,
      $limit: paginationOptions.limit
    };
    const service = app.service('users');
    this.setState({ isTableLoading: true });

    service
      .find({ query })
      .then(response => {
        // handle success
        const resData = response.data;

        const paginationOptions = {
          total: response.total
        };
        this.setState({
          paginationOptions: {
            ...this.state.paginationOptions,
            ...paginationOptions
          }
        });
        let tableData = [];
        resData.forEach(user => {
          if (user.firstName === 'System') return;
          let role = '';
          if (user.role === 'admin') {
            role = 'Admin';
          } else if (user.role === 'planner') {
            role = 'Coordinator/Planner';
          } else {
            role = 'Foreman/HET';
          }
          tableData.push({
            id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            role: role,
            email: user.email,
            isActive: user.isActive ? 'ACTIVE' : 'INACTIVE'
          });
        });
        this.setState({ data: tableData, query });
      })
      .catch(error => {})
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };
  componentDidMount = () => {
    this.props.changeHeader('Setup | Users');

    this.getItems();
  };
  downloadFunc = (itemName, itemUrl, fileName, token) => {
    axios
      .get(`${itemUrl}${fileName}?token=${token}`)
      .then(response => {
        download(
          response.data,
          `users-${moment().format('MM-DD-YYYY')}.csv`,
          'text/csv'
        );
      })
      .finally(() => {});
  };
  handleExport = itemName => {
    let token = null;
    let fileName = null;

    let itemUrl = apiEndpoint;
    const io = require('socket.io-client');
    const socket = io(apiEndpoint, {
      transports: ['websocket', 'polling']
    });

    socket.on('connect', () =>{
      console.log('Connected.');
      socket.emit(
        'create',
        'authentication',
        {
          strategy: 'jwt',
          accessToken: localStorage.getItem('token')
        },
        (error, authResult) => {
          socket.emit(
            'find',
            'export-csv',
            {
              model: 'users',
              ...this.state.filterQuery
            },
            (error, data) => {
              if (error) return 0;
            }
          );
        }
      );
    });

    socket.on('export-csv-complete', data => {
      fileName = data.fileName;
      token = data.token;

      // const service = app.service(`${itemUrl}${fileName}?token=${token}`);
      this.props.snackbar.current.download(this.downloadFunc, [
        itemName,
        itemUrl,
        fileName,
        token
      ]);

      socket.disconnect();
    });

 
  };
  deleteItem = (itemUrl, itemData) => {
    itemUrl += '/' + itemData.id;
    axios
      .delete(apiEndpoint + itemUrl, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => {
        toast.success('User deactivated successfully.');
        this.getItems();
      })
      .catch(err => {
        if (err.response) {
          // this.setState({ isEmailValid: false });
          toast.error('This user is assigned to one or more sites.');
        }
      })
      .finally(() => {});
  };
  sortData = (itemName, sortIdx) => {
    if (itemName === 'Actions') {
      return;
    }
    let sortList = { ...this.state.sortList };
    const sortQuery = {};
    let currentState = sortList[itemName];
    sortList = {
      'First Name': 0,
      'Last Name': 0,
      Role: 0,
      Email: 0,
      Active: 0
    };
    if (currentState === 1) {
      currentState = -1;
    } else if (currentState === -1) {
      currentState = 1;
    } else {
      currentState = 1;
    }
    sortList[itemName] = currentState;
    sortQuery['$sort'] = { [`${queryNameMapper[itemName]}`]: currentState };
    this.setState({ sortList, sortIdx, sortQuery }, () => {
      this.getItems();
    });
  };
  setFilterQuery = (filterQuery, filterDetails) => {
    this.setState({ filterQuery, filterDetails }, () => {});
  };

  render() {
    const { classes, snackbar } = this.props;
    const {
      columns,
      data,
      tableOptions,
      isTableLoading,
      labels,

      filterDetails
    } = this.state;
    const { isCsvLoading } = this.props;
    function TablePaginationActions(props) {
      const theme = useTheme();
      const { count, page, rowsPerPage, onChangePage } = props;

      const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
      };

      const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
      };

      const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
      };

      const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
        <div
          style={{
            flexShrink: 0,
            marginLeft: theme.spacing(2.5)
          }}
        >
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label='first page'
          >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label='previous page'
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='next page'
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='last page'
          >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      );
    }
    return (
      <Box>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.tableHeader}>
            <Box>
              {Object.keys(filterDetails).length > 0 && (
                <Box>
                  {Object.keys(filterDetails).map(key => (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginTop: '5px'
                      }}
                    >
                      <Typography
                        variant='body1'
                        style={{ marginRight: '5px' }}
                      >
                        {key}:
                      </Typography>
                      {filterDetails[key] &&
                        filterDetails[key].map(itemName => (
                          <Chip
                            color='primary'
                            variant='outlined'
                            label={itemName}
                            size='small'
                            style={{ marginRight: '3px' }}
                          />
                        ))}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box mt={2} style={{ display: 'flex', alignItems: 'center' }}>
              {isCsvLoading ? (
                <CircularProgress size={22} />
              ) : (
                <Tooltip title='Export CSV'>
                  <IconButton
                    className={classes.button}
                    aria-label='exportCSV'
                    onClick={event => {
                      this.handleExport('users');
                      snackbar.current.handleClick();
                    }}
                  >
                    <FontAwesomeIcon icon={faFileCsv} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip Open title='Open Filter'>
                <IconButton
                  className={classes.button}
                  aria-label='Open Filter'
                  onClick={event => this.sideFilter.openDrawer()}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </IconButton>
              </Tooltip>
              <Button
                variant='outlined'
                color='default'
                className={classes.button}
                size='small'
                onClick={(event, rowData) => {
                  localStorage.setItem('text', 'Add');
                  this.props.history.push(
                    '/admin-portal/setup/users/add-edit-user/0'
                  );
                }}
              >
                Add
                <AddIcon className={classes.rightIcon} />
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mt={2} style={{ maxWidth: '100%', height: '80%' }}>
              <MaterialTable
                isLoading={isTableLoading}
                components={{
                  Pagination: () => (
                    <TableRow
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <TablePagination
                        ActionsComponent={TablePaginationActions}
                        rowsPerPageOptions={[2, 5, 10, 20]}
                        style={{ width: '100%' }}
                        count={this.state.paginationOptions.total}
                        rowsPerPage={this.state.paginationOptions.limit}
                        page={this.state.paginationOptions.page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' }
                        }}
                        onChangePage={async (event, page) => {
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              page: page
                            }
                          });

                          this.getItems();
                        }}
                        onChangeRowsPerPage={async event => {
                          const pageSize = parseInt(event.target.value);
                          await this.setState({
                            paginationOptions: {
                              ...this.state.paginationOptions,
                              limit: pageSize,
                              page: 0
                            }
                          });
                          this.setState({
                            tableOptions: {
                              ...this.state.tableOptions,
                              pageSize: pageSize
                            }
                          });
                          this.getItems();
                        }}
                      />
                    </TableRow>
                  ),

                  Header: () => (
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: '#525252',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {[
                          'First Name',
                          'Last Name',
                          'Role',
                          'Email',
                          'Active',
                          'Actions'
                        ].map((item, idx) => (
                          <TableCell
                            style={{
                              color: '#fff',
                              cursor: item !== 'Actions' ? 'pointer' : 'default'
                            }}
                            onClick={() => this.sortData(item, idx)}
                            align='left'
                            key={idx}
                          >
                            <Box style={{ display: 'flex' }}>
                              {item}
                              {this.state.sortList[item] === 0 && (
                                <Box ml={1}>
                                  <FontAwesomeIcon
                                    icon={faArrowsAltV}
                                  ></FontAwesomeIcon>
                                </Box>
                              )}
                              {this.state.sortList[item] !== 0 && (
                                <Box ml={1}>
                                  {item !== 'Actions' && (
                                    <FontAwesomeIcon
                                      rotation={
                                        this.state.sortList[item] === 1
                                          ? 0
                                          : 180
                                      }
                                      icon={faArrowUp}
                                    />
                                  )}
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  ),
                  Toolbar: () => <Box></Box>
                }}
                columns={columns}
                data={data}
                title=''
                onRowClick={(evt, selectedRow) =>
                  this.setState({ selectedRow })
                }
                options={tableOptions}
                editable={{
                  isDeletable: rowData => rowData.isActive === 'ACTIVE',
                  onRowDelete: oldData =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        this.deleteItem('users', oldData);
                      }, 100);
                    })
                }}
                actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Edit User',
                    onClick: (event, rowData) => {
                      localStorage.setItem('text', 'Edit');
                      const userId = rowData.id;
                      this.props.history.push(
                        `/admin-portal/setup/users/add-edit-user/${userId}`
                      );
                    }
                  }
                ]}
                icons={{
                  Delete: 'block'
                }}
                localization={{
                  body: {
                    deleteTooltip: 'Deactivate',
                    editRow: {
                      deleteText:
                        'Are you sure you want to deactivate this user?'
                    },
                    emptyDataSourceMessage: 'No records to display'
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <SideFilter
          setFilterStatus={this.setFilterStatus}
          getItems={this.getItems}
          ref={sideFilter => {
            this.sideFilter = sideFilter;
          }}
          labels={labels}
          setFilterQuery={this.setFilterQuery}
          resetPages={this.resetPages}
        ></SideFilter>
      </Box>
    );
  }
}
export default withStyles(styles)(withWidth()(UserSetup));
